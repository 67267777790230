@use "shared" as *;

.unsubscribe {
  padding: 0 24px;
  color: $color-gray-darkest;

  @media (min-width: $break-md) {
    padding: 12px 72px;
  }

  @media (min-width: $break-lg) {
    padding: 12px 120px;
  }

  h1 {
    font-size: $type-h1;
    font-weight: $default;
  }

  div,
  label,
  p {
    color: $color-gray-darkest;
    font-size: $type-sm;
  }

  button {
    all: unset;
    background: $color-brand-default;
    color: $color-white;
    padding: 6px 12px;
    border-radius: $radius-default;
    cursor: pointer;
    margin: 12px 0 0 0;

    &:hover {
      background: $color-brand;
    }
  }
}
