@use "shared" as *;

.link-vmib {
  display: block;
  background: $color-gray-note;
  padding: 6px 12px;
  margin: 0 0 6px 0;
  font-size: $type-default;
  cursor: pointer;

  &-selected {
    @extend .link-vmib;
    background: $color-gray-slight;
    &:hover {
      background: $color-gray-light;
    }
  }

  &-repo {
    background: $color-white;
    padding: 3px 6px;
    border-radius: $radius-slight;
    color: $color-gray-medium;
    font-size: $type-default;
    margin: 0 12px 0 0;
    display: inline-block;
    cursor: pointer;
    border-top: 1px solid $color-gray-light;
    border-left: 1px solid $color-gray-light;
    border-right: 1px solid $color-gray-light;

    &:hover {
      background: $color-blue-slight;
    }

    &-selected {
      @extend .link-vmib-repo;
      color: $color-blue-default;
      border-top: 1px solid $color-gray-light;
      border-left: 1px solid $color-gray-light;
      border-right: 1px solid $color-gray-light;
      &:hover {
      }
    }
  }

  &:hover {
    background: $color-gray-slight;
  }

  i {
    font-size: $type-tiny;
    padding: 0 7px 0 0;
  }
}

.link-vmib-copy {
  font-size: $type-teeny;
  padding: 3px 6px;
  color: $color-blue-default;
  border-radius: $radius-slight;
  margin-top: 3px;
  float: right;
  display: block;
  cursor: pointer;
  height: 24px;

  &:hover {
    color: $color-blue;
  }
}
.vmib {
  &-sample {
    display: flex;
    margin: 0 0 6px 0;
    text-align: left;

    span {
      margin: 0 12px;
      font-size: $type-default;
    }
  }

  &-section {
    &-label {
      color: $color-gray-dark;
      font-size: $type-teeny;
      padding: 3px 0;
      border-bottom: 1px solid $color-gray-light;
    }

    &-left {
      display: inline-flex;
      flex: 1;
      align-items: center;
    }

    &-right {
      display: flex;
      font-size: $type-default;
      justify-content: flex-end;
      align-items: center;
      font-weight: $regular;
      span {
        color: $color-gray-medium;
      }
      i {
        color: $color-blue-light;
      }
    }
  }

  &-video {
    padding: 12px 24px;
    margin: 12px 0;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 750px;

    input {
      border-radius: 0;
      padding: 6px 0;
      border-left: 1px solid transparent;
      border-right: 1px solid transparent;
      border-top: 1px solid transparent;
      border-bottom: 1px solid $color-gray-light;
      font-size: $type-default;
      font-family: $font-default !important;
      width: 100%;
      display: block;
      box-sizing: border-box;
      outline: none;
    }

    iframe {
      align-items: baseline !important;
      width: 400px;
      height: 225px;
      padding: 0;
      margin: 0;
    }
  }

  &-grid {
    list-style: none;
    text-decoration: none !important;
    display: flex;
    flex-direction: column;
    padding: 12px 6px;
    text-align: left;
    margin-top: 70px;

    @media (min-width: $break-sm) {
      grid-column: 1 / -1;
      grid-gap: 24px;
      display: grid;
      grid-template-columns: 400px auto;
      align-items: flex-start;
      flex-direction: column;
      text-align: justify;
    }

    small {
      font-size: $type-tiny;
      font-weight: $default;
      padding: 0;
      color: $color-gray-darkest;
      margin: 12px 0 0 0;
    }

    span,
    strong,
    p {
      line-height: $type-xl;
    }

    strong {
      font-weight: $regular;
    }
  }

  &-solo {
    @extend .vmib-grid;
    @media (min-width: $break-md) {
      grid-column: 1 / -1;
      grid-gap: 24px;
      display: grid;
      grid-template-columns: auto;
      align-items: flex-start;
      flex-direction: column;
    }
  }

  &-file {
    grid-column: 1 / -1;
    grid-gap: 6px;
    display: grid;
    grid-template-columns: 40px auto;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid $color-gray-light;
    padding: 12px 6px;
    text-decoration: none !important;
    transition: $transition-background;

    img {
      transition: $transition-scale;
      transform: scale(1);
    }

    &:hover {
      background: $color-gray-note;
      transition: $transition-background;

      img {
        transition: $transition-scale;
        transform: scale(1.5) rotate(-6deg);
        // transform: scale(1.75) rotate(5deg);
      }
    }

    i {
      color: $color-brand-medium;
      font-size: $type-xl;
    }

    b {
      text-decoration: underline;
      color: $color-brand-medium;
      font-size: $type-xxs;
      font-weight: $regular;
    }

    span {
      color: $color-gray;
      font-size: $type-default;
      text-transform: uppercase;
    }
  }
}
