@use "shared" as *;

.split {
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  padding: 0;
  font-size: $type-teeny;
  align-items: baseline;

  h3 {
    font-size: $type-h3;
    color: $color-gray-darkest;
    padding: 0;
    margin: 0;
  }

  strong {
    color: $color-gray-dark;
    font-weight: $regular;
  }

  i {
    font-size: $type-teeny;
    padding: 0 3px;
    color: $color-gray;
  }
}

.sticky {
  &Header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    margin: 0;
    padding: 24px 36px 0 36px;
    background: $color-white;
    border-bottom: 1px solid $color-gray-note;
    box-shadow: 0 0 24px $color-gray-slight;
    z-index: $z-ultimate;
  }
}

.note {
  font-weight: $regular;
  font-size: $type-default;
  padding: 24px 0 0 0;
}

.bundle {
  // border: 1px solid $color-gray-slight;
  padding: 12px 36px;

  &Header {
    padding: 24px 0;
    text-transform: none;

    font-size: $type-sm;

    small {
      font-size: $type-xxs;
      padding: 3px 0;
    }

    p {
      padding: 0;
      margin: 0;
      font-size: $type-xxs;
      font-weight: $regular;
    }

    strong {
      color: $color-blue;
      font-weight: $regular;
    }

    b {
      color: $color-gray-half;
      font-weight: $default;
      padding: 0 6px;
    }

    span {
      color: $color-gray-darkest;
      font-weight: $default;
    }
  }

  &Program {
    font-size: $type-default;
    border-top: 1px solid $color-gray-slight;

    padding: 0;
    margin: 0;

    &Container {
      margin: 24px;
    }

    button {
      background: transparent;
      border: 1px solid $color-brand;
      color: $color-brand;
      font-weight: $regular;
      font-family: $font-default;
      font-size: $type-default;
      margin: 24px 0 0 0;
      padding: 6px 12px;
      cursor: pointer;
      text-decoration: none;
      border-radius: $radius-slight;

      &:hover {
        background: $color-brand;
        border: 1px solid transparent;
        color: $color-white;
      }
    }
  }

  &Desc {
    margin: 0;

    p {
      font-size: $type-default !important;
      text-transform: none;
      padding: 0;
      margin: 0;

      small {
        font-size: $type-teeny;
      }
    }

    ol {
      padding: 0 12px;
      text-transform: none;

      li {
        padding: 6px 0;
        font-size: $type-default;
        border: none;
      }
    }
  }

  &Main {
    display: grid;
    grid-gap: 6px;
    grid-template-columns: 75px 100px auto 75px;
    margin: 6px 0;
    padding: 12px;
    align-items: flex-start;
    border-bottom: 1px solid $color-gray-slight;
    font-size: $type-default;

    li {
      list-style: none;
      font-weight: $regular;
      border: none;

      b {
        strong {
          color: $color-blue;
          font-weight: $regular;
        }
      }

      label {
        font-weight: $default;
        font-size: $type-teeny;
        color: $color-gray-darkest;
        padding: 0;
        margin: 6px 0;
        text-transform: uppercase;
      }
    }

    &:hover {
      a,
      button {
        background: $color-brand-default;
        color: $color-white;
        text-decoration: none;
      }
    }

    a,
    button {
      background: transparent;
      border: none;
      align-self: center;
      color: $color-brand;
      font-weight: $regular;
      font-family: $font-default;
      font-size: $type-default;
      padding: 3px 6px;
      text-align: center;
      cursor: pointer;
      text-decoration: none;
      border-radius: $radius-slight;
      text-transform: uppercase;
      text-decoration: underline;

      &:hover {
        background: $color-brand;
        color: $color-white;
        text-decoration: none;
      }
    }
  }

  &Details {
    display: grid;
    grid-gap: 12px;
    grid-template-columns: repeat(5, auto);
    padding: 6px 0;
    margin: 0 0 12px 0;
    text-transform: none;

    li {
      list-style: none;
      font-weight: $regular;
      font-size: $type-default;
      border: none;

      label {
        font-weight: $default;
        font-size: $type-teeny;
        color: $color-gray-darkest;
        padding: 0;
        margin: 6px 0;
        text-transform: uppercase;
      }
    }
  }

  &TestEvent {
    margin: 0 0 12px 0;

    &Header {
      background: $color-gray-slight;
      font-size: $type-default;
      display: grid;
      grid-gap: 0;
      grid-template-columns: repeat(4, 1fr);
      border-top: 1px solid $color-gray-slight;
      border-bottom: 1px solid $color-gray-slight;
      text-transform: uppercase;

      div {
        padding: 6px;
      }
    }

    &Schedule {
      display: grid;
      grid-gap: 0;

      grid-template-columns: repeat(4, 1fr);
      border-bottom: 1px solid $color-gray-light;

      li {
        list-style: none;
        font-weight: $regular;
        font-size: $type-xxs;
        padding: 6px;
        border: none;
      }
    }
  }
}

.close {
  position: absolute;
  top: 24px;
  right: 24px;

  button {
    background: none;
    border: none;
    font-size: $type-h1;
    text-decoration: none;
    color: $color-gray-dark;
    font-family: $font-default;
    padding: 0;
    text-align: center;
    cursor: pointer;

    &:hover {
      color: $color-gray-darkest;

      background: none;
    }
  }
}

.toggleset {
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(4, auto);

  button {
    background: $color-gray-note;
    border-radius: $radius-slight;
    font-weight: $regular;
    text-decoration: none;
    color: $color-gray-darkest;
    padding: 6px 12px;
    text-align: center;
    border: none;
    font-family: $font-default;
    font-size: $type-default;
    cursor: pointer;

    &:hover {
      background: $color-gray-darker;
      color: $color-white;
    }
  }
}

.fieldset {
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(2, auto);
  padding: 0 0 6px 0;

  .textField {
    background-color: $color-white;
    width: 100%;
    height: 32px !important;
    line-height: 32px !important;

    input {
      font-family: $font-default;
      padding: 12px;
      font-weight: $regular;
      font-size: 13px;
    }

    label {
      color: $color-gray-darkest;
      font-size: $type-default;
      font-family: $font-default;
      font-weight: $regular;
      opacity: 0.7;
    }

    label + div {
      border-radius: 0;
      height: 100%;
    }
  }

  .textFieldOption {
    font-size: 0.875rem;
  }

  .textLabel {
    color: $color-gray-darkest;
    font-size: 0.625rem;
  }

  .textSummary {
    margin-top: 16px;
    font-size: 0.75rem;
  }
}

.notifications {
  height: 24px;
  padding: 12px 0 0 0;
}

.bounce {
  justify-content: center;
  align-items: center;
  animation-name: bounce;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-play-state: running;
  display: inline-flex;
}

.flash {
  display: flex;
  justify-content: center;
  align-items: center;
  animation-name: pulse;
  animation-duration: 0.1s;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: 2;
  animation-play-state: running;

  i {
    padding: 0 3px 0 0;
  }

  &Quantity {
    @extend .flash;
    color: $color-white;
    background: $color-brand;
    padding: 3px 6px;
    font-size: $type-default;
    font-weight: $regular;
  }

  &Error {
    @extend .flash;
    color: $color-white;
    background: $color-red-default;
    padding: 3px 6px;
    font-size: $type-default;
    font-weight: $regular;
  }

  &Success {
    @extend .flash;
    color: $color-white;
    background: $color-mid;
    padding: 3px 6px;
    font-size: $type-default;
    font-weight: $regular;
  }
}

@keyframes pulse {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }

  12% {
    transform: translateY(0);
  }

  24% {
    transform: translateY(6px);
  }

  60% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0);
  }
}
