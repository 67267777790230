@use "shared" as *;

.link,
a {
  color: $color-blue-default;
  font-weight: $default;
  cursor: pointer;
  text-decoration: underline;
  font-size: inherit;

  &:hover {
    color: $color-blue;
  }

  &-close {
    text-decoration: none;
    color: $color-gray-dark;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 24px;
    right: 24px;

    i {
      font-size: $type-lg !important;
      transition: $transition-color;
      padding: 0;

      &:hover {
        transition: $transition-color;
        color: $color-gray-darker;
      }
    }

    &-drawer {
      text-decoration: none;
      color: $color-gray-dark;
      cursor: pointer;
      float: right;

      i {
        font-size: $type-lg !important;
        transition: $transition-color;

        &:hover {
          transition: $transition-color;
          color: $color-gray-darker;
        }
      }
    }
  }

  &-q {
    position: relative;
    font-size: $type-default;
    font-weight: $regular;
    border-radius: $radius-default;
    padding: 4px 24px;
    align-self: center;
    outline: none;
    text-decoration: none;
    cursor: pointer;
    margin: 0 36px 0 0;
    color: $color-blue;
    border-radius: $radius-default;
    background: $color-white-default;
    transition: $transition-background;
    font-weight: $bold;

    &-inverse {
      @extend .link-q;
      color: $color-blue;
      background: $color-blue-slight;
    }
  }

  &-partner {
    background: $color-blue;
    color: $color-white;
    font-size: $type-default;
    border-radius: $radius-slight;
    font-family: $font-default;
    padding: 6px 12px;
    align-self: center;
    outline: none;
    text-decoration: none;
    cursor: pointer;
    color: $color-white !important;
    border: none !important;
    width: 100%;
    font-weight: $default;
    text-align: center;
    margin: 0 0 12px 0;
    width: 100%;

    @media (min-width: $break-xs) {
      margin: 0;
      width: auto;
    }

    &:hover {
      background: $color-blue;
      border: none !important;
    }
  }

  &-breadcrumbs {
    font-size: $type-default;
    padding: 3px 16px;
    margin: 0 0 0 12px;
    background: $color-blue-default;
    color: $color-white;
    border-radius: $radius-slight;
    cursor: pointer;
    font-weight: $regular;
    display: inline-flex;
    align-items: center;
    font-weight: $regular;

    &-reset {
      margin: 0 0 0 12px;
      color: $color-blue;
      text-decoration: underline;
      cursor: pointer;
      font-weight: $regular;

      &:hover {
        color: $color-pink;
      }
    }

    i {
      color: $color-white;
      margin: 0 0 0 6px;
      font-size: $type-default;
      vertical-align: middle;
      transition: $transition-scale;
    }

    &:hover {
      background: $color-pink;
      color: $color-white;
      i {
        transform: scale(1.3) rotate(90deg);
        transition: $transition-scale;
        color: $color-white;
      }
    }
  }

  &-get {
    background: $color-blue-default;
    color: $color-white;
    font-size: $type-default;
    border-radius: $radius-slight;
    font-family: $font-default;
    padding: 6px 36px;
    width: auto;
    text-align: center;
    text-decoration: none;
    font-weight: $default;
    margin: 0 12px 0 0;
    font-weight: $default;

    &:hover {
      background: $color-blue;
      border: none !important;
      color: $color-white;
    }
  }

  &-cert {
    color: $color-blue-default;
    font-size: $type-default;
    border-radius: $radius-slight;
    font-family: $font-default;
    width: auto;
    text-align: center;
    text-decoration: underline;
    font-weight: $regular;
    margin: 0;
    float: right;
    padding: 0;

    &:hover {
      color: $color-blue;
      border: none !important;
    }
  }

  &-thumb {
    margin-right: 10px;
  }

  &-wide {
    display: flex;
    background: $color-white-default;
    color: $color-blue;
    font-size: $type-default;
    padding: 12px;
    margin: 12px 0 0 0;
    text-transform: uppercase;
    text-decoration: none;
    align-items: center;

    strong {
      font-weight: $bold;
      padding: 0 6px 0 0;
    }

    i {
      color: $color-blue;
      padding: 0 12px 0 6px;
    }

    .pdf {
      padding: 0 12px 0 6px;
      font-size: $type-md;

      &:before {
        color: $color-pink-default;
      }

      &:after {
        color: $color-blue-half;
      }
    }

    &:hover {
      background: $color-white;

      i {
        transform: scale(1.2);
        transition: $transition-scale;
        transform-origin: center center;
      }
    }

    &-inert {
      @extend .link-wide;
      color: $color-blue-default;
      background: $color-white-medium;

      &:hover {
        background: $color-white-default;
      }
    }
  }

  &-all {
    color: $color-blue-default;
    text-decoration: underline;
    font-size: $type-default;
    font-weight: $regular;
    cursor: pointer;
    height: 60px;
    align-items: center;
    display: inline-flex;

    &:hover {
      color: $color-blue;
    }

    &-inert {
      @extend .link-all;
      color: $color-gray-darkest;
      text-decoration: none;
      cursor: default;

      span {
        margin: 0 0 0 6px;
      }

      &:hover {
        color: $color-gray-darkest;
      }
    }
  }

  &-urgent {
    font-size: $type-default;
    font-weight: $regular;
    border-radius: $radius-default;
    padding: 4px 12px;
    outline: none;
    text-decoration: none;
    cursor: pointer !important;
    margin: 0 36px 0 0;
    color: $color-white;
    border-radius: $radius-default;
    background: $color-pink-medium;
    transition: $transition-background;
    border: none;
    display: block;

    i {
      color: $color-white !important;
      font-size: $type-tiny !important;
      vertical-align: middle;
    }

    &:hover {
      background: $color-pink;
    }
  }

  &-edit {
    color: $color-blue-default !important;
    font-weight: $bold !important;
    cursor: pointer;
    text-decoration: underline;
    float: right;
    font-size: $type-default;
    margin: 0 0 0 12px;

    i {
      color: $color-blue-default !important;
      padding: 0 3px 0 0 !important;
    }
  }

  &-store {
    cursor: pointer;
    width: 100%;
    font-size: $type-default;
    color: $color-white;
    font-weight: $regular;
    height: auto;
    font-family: $font-default;
    padding: 9px 24px;
    margin: 6px 0 0 0;
    border-radius: $radius-default;
    background: $color-blue-default;
    text-decoration: none;
    text-transform: uppercase;
    border: 1px solid $color-blue-default;

    &:hover {
      background: $color-blue;
      transition: $transition-background;
      border: 1px solid $color-blue;
    }

    &-disabled {
      border: 1px solid $color-gray-light;
      @extend .link-store;
      color: $color-gray;
      background: $color-gray-slight;
      cursor: not-allowed;

      &:hover {
        border: 1px solid $color-gray-light;
        color: $color-gray;
        background: $color-gray-slight;
      }
    }

    &-inactive {
      @extend .link-store;
      cursor: not-allowed;
      background: $color-gray-slight;
      color: $color-gray;
      border: 1px solid transparent;

      &:hover {
        cursor: not-allowed;
        background: $color-gray-slight;
        color: $color-gray;
        border: 1px solid transparent;
      }
    }

    &-processing {
      @extend .link-store;
      cursor: not-allowed;
      background: transparent;
      color: $color-blue;
      border: 1px solid $color-blue;

      &:hover {
        background: transparent;
        color: $color-blue;
        cursor: not-allowed;
        border: 1px solid $color-blue;
      }
    }
  }

  &-agent {
    cursor: pointer;
    width: 475px;
    box-sizing: border-box;
    color: $color-white;
    font-size: $type-default;
    font-weight: $regular;
    height: auto;
    font-family: $font-default;
    padding: 9px 24px;
    margin: 24px 0 36px 0;
    border-radius: $radius-default;
    background: $color-blue-default;
    transition: $transition-background;
    text-decoration: none;
    border: 1px solid $color-blue-default;
    text-align: center;

    &-sm {
      color: $color-white;
      font-size: $type-default;
      font-weight: $regular;
      height: auto;
      font-family: $font-default;
      text-align: center;
      padding: 9px 24px;
      margin: 0;
      display: flex;
      justify-content: center;
      border-radius: $radius-default;
      background: $color-blue-default;
      transition: $transition-background;
      text-decoration: none;
      border: 1px solid $color-blue-default;
      box-sizing: border-box;
    }

    &-sm {
      @extend .link-agent;
      position: relative;
      text-align: center;
      text-transform: uppercase;

      &:hover {
        color: $color-white;
      }
    }

    &:hover {
      color: $color-white;
    }

    &-alt {
      @extend .link-agent-sm;
      background: $color-white;
      color: $color-blue-default;
      border: 1px solid $color-blue-default;

      &:hover {
        background: $color-white;
        color: $color-blue;
        border: 1px solid $color-blue;
      }
    }
  }

  &-webinar {
    color: $color-blue-default;
    font-size: $type-copy;
    outline: none;
    text-decoration: underline;
    cursor: pointer;
    padding: 6px 0;

    &:hover {
      color: $color-blue;
    }
  }

  &-text {
    color: $color-brand-default;
    font-size: $type-default;
    outline: none;
    text-decoration: underline;
    cursor: pointer;
    padding: 6px 0;

    &-lg {
      font-size: $type-lg;
    }

    &:hover {
      color: $color-blue;
    }
  }

  &-related {
    color: $color-blue;
    text-decoration: none;
    width: auto;
    cursor: pointer;
    font-size: $type-default;
    display: flex;
    padding: 3px 0;

    &-icon {
      @extend .link-related;

      i {
        font-size: $type-lg !important;
      }
    }

    i {
      color: $color-blue-half;
      font-size: $type-sm;
      padding: 0 3px;
      align-self: center;
    }

    &:hover {
      color: $color-blue;

      i {
        color: $color-blue;
      }
    }
  }

  &-null {
    cursor: pointer;
  }

  &-resource {
    text-decoration: none;
    font-size: $type-xs !important;
    cursor: pointer;
    margin-bottom: 12px !important;
    font-weight: $default !important;
    color: $color-blue !important;
    font-weight: $regular !important;

    &:hover {
      color: $color-blue !important;
    }
  }

  &-hamburger {
    cursor: pointer;
    color: $color-blue-medium;

    i {
      font-size: $type-lg;

      @media (min-width: $break-md) {
        font-size: $type-md;
      }
    }
  }

  &-direct {
    font-size: $type-md;
    padding: 6px 0;

    i {
      font-size: $type-md;
      margin: 0 6px 0 0;
    }
  }

  &-dialog {
    padding: 6px 24px;
    text-decoration: none;
    display: flex;
    align-items: center;
    width: 100%;
    line-height: $type-md;
    display: flex;
    font-weight: $regular;
    color: $color-blue;
    font-size: $type-default;
    cursor: pointer;

    #flag {
      padding: 0 3px;
      border-radius: $radius-slight;
      background: $color-green;
      color: $color-white;
      font-size: $type-teeny;
      font-weight: $bold;
      margin: 0 0 0 3px;
      z-index: $z-ultimate;
    }

    &:hover {
      background: $color-blue-note;
    }
  }

  &-covid {
    color: $color-pink;
    font-size: $type-xs !important;
    padding: 10px 20px;
    text-decoration: none;
    text-transform: uppercase;
    display: block;
    width: 85%;
    font-weight: $regular;
    line-height: $type-md;

    @media (min-width: $break-sm) {
      font-size: $type-default !important;
      line-height: $type-md;
      width: 100%;
    }

    &:hover {
      background: $color-pink;
      color: $color-white;
    }

    &-novel {
      @extend .link-covid;
      background: $color-gray-darkest;
      color: $color-white;

      i {
        color: $color-white !important;
        font-family: $font-awesome !important;
        font-size: $type-sm;
      }

      &:hover {
        background: $color-gray-darkest;
        color: $color-white;
      }
    }
  }

  &-cookie {
    background: $color-gray-slight;
    color: $color-gray-darker;
    font-size: $type-default;
    border-radius: $radius-slight;
    padding: 0 6px;
    align-self: center;
    outline: none;
    text-decoration: none;
    transition: $transition-background;
    margin: 0 4px;
    cursor: pointer;

    &:hover {
      color: $color-white;
      background: $color-gray-dark;
      transition: $transition-background;
    }
  }

  &-inert {
    text-decoration: none;
    border-bottom: none !important;
    outline: none !important;

    &:hover {
      border-bottom: none !important;
    }
  }

  &-footer {
    display: flex;
    color: $color-brand-solid;
    font-size: $type-default;
    text-decoration: underline;
    cursor: pointer;
    padding: 9px 0;
    font-weight: $regular;

    &:hover {
      color: $color-brand-solid;
    }

    &-nav {
      display: flex;
      color: $color-white-default;
      font-size: $type-default;
      font-weight: $regular;
      text-decoration: none;
      cursor: pointer;
      padding: 9px 0;

      &:hover {
        color: $color-brand-solid;
      }
    }
  }

  &-email {
    color: $color-blue-default;
    font-size: $type-default;
    transition: $transition-color;
    text-decoration: underline;
    border-bottom: 1px solid transparent;
    font-weight: $regular;
    cursor: pointer;

    &:hover {
      color: $color-white;
      border-bottom: 2px solid $color-blue-light;
      transition: $transition-border;
    }

    &-light {
      color: $color-blue;

      &:hover {
        color: $color-white;
      }
    }
  }

  &-nav {
    color: $color-blue;
    transition: $transition-color;
    text-decoration: none;
    font-weight: $regular;
    border-bottom: 1px solid transparent;
    margin-top: 10px;
    border-bottom: 2px solid $color-blue-note;
    cursor: pointer;
    font-size: $type-default !important;
    margin: 0 36px 0 0;
    text-transform: uppercase;

    b {
      vertical-align: super;
      text-decoration: none !important;
    }

    strong {
      font-weight: $bold;
    }

    &:hover {
      color: $color-blue;
      border-bottom: 2px solid $color-blue-light;
      transition: $transition-border;
    }
  }

  &-default {
    color: $color-blue !important;
    font-size: $type-default;
    cursor: pointer;
    transition: $transition-color;
    text-decoration: none;
    font-weight: $regular;
    border-bottom: 2px solid $color-blue-note;
    // margin-top: 10px;

    &:hover {
      color: $color-blue;
      border-bottom: 2px solid $color-blue-slight;
      transition: $transition-border;
    }
  }

  &-tooltip {
    @extend .link-default;
    border-bottom: none;
    color: $color-gray !important;

    i {
      font-size: $type-default;
    }

    &:hover {
      color: $color-gray-dark !important;
      border-bottom: none;
      transition: $transition-border;
    }
  }

  &-expand {
    @extend .link-default;
    border-bottom: none;
    color: $color-gray !important;
    font-size: $type-teeny;
    padding: 6px 0;
    display: block;

    i {
      font-size: $type-teeny;
    }

    &:hover {
      color: $color-gray-dark !important;
      border-bottom: none;
      transition: $transition-border;
    }
  }

  &-filter {
    cursor: pointer;
    transition: $transition-color;
    text-decoration: none;
    font-weight: $regular;
    border-bottom: 2px solid $color-brand-note;
    // margin-top: 10px;
    color: $color-brand;

    &:hover {
      color: $color-brand;
      border-bottom: 2px solid $color-brand-light;
      transition: $transition-border;
    }
  }

  &-remove {
    font-size: $type-default;
    color: $color-yellow;
    text-decoration: underline;
    cursor: pointer;
    font-weight: $regular;
  }

  &-order {
    font-size: inherit;
    text-decoration: none;

    text-align: center;
    justify-content: center;
    font-weight: $bold;
    color: $color-brand;
    margin: 0 3px 0 0;
    // -webkit-user-select: none;
    // -moz-user-select: none;
    // -ms-user-select: none;
    // user-select: none;
    text-transform: uppercase;

    @media (min-width: $break-md) {
      margin: 0;
      min-width: 72px;
      display: block;
    }

    em {
      font-style: normal;
      color: $color-gray-darker;
    }

    &-video {
      @extend .link-order;
      cursor: default !important;

      &:hover {
        background: $color-blue-slight;
      }
    }

    &-covid {
      @extend .link-order;
      background: $color-blue-note;
      // color: $color-red;

      em {
        font-style: normal;
        color: $color-gray-darkest;
      }
    }
  }

  &-chip {
    cursor: pointer;
    font-size: $type-default;
    text-decoration: none;
    padding: 6px 12px;
    grid-column: 1 / -1;
    display: grid;
    grid-gap: 6px;
    flex-direction: column;
    grid-template-columns: 90px auto 24px;
    font-weight: $regular;
    border-bottom: 1px solid $color-gray-light;
    margin: 0;
    align-items: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    @media (min-width: $break-md) {
      padding: 9px 12px;
    }

    span {
      color: $color-gray-darkest;

      em {
        font-style: normal;
        color: $color-gray-darkest;
      }

      b {
        color: $color-brand;
        font-weight: $regular;
      }
    }

    i {
      vertical-align: middle;
      align-self: center;
      float: right;
      color: $color-gray-light;
      margin: 0 0 0 6px;
      cursor: pointer;
      font-size: $type-default;
    }

    &:hover {
      i {
        color: $color-pink-default;
      }
    }
  }

  &-quantity {
    font-size: $type-default;
    text-decoration: none;
    padding: 6px 12px;
    grid-column: 1 / -1;
    display: grid;
    grid-gap: 12px;
    flex-direction: column;
    grid-template-columns: 96px auto 72px 36px;
    font-weight: $regular;
    border-bottom: 1px solid $color-gray-light;
    margin: 0;
    align-items: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    @media (min-width: $break-lg) {
      padding: 12px;
      grid-template-columns: 120px auto 72px 36px;
    }

    @media (min-width: $break-xl) {
      padding: 12px;
      grid-template-columns: 108px auto 72px 36px;
    }

    @media (min-width: $break-xxl) {
      padding: 12px;
      grid-gap: 24px;

      grid-template-columns: 132px auto 72px 36px;
    }

    label {
      display: block;
      color: $color-gray-dark;
      font-size: $type-tiny;
    }

    span {
      color: $color-gray-darkest;
      line-height: $type-default !important;

      small {
        font-size: $type-tiny;
        margin: 3px 0 0 0;
        display: flex;
      }

      em {
        font-style: normal;
        color: $color-gray-darkest;
        text-transform: uppercase;
      }

      b {
        color: $color-brand;
        font-weight: $regular;
        text-transform: uppercase;
      }
    }

    #quantity {
      display: inline-flex;
      align-items: center;
      justify-content: center;

      i {
        vertical-align: middle;
        align-self: center;
        float: right;
        color: $color-gray;
        font-size: $type-teeny;
        padding: 12px;
        transition: $transition-scale;
        cursor: pointer;

        &:hover {
          cursor: pointer;
          color: $color-gray-darkest;
          transform: scale(1.5);
          transition: $transition-scale;
        }
      }

      span {
        outline: 2px solid $color-gray-slight;
        background: $color-gray-slight;
        color: $color-gray-darkest;
        padding: 3px;
        min-width: 24px;
        font-weight: $regular;
        text-transform: uppercase;
        align-items: center;
        justify-content: center;
        text-align: center;
        border-radius: $radius-slight;
        font-size: $type-default;
      }
    }

    .remove {
      vertical-align: middle;
      align-self: center;
      float: right;
      color: $color-gray-half;
      font-size: $type-sm;
      padding: 6px;
      cursor: pointer;

      &:hover {
        transform: scale(1.2) !important;
        color: $color-pink;
      }
    }

    &:hover {
      i {
        color: $color-pink-default;
      }

      &:hover {
        #quantity i {
          transform: scale(1.2) !important;
          color: $color-gray-darkest;
        }
      }
    }
  }

  &-buy {
    cursor: pointer !important;
    border-radius: $radius-slight;
    font-size: $type-tiny;
    line-height: $type-tiny !important;
    text-decoration: none;
    text-align: left;
    font-weight: $regular;
    color: $color-blue-medium;
    display: none;

    &:before {
      padding: 0 2px !important;
      font-size: $type-tiny;
      display: inline-flex;
      line-height: $type-tiny !important;
      color: $color-white;
      background: $color-orange;
      border-radius: $radius-slight;
      text-align: center;
      pointer-events: none !important;
      content: "+";
      margin-right: 6px;
    }

    &:hover {
      color: $color-blue;
    }
  }

  &-cart {
    color: $color-white;
    font-size: $type-default;
    border-radius: $radius-slight;
    padding: 4px;
    align-self: center;
    outline: none;
    text-decoration: none;
    transition: $transition-scale;
    transform: scale(1);
    margin-top: 10px;
    cursor: pointer;
    display: inline-block;
    position: relative;

    i {
      font-size: $type-md;
      color: $color-blue;
    }

    .bubble {
      position: absolute;
      top: -4px;
      right: -6px;
      width: 18px;
      height: 18px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      vertical-align: middle;
      font-size: $type-tiny;
      font-weight: $regular;
      border-radius: 100%;
      background: $color-green;
      color: $color-white;
    }

    // span {
    //   background-color: $color-green;
    //   color: $color-white;
    //   font-weight: $regular;
    //   font-size: 100%;
    //   border-radius: 10em;
    //   min-width: 1.5em;
    //   padding: 0.25em;
    //   text-align: center;
    //   margin-left: 6px;
    // }

    &-add {
      @extend .link-cart;
      animation: pop2 0.5s linear;

      .bubble {
        position: absolute;
        top: -4px;
        right: -6px;
        width: 18px;
        height: 18px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        vertical-align: middle;
        font-size: $type-tiny;
        font-weight: $regular;
        border-radius: $radius-round;
        background: $color-green;
        color: $color-white;

        animation: pop1;
      }
    }
  }

  &-complete {
    cursor: pointer !important;
    border-radius: $radius-slight;
    text-decoration: none;
    display: inline-block;
    justify-content: center;
    margin: 0 24px !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: $color-white;
    background: $color-green-default;
    text-align: center;
    font-size: $type-default;
    margin: 0;
    padding: 6px 12px;
    min-width: 100px !important;

    &:hover {
      background: $color-green;
    }
  }

  &-add {
    cursor: pointer !important;
    border-radius: $radius-slight;
    text-decoration: none;
    display: inline-block;
    justify-content: center;
    // -webkit-user-select: none;
    // -moz-user-select: none;
    // -ms-user-select: none;
    // user-select: none;
    text-align: center;
    font-size: $type-default;
    margin: 0;
    min-width: 100px !important;
    color: $color-white;
    background: transparent;
    text-align: center;
    padding: 6px 12px;
    z-index: $z-ultimate;

    i {
      font-size: $type-tiny;
      margin-right: 6px;
    }

    &:hover {
      background: $color-gray-darkest;
    }
  }

  &-more {
    font-weight: $regular;
    color: $color-blue;
    font-size: $type-default !important;
    line-height: inherit !important;
    text-transform: capitalize !important;
    cursor: pointer;
    text-decoration: none;
    padding: 0;
    margin-left: 12px;
    width: auto;
    border-radius: $radius-slight;
    border-bottom: 2px solid $color-blue-slight;

    &:hover {
      color: $color-blue-medium;
      border-bottom: 2px solid $color-blue-light;
    }
  }

  &-learn {
    font-weight: $regular;
    color: $color-blue-medium;
    font-size: $type-default;
    line-height: inherit;
    cursor: pointer;
    text-decoration: none;
    padding: 3px 6px;
    border-radius: $radius-slight;

    &:hover {
      background: $color-blue-default;
      color: $color-white;
    }
  }

  &-product {
    border-radius: $radius-slight;
    background: $color-white;
    color: $color-mid-default;
    cursor: pointer;
    text-decoration: none;
    font-size: $type-tiny;
    margin: 0 12px 0 0;
    font-weight: $regular;
    align-self: center;
    text-align: center;
    border: none;
    font-family: $font-default;
    transition: $transition-outline;
    text-transform: uppercase;
    outline: 1px solid $color-mid-light;
    padding: 6px 12px;
    min-height: 24px;
    align-items: center;
    display: inline-flex;

    &:hover {
      background: $color-mid;
      color: $color-white;
      outline: 1px solid $color-mid-light;
      transition: $transition-outline;
    }

    &-default {
      border-radius: $radius-slight;
      background: $color-white;
      color: $color-blue;
      cursor: pointer;
      text-decoration: none;
      font-size: $type-tiny;
      font-weight: $regular;
      border: none;
      font-family: $font-default;
      transition: $transition-outline;
      outline: 1px solid $color-blue-light;

      padding: 2px 12px;
      margin: 6px 0 0 0;
    }

    &-text {
      background: transparent;
      color: $color-white;
      cursor: pointer;
      text-decoration: underline;
      font-size: $type-tiny;
      font-weight: $regular;
      border: none;
      font-family: $font-default;
      transition: $transition-outline;
      outline: none;
      padding: 0 36px;
      margin: 0;
      text-transform: uppercase;
    }

    &-price {
      font-family: $font-default;
      text-transform: uppercase;
      background: transparent;
      color: $color-gray-darkest;
      // cursor: pointer;
      text-decoration: none;
      font-size: $type-md;
      font-weight: $default;
      text-align: center;
      align-self: center;
      justify-content: center;
      border: none;
      outline: none;
      min-width: 144px;
      padding: 0 36px;
      border-left: 1px solid $color-gray-half;
      display: inline-flex;

      &:hover {
      }
    }

    &-details {
      font-family: $font-default;
      font-weight: $regular;
      color: $color-blue;
      cursor: pointer;
      text-decoration: underline;
      font-size: $type-tiny;
      display: flex;
      margin: 6px 12px 0 0;
      display: inline-flex;
      text-transform: uppercase;
      padding: 6px 12px;

      @media (min-width: $break-md) {
        border-radius: $radius-slight;
        background: $color-white;
        color: $color-blue;
        cursor: pointer;
        text-decoration: none;
        font-size: $type-tiny;
        margin: 0 12px 0 0;
        font-weight: $regular;
        align-self: center;
        text-align: center;
        border: none;
        outline: 1px solid $color-blue-light;
        transition: $transition-outline;

        &:hover {
          background: $color-blue !important;
          color: $color-white;
          outline: 1px solid $color-blue-light;
          transition: $transition-outline;
        }
      }
    }

    &-mobile {
      background: transparent;
      font-weight: $regular;
      color: $color-mid;
      border: 1px solid $color-mid-default;
      font-size: $type-xxs;
      line-height: inherit;
      cursor: pointer;
      text-decoration: none;
      padding: 6px 12px;
      border-radius: $radius-slight;
      display: inline-flex;
      justify-content: center;
      align-self: center;

      @media (min-width: $break-md) {
        display: none;
      }

      &:focus,
      &:active {
        color: $color-white;
        border: 1px solid $color-mid-default;
        background: $color-mid;
      }
    }

    &-hilite {
      @extend .link-product;
      background: $color-blue;
      color: $color-white;
      display: inline-flex;
      width: 100%;
      margin: 12px 0;
      padding: 9px 12px;
      justify-content: center;
      text-align: center;
      align-items: center;
      text-transform: uppercase;
      border: none;
      font-size: $type-default;
      font-family: $font-default;
      border: none;

      i {
        align-self: center;
        padding: 0 6px 0 0;
      }

      &:hover {
        color: $color-white;
        background: $color-blue;
      }
    }

    &-family {
      @extend .link-product-details;
      background: transparent;
      border: 1px solid $color-blue-medium;
      color: $color-blue-default;

      &:hover {
        background: transparent;
        border: 1px solid $color-blue-default;
        color: $color-blue;
      }
    }
  }

  &-right {
    float: right;
    color: white;
    font-size: $type-xl;
    padding: 6px;
    cursor: pointer;
    z-index: $z-ultimate;
  }

  &-details {
    @extend .link-more;
    margin: 0;
  }

  &-small {
    font-size: $type-default;
    padding: 2px 6px 2px 12px;
    margin: 0 0 0 6px;
    background: $color-brand-medium;
    color: $color-white;
    border-radius: $radius-slight;
    cursor: pointer;
    transition: $transition-background;

    i {
      margin: 0 3px;
      font-size: $type-xxs;
      color: $color-white-medium;
      transition: $transition-color;
      vertical-align: middle;
    }

    &:hover {
      transition: $transition-background;
      background: $color-brand;

      i {
        transition: $transition-color;
        color: $color-white;
      }
    }
  }

  &-submit {
    color: $color-brand-medium;
    font-size: $type-lg;
    padding: 0 6px;
    cursor: pointer;
    transition: $transition-color;

    &:hover {
      color: $color-brand;
      transition: $transition-color;
    }
  }

  &-support {
    @extend .link-learn;
    margin: 24px 0 0 24px;
    font-weight: $regular;
  }

  &-download {
    @extend .link-learn;
    margin: 0 0 0 24px;
    font-weight: $regular;
    line-height: $type-default;
    color: $color-blue-medium;
    border-bottom: none;
    text-decoration: underline;
    font-size: $type-default;
    padding: 0 !important;
    text-align: right;
    text-transform: uppercase;
    font-weight: $bold;
    padding: 6px 12px;

    &-soon {
      @extend .link-learn;
      color: $color-blue-medium;
    }

    &:hover {
      color: $color-blue;
      border-bottom: none;

      i {
        color: $color-blue-medium;
      }
    }

    i {
      color: $color-blue-half;
      font-size: $type-default;
      padding: 6px;
      border-bottom: 2px solid white !important;
    }
  }

  &-admin {
    background: $color-brand-medium;
    padding: 1px 6px;
    display: flex;
    border-radius: $radius-slight;
    color: $color-white;
    font-size: $type-default;
    text-decoration: none;
    margin: 6px 0;
    cursor: pointer;

    &:hover {
      background: $color-brand;
    }
  }

  &-temp {
    color: $color-pink-medium !important;
    font-size: inherit;
    transition: $transition-color;
    text-decoration: none;
    font-weight: $regular;
    border-bottom: 2px solid $color-pink-slight !important;

    &:hover {
      color: $color-pink !important;
      border-bottom: 2px solid $color-pink-light !important;
      transition: $transition-border;
    }
  }

  &-top {
    border-bottom: none;
    color: $color-blue-half;
    text-decoration: none;
    font-size: $type-default;

    i {
      font-size: $type-tiny;
      padding: 0;
      margin: 0;
    }

    &:hover {
      border-bottom: none;
      color: $color-blue-medium;
    }
  }

  &-login {
    font-size: $type-default;
    font-weight: $regular;
    border-radius: $radius-default;
    padding: 4px 24px;
    align-self: center;
    outline: none;
    text-decoration: none;
    cursor: pointer;
    margin: 0 36px 0 0;

    color: $color-white;
    border-radius: $radius-default;
    background: $color-blue-default;
    transition: $transition-background;

    &:hover {
      // background: $color-blue;
      // transition: $transition-background;

      color: $color-white;
      background: $color-blue;
      transition: $transition-background;
    }
  }

  &-novel {
    background: $color-gray-darkest;
    color: $color-white;
    font-size: $type-default;
    border-radius: $radius-slight;
    padding: 4px 6px;
    align-self: center;
    outline: none;
    text-decoration: none;
    transition: $transition-scale;
    transform: scale(1);
    // margin: 24px 12px 0 0;
    margin-right: 12px;
    cursor: pointer;
    border: 2px solid transparent;
    transition: $transition-color, $transition-background;

    &:hover {
      transition: $transition-scale;
      transform: scale(1.1);
      background: transparent;
      color: $color-gray-darkest;
      border: 2px solid $color-gray-darkest;
      transition: $transition-color, $transition-background;
    }
  }

  &-disabled {
    background: $color-gray-slight;
    color: $color-gray-light;
    font-size: $type-default;
    border-radius: $radius-slight;
    padding: 4px 10px;
    align-self: center;
    outline: none;
    text-decoration: none;
    transform: scale(1);
    margin-top: 10px;
    cursor: not-allowed;
  }

  &-cta {
    background: $color-blue-medium;
    color: $color-white;
    font-size: $type-sm;
    border-radius: $radius-slight;
    padding: 4px 12px;
    align-self: center;
    outline: none;
    text-decoration: none;
    transition: $transition-scale;
    transform: scale(1);
    margin-top: 10px;
    cursor: pointer;
    color: $color-white !important;
    border: none !important;

    &:hover {
      transition: $transition-scale;
      transform: scale(1.1);
      background: $color-blue;
      border: none !important;
    }
  }

  &-close-video {
    text-decoration: none;
    color: $color-gray-medium;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;

    i {
      color: $color-gray-medium !important;
      transition: $transition-color;
      font-size: $type-md;

      &:hover {
        transition: $transition-color;
        color: $color-gray-dark !important;
      }
    }
  }

  &-drawer {
    &-close {
      text-decoration: none;
      color: $color-gray-dark;
      cursor: pointer;

      i {
        transition: $transition-color;
        padding: 0 25px;

        &:hover {
          transition: $transition-color;
          color: $color-gray-darker;
        }
      }
    }
  }

  &-inert {
    text-decoration: none;
    color: inherit;
  }

  &-imagebank {
    display: block;
    text-decoration: none;
    color: $color-blue-medium;
    cursor: pointer;
    padding: 10px 0 !important;
    font-size: $type-xs;
    border-top: 1px solid $color-gray-slight;
    transform-origin: center left;

    &:first-child {
      border-bottom: 1px solid $color-gray-slight;
    }

    strong {
      padding: 0 6px;
    }

    i {
      color: $color-blue-medium;
      padding: 0 6px;
    }

    &:hover {
      color: $color-blue;

      i {
        color: $color-blue-medium;
      }
    }
  }

  &-settings {
    background: $color-brand;
    color: $color-white;
    border-radius: $radius-default;
    padding: 5px 12px;
    cursor: pointer;
    transition: $transition-background;
    margin: 0 0 0 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    text-decoration: none;
    transform: scale(1);
    transition: $transition-scale;

    span {
      padding: 0 5px;
      font-size: $type-sm;
      font-weight: $regular;
    }

    i {
      font-size: $type-sm;
      align-self: center;
      align-items: center;
      align-content: center;
      justify-content: center;
      transition: $transition-scale;
    }

    &:hover {
      i {
        transform: scale(1.2);
        transition: $transition-scale;
      }
    }
  }

  &-git {
    @extend .link-settings;
    background: $color-gray-darker;
    color: $color-white;

    i {
      font-size: $type-md;
    }
  }

  &-copy {
    @extend .link-settings;
    background: $color-gray-note;
    color: $color-blue;
    font-size: $type-default;
    padding: 2px 8px;

    &:hover {
      background: $color-blue-medium;
      color: $color-white;
    }

    i {
      font-size: $type-default;
      padding: 0 4px;
      opacity: 0.6;
    }
  }

  &-copy-embed {
    color: $color-blue !important;
    font-size: $type-tiny;
    cursor: pointer;
    text-align: left;
    justify-content: flex-end;
    align-items: flex-end;
    display: flex;
  }

  &-copy-ok {
    @extend .link-copy-embed;
    color: $color-green !important;
    cursor: default;

    i {
      align-self: center;
      padding: 3px;
      color: $color-green !important;
    }
  }

  &-notice {
    @extend .link-settings;

    &:after {
      background: $color-pink;
      color: $color-white;
      padding: 0 4px;
      content: "NEW";
      font-size: $type-tiny;
      margin-right: 0px;
      margin-top: -25px;
      transition: $transition-scale;
      transform: scale(1);
      transform-origin: left center;
    }

    i {
      margin-left: 30px;
      font-size: $type-md !important;
      color: $color-white !important;
      animation: pulse 0.5s 10;
    }

    &:hover {
      &:after {
        transition: $transition-scale;
        transform: scale(1.2);
        transform-origin: left center;
      }
    }
  }

  &-pdf {
    @extend .link-settings;
    background: $color-blue-medium;
    color: $color-white;
    width: 80px;
    height: 16px;
    margin: 6px 0;
    font-size: $type-sm;
    transition: $transition-background;

    i {
      color: $color-white-half;
      font-size: $type-sm;
      margin-right: 5px;
    }

    &:hover {
      background: $color-blue;
      transition: $transition-background;

      i {
        color: $color-white;
        transform: scale(1);
      }
    }
  }
}
