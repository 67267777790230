@use "shared" as *;

.product {
  &-virtual {
    border-bottom: 1px solid $color-gray;
    padding: 0;

    display: grid;
    grid-template-columns: auto 200px;
    flex-direction: column;
    gap: 6px;

    h2 {
      font-weight: $regular;
    }

    button {
      background: transparent;
      border: 1px solid transparent;
      cursor: pointer;
      outline: none;
      color: $color-blue;
      font-weight: $regular;
      font-size: $type-sm;
      align-items: center;
      text-align: center;
      vertical-align: middle;
      margin: 0;
      text-decoration: underline;
      font-family: $font-default;
      padding: 0;
      justify-content: flex-end;
      display: flex;
    }
  }

  &-video {
    display: flex;
    flex-direction: column;
    padding: 24px;
    margin: 12px 0 0 0;

    h1 {
      font-weight: $regular;
      font-size: $type-sm;
      padding: 3px 0 0 0;
      margin: 0;
      color: $color-gray-darkest;
    }

    h2 {
      font-weight: $default;
      font-size: $type-default;
      padding: 0;
      margin: 0;
    }

    iframe {
      width: 100%;
      border: 1px solid $color-gray;
      min-width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: 6px 0 12px 0;
    }

    @media (min-width: $break-xxs) {
      padding: 0 12px !important;
    }

    @media (min-width: $break-sm) {
      padding: 0 24px !important;
    }

    &-related {
    }
  }

  &-nav {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    background: $color-white;
    box-shadow: 0 0 24px $color-gray-slight;
    padding: 12px;
    z-index: $z-ultimate;

    @media (min-width: $break-md) {
      padding: 12px 24px;
    }

    &-status {
      padding: 6px;
    }

    button {
      outline: none;
      padding: 3px 6px;
      border: 1px solid $color-blue-light;
      background: transparent;
      color: $color-blue;
      margin: 0 6px 0 0;
      border-radius: $radius-slight;
      font-family: $font-default;
      font-size: $type-default;
      cursor: pointer;
      font-weight: $regular;
      transition: $transition-border;
      min-width: 72px;

      &:disabled {
        color: $color-gray !important;
        border: 1px solid $color-gray-light !important;
      }

      &:hover {
        border: 1px solid $color-blue-default;
        transition: $transition-border;
      }

      i {
        font-size: $type-tiny !important;
      }
    }
  }

  &-drawer {
    z-index: $z-drawer !important;

    &.MuiDrawer-paper {
      width: 100% !important;
      background: $color-white !important;
      box-shadow: 12px 0 24px $color-gray-slight !important;
      color: $color-gray-darkest !important;
      display: block !important;
      overflow-y: scroll !important;
      overflow-x: hidden !important;
      text-transform: none;
      z-index: $z-drawer !important;

      @media (min-width: $break-xxs) {
        width: 420px !important;
      }

      @media (min-width: $break-md) {
        width: 600px !important;
      }
    }
  }

  &-additional {
    &-contain {
      padding: 0 12px;
      min-height: 100px;

      @media (min-width: $break-xxs) {
        padding: 0 12px !important;
      }

      @media (min-width: $break-sm) {
        padding: 0 24px !important;
      }
    }

    ul > li {
      font-size: $type-default;
      font-weight: $default;
    }

    &-group {
      margin: 12px 0;
      padding: 0;
      font-size: $type-default;
      font-weight: $default;

      ul > li {
        font-size: $type-default;
        font-weight: $default;
      }
    }

    small {
      display: block;
      text-transform: uppercase;
      font-size: $type-note;
      color: $color-gray-darkest;
      font-weight: $regular;
      margin: 24px 0 3px 0;
    }

    ul {
      padding: 0;
      margin: 0;

      li > i {
        font-style: italic;

        &:hover {
          background: transparent !important;
        }
      }
    }

    #analytes {
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
      background: $color-gray-slight;
      padding: 12px 24px;

      i {
        font-style: normal;
        padding: 0;
        margin: 0;
        text-transform: none;
      }
    }

    ol {
      padding: 0 24px;
      margin: 0;

      li {
        list-style-type: decimal !important;
        font-size: $type-default;
        padding: 6px 0;
      }
    }

    li {
      border: unset;
      list-style-type: none;
      font-size: $type-default;
      font-weight: $default;
      margin: 0;
      background: transparent;

      &.plain-list {
        list-style-type: none;
        font-weight: $regular;
      }

      &.note-list {
        list-style-type: none;
      }
    }

    .note {
      &-info {
        font-weight: $regular;
        display: block;
        padding: 12px;
        color: $color-pink-default;
        background: $color-pink-slight;
      }

      &-legend {
        margin: 12px 0;
        color: $color-blue-default;
        font-size: $type-default;

        small {
          display: block;
          text-transform: uppercase;
          font-size: $type-note;
          color: $color-blue;
        }
      }
    }
  }

  &-code {
    color: $color-brand;

    em {
      color: $color-gray-darker;
      font-style: normal;
    }

    .new {
      background: $color-green;
      font-size: $type-default;
      color: $color-white;
      font-weight: $regular;
      padding: 3px 6px;
      display: inline-flex;
      margin: 0 0 0 6px;
    }
  }

  &-deals {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    flex-direction: column;
    gap: 6px;
    margin: 6px 0 12px 0;

    .active {
      font-size: $type-default;
      border: 1px dashed $color-gray-half;
      padding: 6px 12px;
      color: $color-blue-default;
      text-align: center;
      font-weight: $regular;
      cursor: pointer;

      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;

      i {
        display: none;
      }

      @media (min-width: $break-md) {
        i {
          display: flex;
          color: $color-blue-default;
          font-size: $type-default;
          padding: 0 9px 0 0;
        }
      }

      // transform: scale(1);
      // transition: $transition-scale;

      // &:hover {
      //   transition: $transition-scale;
      //   transform: scale(1.1);
      // }

      i {
        color: $color-blue-default;
        font-size: $type-default;
        padding: 0 9px 0 0;
      }
    }
  }

  &-details {
    small {
      display: block;
      text-transform: uppercase;
      font-size: $type-note;
      color: $color-gray-darkest;
    }

    span {
      display: block;
      font-weight: $regular;
      font-size: $type-default;
    }

    &-btn {
      background-color: $color-blue-default;
      color: $color-white;
      border: none;
      padding: 3px 12px;
      margin: 12px 0;
      text-decoration: none;
      font-family: $font-default;
      display: flex;
    }

    &-contain {
      min-height: 100px;
      padding: 12px 12px 0 12px;

      @media (min-width: $break-xxs) {
        padding: 12px 12px 0 12px !important;
      }

      @media (min-width: $break-sm) {
        padding: 24px 24px 0 24px !important;
      }
    }

    &-main {
      font-size: $type-sm;
      margin: 0 0 12px 0;

      strong {
        font-size: $type-default;
        font-weight: $regular;
      }

      small {
        display: block;
        text-transform: uppercase;
        font-size: $type-note;
        color: $color-gray-darkest;
        font-weight: $regular;
      }

      span {
        display: flex;
        align-items: center;
        font-weight: $default;
        font-size: $type-h1;
        margin: 3px 0;
      }

      // ISO Cert
      b {
        display: inline-flex;
        background: transparent;
        border: 1px solid $color-brand-light;
        padding: 2px 12px;
        font-weight: $regular;
        font-size: $type-xxs;
        border-radius: $radius-round;
        color: $color-brand;
        margin: 6px 0;
      }

      .no-details {
        font-weight: $default;
      }
    }

    &-item {
      img {
        max-height: 60px;
        cursor: pointer;
        // transform: scale(1);
        // transition: $transition-scale;

        // &:hover {
        //   transition: $transition-scale;
        //   transform: scale(1.2);
        // }
      }

      margin: 0 0 12px 0;

      small {
        display: block;
        text-transform: uppercase;
        font-size: $type-note;
        color: $color-gray-darkest;
      }

      span {
        display: block;
        font-weight: $regular;
        font-size: $type-default;
      }

      b {
        display: block;
        font-weight: $regular;
        font-size: $type-default;
      }

      i {
        color: $color-gray;
      }

      &-group {
        @extend .product-details-item;

        display: grid;
        grid-template-columns: repeat(3, 72px) auto;
        gap: 6px;
      }
    }
  }

  &-schedule {
    small {
      font-size: $color-gray-darkest;
      margin: 3px 0;
      display: flex;
      font-size: $type-note;
      font-weight: $regular;
    }

    &-contain {
      margin: 12px 0;
      padding: 0 12px;

      @media (min-width: $break-xxs) {
        padding: 0 12px !important;
      }

      @media (min-width: $break-sm) {
        padding: 0 24px !important;
      }
    }

    &-header {
      display: grid;
      grid-template-columns: repeat(4, 20%);
      gap: 6px;
      padding: 6px;
      background: $color-gray-slight;
      border-top: 1px solid $color-gray-slight;
      border-bottom: 1px solid $color-gray-slight;
      text-align: center;
      justify-content: center;
      flex-direction: column;

      @media (min-width: $break-md) {
        gap: 24px;
      }
    }

    &-item {
      display: grid;
      grid-template-columns: repeat(4, 20%);
      gap: 6px;
      padding: 6px;
      border-bottom: 1px solid $color-gray-slight;
      text-align: center;
      justify-content: center;
      flex-direction: column;

      @media (min-width: $break-md) {
        gap: 24px;
      }
    }
  }

  &-contain {
    color: $color-gray-darkest;

    .status-empty {
      font-size: $type-default;
      margin-top: 12px;
      color: $color-red-default;
    }
  }

  &-quote {
    padding: 12px;
    color: $color-gray-darkest;
    font-size: $type-default;

    i {
      font-size: $type-default;
      margin: 0 1px;
      color: $color-mid;
    }
  }

  &-results {
    font-size: $type-default;
    color: $color-gray-darkest;

    &-contain {
      padding-top: 12px;
      padding-bottom: 12px;
      border-bottom: 1px solid rgba(18, 29, 45, 0.35);
    }
  }

  &-action {
    padding: 9px 12px;
    border-color: transparent;
    display: block;
    text-align: center;
  }

  @media (min-width: $break-md) {
    &:hover {
      .link-product {
        background: $color-mid-default;
        color: $color-white;
      }

      .link-product-details {
        visibility: visible;
      }

      b > span {
        font-size: $type-teeny;
        color: $color-blue;
        text-decoration: underline;
        font-weight: $regular;
        text-transform: uppercase;
        line-height: $type-sm;
        cursor: pointer;
        visibility: visible;
      }
    }
  }

  b {
    font-weight: $regular;
  }

  small {
    display: block;
    font-size: $type-note;
    color: $color-gray-darkest;
    text-transform: uppercase;
    padding: 3px 0;
    font-weight: $regular;
  }

  &-order {
    &-scroll {
      height: 108px;
      overflow-y: auto;
      margin: 6px 0 12px 0;
      background: $color-white-default;
      padding: 0;
    }

    i {
      color: $color-brand !important;
      font-size: 9px !important;
    }
  }

  &-analyte {
    font-size: $type-default;
    color: $color-gray-darkest;
    line-height: $type-md !important;

    &:after {
      content: ", ";
    }

    &:last-child {
      &:after {
        content: "";
      }
    }
  }

  &-fields-split {
    grid-column: 1 / -1;
    display: grid;
    grid-gap: 12px;
    grid-template-columns: repeat(2, auto);
  }

  &-grid {
    flex-direction: column;
    border: none;
    border-bottom: 1px solid $color-gray-half;
    align-items: baseline;
    font-size: $type-default;
    color: $color-gray-darkest;
    padding: 12px;
    grid-column: 1 / -1;
    grid-gap: 12px;
    display: grid;
    grid-template-columns: auto 50px;

    @media (min-width: $break-md) {
      grid-column: 1 / -1;
      grid-gap: 24px;
      display: grid;
      grid-template-columns: 150px 150px auto 80px 50px;
      padding: 12px;
    }

    @media (min-width: $break-lg) {
      grid-column: 1 / -1;
      grid-gap: 24px;
      display: grid;
      grid-template-columns: 150px 200px auto 80px 50px;
      padding: 12px;
    }

    @media (min-width: $break-xl) {
      grid-column: 1 / -1;
      display: grid;
      grid-gap: 24px;
      grid-template-columns: 200px 200px auto 80px 50px;
      padding: 12px;
    }

    &-selected {
      background: $color-gray-slight;
      border-bottom: 1px solid $color-gray-light;

      &:hover {
        background: $color-gray-slight;
      }
    }

    b {
      i {
        color: $color-brand-default;
        transition: $transition-color;
        font-size: $type-teeny;
        text-align: center;
        padding: 0 3px 0 6px;
        cursor: pointer;
      }

      span {
        font-size: $type-teeny;
        color: $color-blue;
        text-decoration: underline;
        font-weight: $regular;
        text-transform: uppercase;
        line-height: $type-sm;
        cursor: pointer;
        visibility: hidden;
      }
    }
  }
}

.analytes {
  b {
    font-size: $type-default;
    padding: 3px 0;
  }

  transition: $transition-all;

  &-block {
    background: $color-gray-slight;
    border-bottom: 1px solid $color-gray-half;
    border-top: 1px solid transparent;
    padding: 0 12px;

    @media (min-width: $break-md) {
      padding: 0 24px 12px 24px;
    }

    h3 {
      font-size: $type-default;
      font-weight: $default;
      color: $color-gray-darker;

      strong {
        font-weight: $regular;
        color: $color-gray-darkest;
      }
    }

    ul {
      padding: 12px;
      columns: 2;
      column-gap: 12px;
      background: $color-white;

      @media (min-width: $break-md) {
        columns: 3;
        column-gap: 24px;
      }

      li {
        border: none;
        background: transparent;
        color: $color-gray-darkest;
        font-size: $type-default;
        font-weight: $default;
      }
    }
  }
}
