@use "shared" as *;

.tooltip {
  background-color: $color-gray-darkest !important;
  max-width: 200px !important;
  padding: 6px 12px !important;
  line-height: $type-xxs !important;
  font-size: $type-tiny !important;
  text-align: center !important;
  border-radius: 2px !important;
  color: $color-white !important;
  font-weight: $regular !important;
  text-transform: none !important;
  font-family: inherit !important;
}

.tooltip-arrow {
  &::before {
    color: $color-gray-darkest !important;
  }
}
