@use "shared" as *;

.react-slideshow-container .nav {
  padding: 0 !important;
  background: transparent !important;
  &:hover {
    background: transparent !important;
  }
}

.indicators {
  margin: 24px !important;
}

.react-slideshow-container + div.indicators {
  margin-top: 20px;

  div.active {
    background: $color-gray !important;
  }

  div {
    margin-right: 4px !important;

    @media (min-width: $break-sm) {
      margin-right: 10px !important;
    }
  }
}
