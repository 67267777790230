@use "shared" as *;

.MuiInputBase-input,
.MuiInput-input {
  color: $color-gray-dark !important;
  font: $default $type-xxs $font-default !important;
  font-weight: $regular !important;
}

.MuiTooltip-tooltip {
  max-width: 60px !important;
}

.MuiInput-underline:after {
  border-bottom: 1px solid $color-gray-half !important;
}

.MuiInput-underline:before {
  border-bottom: 1px solid $color-gray-half !important;
}

.MuiFormLabel-root {
  color: $color-white !important;
  font-family: $font-default !important;
  justify-content: center !important;
}

.MuiFormControl-root {
  label {
    color: $color-gray-darkest !important;
    font-size: $type-xxs !important;
    text-transform: uppercase !important;
    font-weight: $regular !important;
  }
}

.MuiRadio-root {
  border-color: white !important;
  color: white !important;
}

.MuiFormControlLabel-label {
  font-size: $type-xxs !important;
  font-weight: $default !important;
  color: $color-gray-darkest !important;
}

.MuiFormControl-root {
  label {
    font-size: $type-default !important;
  }
}

.MuiInputLabel-root,
.MuiInputBase-root,
.MuiInput-input {
  color: $color-gray-darkest !important;
}

.MuiInputBase-input,
.MuiInput-input {
  color: $color-gray-darkest !important;
  font-size: $type-xxs !important;
}

.MuiBox-root,
.MuiTypography-root {
  color: $color-gray-darkest;
  font-size: $type-default;
}

.MuiDrawer-paper {
  width: 320px !important;
  background: $color-white !important;
  box-shadow: -4px 0 10px $color-gray-slight !important;
  z-index: $z-penultimate !important;
  text-transform: none;

  @media (min-width: $break-md) {
    width: 550px !important;
  }

  #close {
    font-size: $type-lg;
    color: $color-gray-dark;
    cursor: pointer;
    text-align: right;
    padding: 6px;
    position: fixed;
    right: 12px;
    left: 12px;
    top: 12px;

    &:hover {
      color: $color-gray-darkest;
    }
  }

  img {
    max-width: 100%;
  }

  blockquote {
    font-size: $type-default;
    font-style: italic;
    font-weight: $bold;
    margin: 0 12px;
  }

  code {
    font-family: $font-mono;
    font-size: $type-default;
    background: $color-gray-solid;
  }

  ul {
    margin: 0;
    padding: 0 12px;

    li {
      list-style: none;
      border: none;
      padding: 3px 0;
      font-size: $type-sm;
      font-weight: $default;
      text-transform: none;

      strong {
        font-weight: $regular;
        color: $color-gray-darkest;
      }
    }
  }

  ol {
    margin: 0;
    padding: 0 24px;

    li {
      border: none;
      padding: 3px 0;
      font-size: $type-sm;
      font-weight: $default;
      text-transform: none;

      strong {
        font-weight: $regular;
        color: $color-gray-darkest;
      }
    }
  }

  h1 {
    font-size: $type-h1;
    color: $color-gray-darkest;
    font-weight: $default;
    text-transform: none;
    line-height: $type-h1 + 6px;
    padding: 0;
    margin: 0;
  }

  h2 {
    padding: 6px 0;
    margin: 0;
    font-size: $type-h2;
    color: $color-gray-darkest;
    font-weight: $default;
    text-transform: none;
  }

  h3 {
    padding: 6px 0;
    margin: 0;
    font-size: $type-h3;
    color: $color-gray-darkest;
    font-weight: $default;
    text-transform: none;
  }

  h4 {
    padding: 6px 0;
    margin: 0;
    font-size: $type-h4;
    color: $color-gray-darkest;
    font-weight: $regular;
    text-transform: none;
  }

  h5 {
    padding: 3px 0;
    margin: 0;
    font-size: $type-default;
    color: $color-gray-darkest;
    font-weight: $regular;
    text-transform: none;
  }

  h6 {
    padding: 3px 0;
    margin: 0;
    font-size: $type-xxs;
    color: $color-gray-darkest;
    font-weight: $regular;
    text-transform: none;
  }

  p {
    color: $color-gray-darkest;
    font-size: $type-sm;
    font-weight: $default;
    text-align: left;
    text-transform: none;
    text-transform: none;

    strong {
      font-weight: $regular;
    }
  }
}

#location .MuiDrawer-paper {
  width: 320px !important;
  padding: 24px !important;
  background: $color-white !important;
  box-shadow: 12px 0 24px $color-gray-slight !important;
  color: $color-gray-darkest !important;
  display: block !important;
  z-index: $z-drawer !important;
  text-transform: none;

  @media (min-width: $break-xxs) {
    padding: 12px !important;
    width: 420px !important;
  }

  @media (min-width: $break-sm) {
    padding: 24px !important;
    width: 480px !important;
  }

  p {
    font-size: $type-copy;
    padding: 0;
    margin: 0;
    font-weight: $default;
  }

  img {
    width: 100%;
  }

  small {
    color: $color-gray-darkest;
    font-size: $type-xxs;

    span {
      color: $color-gray;
      padding: 0 6px;
    }
  }

  label {
    padding: 0;
    margin: 0;
    color: $color-gray-dark;
    font-weight: $regular;
    font-size: $type-note;
    display: block;
  }

  h1 {
    color: $color-gray-darkest;
    font-weight: $default;
    font-size: $type-h1;
    padding: 0;
    margin: 0;
  }

  h2 {
    background: $color-gray-note;
    border: 1px solid $color-gray-light;
    color: $color-gray-darkest;
    font-weight: $regular;
    font-size: $type-xxs;
    display: inline-flex !important;
    padding: 3px 6px;
    margin: 12px 0 12px 0;
  }
}

#notice .MuiDrawer-paper {
  width: 320px !important;
  padding: 24px !important;
  background: $color-white !important;
  box-shadow: 12px 0 24px $color-gray-slight !important;
  color: $color-gray-darkest !important;
  display: block !important;
  z-index: $z-drawer !important;
  text-transform: none;

  @media (min-width: $break-xxs) {
    padding: 12px !important;
    width: 420px !important;
  }

  @media (min-width: $break-sm) {
    padding: 24px !important;
    width: 480px !important;
  }

  p,
  li {
    font-size: $type-default;
    padding: 0;
    margin: 12px 0;
    font-weight: $default;
  }

  img {
    width: 100%;
  }

  small {
    color: $color-gray-darkest;
    font-size: $type-xxs;

    span {
      color: $color-gray;
      padding: 0 6px;
    }
  }

  label {
    padding: 0;
    margin: 0;
    color: $color-gray-dark;
    font-weight: $regular;
    font-size: $type-note;
    display: block;
  }

  h1 {
    color: $color-gray-darkest;
    font-weight: $default;
    font-size: $type-h2;
    padding: 0;
    margin: 0 0 12px 0;
  }

  h2 {
    background: $color-gray-note;
    border: 1px solid $color-gray-light;
    color: $color-gray-darkest;
    font-weight: $regular;
    font-size: $type-xxs;
    display: inline-flex !important;
    padding: 3px 6px;
    margin: 12px 0 12px 0;
  }
}

.MuiBackdrop-root {
  background-color: rgba(18, 29, 45, 0.24) !important;
  z-index: $z-backdrop !important;
}
