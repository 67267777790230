@use "shared" as *;

.worldmap__figure-container {
  background-color: transparent !important;
  z-index: $z-base !important;

  svg {
    rect {
      stroke: none !important;
    }
  }
}

.worldmap {
  display: none !important;
  position: relative;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: $z-base;

  @media (min-width: $break-lg) {
    justify-content: flex-end;
    align-items: center;
    display: flex !important;
  }
}

.partner {
  &-rollup {
    // position: absolute;
    // top: 36px;
    // right: 96px;
    // z-index: $z-default;

    button {
      display: flex;
      width: 144px;
      font-size: $type-default;
      font-weight: $regular;
      text-decoration: none;
      text-align: center;
      border: 1px solid $color-brand-light;
      color: $color-brand;
      background: $color-white-half;
      padding: 6px 12px;
      justify-content: center;
      cursor: pointer;
      border-radius: $radius-default;
      margin: 12px 0;
      float: right;

      i {
        text-decoration: none;
        margin: 0 6px 0 0;
      }

      &:hover {
        border: 1px solid $color-brand;
        color: $color-brand;
      }
    }

    &-group {
      grid-column: 1 / -1;
      grid-gap: 12px;
      display: grid;
      grid-template-columns: repeat(3, 32%);
      justify-content: space-around;

      div {
        background: $color-gray-slight;
        padding: 12px;

        color: $color-gray-darkest;
        text-align: center;
        justify-content: center;
        flex-direction: column;
        border-radius: $radius-module;
        border: 1px solid transparent;
        cursor: pointer;

        h1 {
          font-weight: $default;
          font-size: $type-h1;
          padding: 0;
          margin: 0;
        }
        small {
          font-weight: $regular;
          font-size: $type-teeny;
          padding: 0;
          margin: 0;
        }

        &:hover {
          border: 1px solid $color-brand;
        }
      }
    }
  }
}

.banner {
  display: flex !important;
  background: $color-brand-slight;
  background: linear-gradient(
    180deg,
    $color-white 0,
    $color-white 30%,
    $color-brand-light 100%
  );
  overflow: hidden;

  @media (min-width: $break-md) {
    grid-column: 1 / -1;
    display: grid;
    grid-gap: 0;
    grid-template-columns: 500px auto;
  }

  padding: 12px 0 0 0;

  &-form {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    color: $color-gray-darkest;
    width: 100%;
    z-index: $z-default !important;
    margin: 24px;

    @media (min-width: $break-md) {
      margin: 24px;
    }

    @media (min-width: $break-lg) {
      margin: 24px 0 96px 96px;
    }

    span {
      font-size: $type-default;
    }

    small {
      color: $color-gray-darkest;
      font-size: $type-default;
      padding: 0 0 6px 0;
    }

    h1 {
      color: $color-gray-darkest;
      font-weight: $default;
      font-size: $type-lg;
      padding: 0;
      margin: 0;
    }

    h2 {
      font-size: $type-lg;
      font-weight: $default;
      color: $color-gray-darker;
      padding: 0;
      margin: 0 0 12px 0;
    }

    h3 {
      color: $color-gray-darkest;
      font-weight: $default;
      font-size: $type-sm;
      padding: 0;
      margin: 12px 0;
      display: none;

      @media (min-width: $break-lg) {
        font-size: $type-sm;
        display: block;
      }

      @media (min-width: $break-xl) {
        font-size: $type-sm;
      }

      @media (min-width: $break-xxl) {
        font-size: $type-h3;
      }

      span {
        font-size: $type-h1;
        font-weight: $default;
        color: $color-gray-darkest;
        background: $color-gray-slight;
        padding: 24px;
        cursor: pointer;
        border-radius: $radius-default;
      }

      a {
        text-decoration: underline;
        color: $color-mid;
        font-weight: $regular;
        font-size: $type-default;
        cursor: pointer;
      }
    }

    .MuiFormControl-root,
    .MuiOutlinedInput-root,
    .MuiInputBase-root {
      width: 100%;
      padding: 0;
      margin: 0;
    }

    &-results {
      height: 300px;
      overflow-y: auto;
      overflow-x: hidden;

      // scrollbar-color: $color-gray-medium $color-white-note !important;
      // /* thumb and track color */
      // scrollbar-width: thin !important;

      li {
        padding: 9px 24px;
        color: $color-gray-darker;
        background: $color-white-medium;
        border-bottom: 1px solid $color-gray-light;
        font-size: $type-default;
        cursor: pointer;
        list-style: none;

        i {
          color: $color-blue-half;
          transition: $transition-scale;
          float: right;
        }

        &:hover {
          color: $color-gray-darkest;
          background: $color-white;

          i {
            color: $color-blue !important;
            transform: translateX(6px);
            transition: $transition-scale;
          }
        }

        &:first-child {
          border-top: 1px solid $color-gray-light;
        }
      }
    }

    .MuiInputBase-input,
    .MuiInput-input {
      color: $color-gray-darkest;
      font: $default $type-sm $font-default !important;
      background: $color-white;
      border-radius: $radius-default;
      padding: 6px;
      height: 24px;
    }

    &-partners {
      display: flex;
      flex-direction: column;
      width: 100%;

      @media (min-width: $break-xs) {
        grid-column: 1 / -1;
        display: grid;
        grid-gap: 6px;
        grid-template-columns: repeat(4, auto);
        flex-direction: row;
      }
    }

    &-search {
      display: flex;
      width: auto;

      input {
        border: 1px solid $color-gray !important;
        font-size: $type-default !important;
        padding: 9px 12px !important;
        border-radius: $radius-default !important;
        width: 100%;
        display: flex;
        box-sizing: border-box;
      }
    }
  }

  &-generic {
    background: $color-gray-darkest;
    background: linear-gradient(to right, $color-white 40%, $color-gray 80%);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 400px;
    display: flex;
    padding: 36px;

    &:hover {
      i {
        transform-origin: center;
        transform: scale(1.3);
        color: $color-blue-default;
        transform-origin: left center;
        transition: $transition-scale;
      }
    }

    @media (min-width: $break-md) {
      padding: 0 120px;
      height: 375px;
    }

    &-text {
      display: inline-flex;
      color: $color-white;
      justify-content: center;
      flex-direction: column;
      align-items: baseline;

      h1 {
        font-size: $type-subhero;
        color: $color-white;
        font-weight: $default;
        text-transform: uppercase;
        padding: 0;
        margin: 0;
      }

      h2 {
        padding: 6px 0;
        margin: 0;
        font-size: $type-tiny;
        color: $color-white;
        font-weight: $default;
        text-transform: none;
        border-radius: $radius-default;
      }

      p {
        color: $color-white;
        font-size: $type-md;
        line-height: $type-xl;
        font-weight: $default;
        text-align: left;

        @media (min-width: $break-lg) {
          text-align: justify;
        }
      }

      a {
        color: $color-blue-medium;
        font-weight: $regular;
        cursor: pointer;
        text-decoration: underline;
        font-size: $type-md;
        cursor: pointer;

        i {
          transform-origin: left center;
          font-size: $type-xl;
          color: $color-blue-medium;
          padding: 6px 0;
          transition: $transition-scale;
        }

        img {
          vertical-align: middle;
          padding: 0 3px;
          height: 14px;
        }

        &:hover {
          color: $color-blue;
        }
      }
    }
  }
}
