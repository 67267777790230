@use "shared" as *;

footer,
.footer {
  background: $color-gray-darkest;
  font-size: $type-default;
  color: $color-white;
  max-width: 100%;
  min-height: 600px;
  display: flex;
  margin: 0;
  z-index: $z-nav;
  font-weight: $regular;
  flex-direction: column;
  padding: 48px 24px;

  &-1 {
    order: 3;
    margin: 0 0 36px 0;
    @media (min-width: $break-sm) {
      order: 1;
      margin: 0;
    }
  }
  &-2 {
    order: 2;
    margin: 0 0 36px 0;
    @media (min-width: $break-sm) {
      order: 2;
      margin: 0;
    }
  }
  &-3 {
    order: 1;
    margin: 0 0 36px 0;
    @media (min-width: $break-sm) {
      order: 3;
      margin: 0 0 40px;
    }
  }

  h1 {
    font-size: $type-md;
    font-weight: $default;
    margin: 12px 0 24px 0;
    display: inline-flex;
  }

  @media (min-width: $break-md) {
    padding: 48px 24px;
  }

  @media (min-width: $break-lg) {
    padding: 48px 36px 48px 36px;
  }

  @media (min-width: $break-xl) {
    padding: 48px 96px 48px 96px;
  }

  @media (min-width: $break-huge) {
    padding: 48px 96px 48px 96px;
  }

  @media (min-width: $break-md) {
    grid-column: 1 / -1;
    grid-gap: 36px;
    display: grid;
    grid-template-columns: auto auto 45%;
    align-items: flex-start;
    flex-direction: column;
  }

  @media (min-width: $break-lg) {
    grid-template-columns: auto auto 40%;
  }

  @media (min-width: $break-xl) {
    grid-template-columns: auto auto 30%;
  }

  .note {
    font-size: $type-xxs;
    color: $color-gray-dark;
  }

  .version {
    position: absolute;
    bottom: 12px;
    right: 12px;
    font-size: $type-note;
    color: $color-gray;
    text-transform: uppercase;
    padding: 0;

    strong {
      color: $color-mid-default;
      font-weight: $bold;
    }

    span {
      color: $color-gray-half;
      margin: 0 6px;
    }
  }

  &-input,
  input {
    background: $color-white;
    border: 1px solid $color-gray;
    border-radius: $radius-slight;
    color: $color-gray-darkest !important;
    padding: 6px 9px !important;
    margin: 0 !important;
    width: 240px;

    @media (min-width: $break-xxs) {
      width: 300px;
    }

    @media (min-width: $break-xl) {
      width: 100%;
      box-sizing: border-box;
    }

    &:after {
      border: 1px solid $color-gray;
      background: $color-white;
    }
  }
}
