@use "shared" as *;

.react-tabs__tab {
  padding: 0 24px 12px 5px;
  color: $color-gray-medium;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.react-tabs__tab--selected {
  color: $color-gray-darkest;
}

.MuiStepLabel-label.MuiStepLabel-active,
.MuiStepLabel-label.MuiStepLabel-completed,
.MuiStepLabel-label,
.MuiButton-label {
  font-family: $font-default !important;
  font-size: $type-xs !important;
  font-weight: $default !important;
}

.MuiButton-containedPrimary {
  background-color: $color-brand-medium !important;
  box-shadow: none !important;
  padding: 2px 4px !important;

  &:hover {
    background-color: $color-brand !important;
  }
}

.MuiButton-root {
  padding: 2px 4px !important;
}

.MuiStepIcon-root,
.MuiStepIcon-root.MuiStepIcon {
  .MuiStepIcon-text {
    fill: $color-brand !important;
    font-size: $type-xxs !important;
    font-weight: $default !important;
  }
  color: $color-brand-light !important;
}
.MuiStepIcon-root.MuiStepIcon-active {
  color: $color-brand-light !important;
}

.MuiStepIcon-text {
  fill: $color-brand-half !important;
  font-size: $type-xxs !important;
}

.MuiStepIcon-completed {
  d {
    fill: $color-white !important;
  }
}

.MuiPaper-root {
  background-color: $color-white !important;
}

.MuiButtonBase-root.Mui-disabled {
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 2px 4px !important;

  &:hover {
    background-color: $color-gray-slight !important;
  }
}

.MuiSvgIcon-root {
  font-size: $type-xl !important;
}

// REACT TABS
.react-tabs {
  -webkit-tap-highlight-color: transparent;

  &__tab-list {
    border-bottom: 1px solid $color-gray !important;
    margin: 0 0 10px;
    padding: 0;
  }

  &__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: 1px solid $color-gray !important;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 0 36px 12px 0 !important;
    cursor: pointer;

    &--selected {
      background: #fff;
      border-color: #aaa;
      color: $color-gray-darkest;
      border-radius: 5px 5px 0 0;
      border-bottom: 1px solid $color-gray !important;
    }

    &--disabled {
      color: $color-gray-light;
      cursor: default;
    }

    &:focus {
      box-shadow: 0 0 5px hsl(208, 99%, 50%);
      border-color: hsl(208, 99%, 50%);
      outline: none;

      &:after {
        content: "";
        position: absolute;
        height: 5px;
        left: -4px;
        right: -4px;
        bottom: -5px;
        background: #fff;
      }
    }
  }

  &__tab-panel {
    display: none;

    &--selected {
      display: block;
    }
  }
}
