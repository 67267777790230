@use "shared" as *;

.flyout {
  position: absolute;
  right: 235px;
  top: 55px;
  display: inline;
  z-index: $z-ultimate;
  cursor: pointer;

  &-hidden {
    display: none;
  }

  div {
    padding: 6px 3px;
    position: absolute;
    min-width: 200px;
    max-width: 200px;
    width: 200px;
    color: $color-white;
    background: $color-green;
    text-align: center;
    visibility: hidden;
    font-size: $type-xxs;
    font-weight: $default;
    visibility: visible;
  }
}
