@use "shared" as *;

video {
  width: 100%;
  height: 480px;
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0.84;

  @media (min-width: $break-md) {
    height: 505px;
  }
}

.landing {
  &-qacademy {
    position: absolute;
    text-align: right;
    background: linear-gradient(
      90deg,
      transparent 0%,
      $color-white-slight 24%,
      $color-white-slight 72%,
      transparent 100%
    );
    padding: 48px 48px;
    right: 108px;
    top: 168px;
    width: 360px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    // box-shadow: 2px 2px 72px $color-brand-slight;
    overflow: hidden;
    text-decoration: none !important;

    @media (max-width: $break-lg) {
      display: none;
    }

    small {
      display: inline-flex !important;
      // width: 5px !important;
      // border-right: 2px solid $color-brand-half;
      background: $color-white-light;
      color: $color-gray-darkest;
      align-self: flex-end;
      font-size: $type-teeny;
      justify-content: flex-end;
      font-weight: $regular;
      padding: 3px 12px;
      margin: 0 0 0 0;
    }

    b {
      display: inline-flex !important;
      // width: 5px !important;
      // border-right: 2px solid $color-brand-half;
      background: $color-pink-medium;
      color: $color-white;
      align-self: flex-end;
      font-size: $type-default;
      justify-content: flex-end;
      font-weight: $default;
      letter-spacing: 1px;
      padding: 3px 12px;
      margin: 0 0 0 0;
    }

    p {
      font-size: $type-sm !important;
      width: 100% !important;
      color: $color-gray-darkest;
      font-weight: $regular !important;

      strong {
        font-weight: $bold;
      }
    }

    h2 {
      text-align: right;
      justify-content: flex-end;
      font-size: $type-h2 !important;
      font-weight: $regular !important;
      color: $color-gray-darkest !important;
      padding: 6px 0 !important;
    }

    a,
    button {
      color: $color-blue;
      text-decoration: underline;
      font-weight: $regular !important;
      font-size: $type-xs;
      text-align: right;
      background-color: transparent;
      border: none;
      padding: 0;
      margin: 0;
      cursor: pointer;
    }
  }

  background: $color-brand-medium;
  background: linear-gradient(
    135deg,
    $color-brand-hint 0%,
    $color-brand-slight 36%,
    $color-brand-light 60%,
    $color-brand-half 100%
  );

  background-size: cover;
  background-repeat: no-repeat;
  background-position: 15% center;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 480px;

  &-wide {
    display: flex;
    flex: 1;
    flex-direction: column;
    // background-image: url("/resources/assets/1wa-icon.svg");
    // background-repeat: no-repeat;
    // background-size: 72%;
    // background-position: right center;
    // width: 100%;
    // height: 100%;
    display: flex;
    justify-content: center;
    padding: 24px;

    @media (min-width: $break-md) {
      padding: 24px 96px;
    }

    @media (min-width: $break-huge) {
      padding: 24px 96px 0 96px;
    }

    p {
      padding: 0;
      font-size: $type-copy;
      margin: 12px 0 24px 0;
      font-weight: $default;
      width: 60%;
    }

    h1 {
      font-size: $type-xxl;
      font-weight: $default;
      color: $color-gray-darker;
      padding: 0;
      margin: 0;
    }

    h2 {
      font-size: $type-lg;
      font-weight: $default;
      color: $color-gray-darker;
      padding: 0;
      margin: 0;
      display: inline-flex;
      align-items: center;

      strong {
        text-align: center;
        // background: $color-gray-darkest;
        padding: 0 12px 0 0;
        margin: 0 12px 0 0;
        font-weight: $regular;
        border-right: 2px solid $color-brand-half;
        color: $color-gray-darkest;
        // background: -webkit-linear-gradient(45deg, $color-mid, $color-brand 80%);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
      }

      span {
        font-size: $type-lg;
        font-weight: $default;
        color: $color-gray-darker;
        padding: 0;
        margin: 0;
        display: none;

        @media (min-width: $break-md) {
          display: flex;
        }
      }
    }

    h3 {
      color: $color-gray-darkest;
      font-weight: $default;
      font-size: $type-copy;
      padding: 0;
      margin: 24px 0;
      width: 100%;

      @media (min-width: $break-md) {
        width: 60%;
      }
    }

    h4 {
      color: $color-gray-darkest;
      font-weight: $default;
      font-size: $type-copy;
      padding: 0;
      margin: 0;
    }

    input {
      background: $color-white;
      border: 1px solid $color-gray;
      border-radius: $radius-default;
      color: $color-gray-darkest !important;
      padding: 9px !important;
      margin: 0 !important;
      font-size: $type-default !important;
      width: 255px;

      @media (min-width: $break-md) {
        font-size: $type-xs !important;
        width: 560px;
        min-width: 560px;
        padding: 12px !important;
      }

      &:after {
        border: 1px solid $color-gray;
        background: $color-white;
      }
    }
  }

  &-left {
    padding: 108px 24px 0 24px;
    display: flex;
    flex: 1;
    flex-direction: column;

    @media (min-width: $break-sm) {
      background-image: none;
      align-self: center;
      padding: 72px 0 0 24px;
    }

    @media (min-width: $break-lg) {
      padding: 72px 0 0 36px;
    }

    @media (min-width: $break-xl) {
      padding: 72px 0 0 96px;
    }

    @media (min-width: $break-huge) {
      padding: 72px 0 0 96px;
    }

    h1 {
      font-size: $type-xxl;
      font-weight: $default;
      color: $color-gray-darker;
      padding: 0;
      margin: 0;
    }

    h2 {
      font-size: $type-lg;
      font-weight: $default;
      color: $color-gray-darker;
      padding: 0;
      margin: 0;

      strong {
        background: $color-gray-darkest;
        color: $color-white;
        padding: 6px 12px;
        margin: 0 6px 0 0;
        font-weight: $default;
      }
    }

    h3 {
      color: $color-gray-darkest;
      font-weight: $default;
      font-size: $type-h3;
      padding: 0;
      margin: 12px 0;

      span {
        font-size: $type-h3;
        font-weight: $regular;
        color: $color-mid;
      }
    }

    h4 {
      color: $color-gray-darkest;
      font-weight: $default;
      font-size: $type-copy;
      padding: 0;
      margin: 12px 0;

      span {
        font-size: $type-h3;
        font-weight: $regular;
        color: $color-mid;
      }
    }

    input {
      background: $color-white;
      border: 1px solid $color-gray;
      border-radius: $radius-default;
      color: $color-gray-darkest !important;
      padding: 9px !important;
      margin: 0 !important;
      font-size: $type-default !important;
      width: 360px;

      @media (min-width: $break-md) {
        font-size: $type-xs !important;
        width: 600px !important;
        padding: 12px !important;
      }

      &:after {
        border: 1px solid $color-gray;
        background: $color-white;
      }
    }
  }

  &-right {
    background-image: url("/resources/assets/1wa-icon.svg");
    background-repeat: no-repeat;
    display: none;
    background-size: cover;
    width: 100%;
    height: 100%;
    background-position: 100px center;
    padding: 0 !important;

    @media (min-width: $break-sm) {
      display: flex;
    }
  }

  &-summary {
    max-width: 100%;
    margin: 24px 0 0 0;
    grid-column: 1 / -1;
    display: grid;
    grid-gap: 6px;
    flex-direction: column;
    grid-template-columns: repeat(2, 1fr);

    @media (min-width: $break-md) {
      max-width: 600px;
      display: flex;
      flex-direction: row;
      margin: 36px 0 0 0;
    }

    &-element {
      font-size: $type-note;
      color: $color-gray-dark;
      text-transform: uppercase;
      margin-right: 36px;

      @media (min-width: $break-md) {
        margin-right: 24px;
        font-size: $type-teeny;
      }

      &-box {
        background: $color-white;
        padding: 3px 12px;
        color: $color-gray-darkest;
        font-size: $type-xxs;
        margin: 6px 0;
        max-width: 60px;
        font-weight: $default;
        border: 1px solid $color-white;
        cursor: pointer;
        display: table;
        text-decoration: none;
        text-align: center;

        @media (min-width: $break-md) {
          font-size: $type-sm;
          max-width: 36px;
        }

        &:hover {
          color: $color-gray-darkest;
          border: 1px solid $color-blue-half;
        }
      }
    }
  }

  &-accent {
    position: absolute;
    left: 400px;
    top: 110px;
    z-index: $z-penultimate;
    color: $color-mid;
    font-size: $type-default;
    text-transform: uppercase;
    text-align: center;
    transform: rotate(9deg);

    a {
      all: unset;
      display: block;
      text-decoration: none;
      text-align: center;
      text-transform: uppercase;
      font-size: $type-default;
      border: 2px solid $color-mid-light;
      padding: 6px 24px;
      background: transparent;
      cursor: pointer;
      color: $color-mid;
      transition: $transition-border;

      b {
        font-size: $type-lg;
        font-weight: $regular;
        color: $color-mid;
      }

      &:hover {
        border: 2px solid $color-mid;
        transition: $transition-border;
        color: $color-mid;
      }
    }
  }
}

.legacy {
  // margin-top: 100px;

  &-text {
    height: 220px;

    @media (min-width: $break-sm) {
      height: 150px;
    }
  }

  &-title {
    font-size: $type-subhero;
    font-weight: $default;
    color: $color-white;
    text-transform: uppercase;
    padding: 125px 50px 8px 50px;
    line-height: $type-xxl;

    @media (min-width: $break-sm) {
      padding: 155px 50px 8px 50px;
      line-height: $type-xxl;
    }

    &-dark {
      @extend .legacy-title;
      color: $color-gray-darker;
      padding: 125px 50px 8px 50px;

      @media (min-width: $break-sm) {
        padding: 155px 50px 8px 50px;
      }
    }
  }

  &-sub {
    font-size: $type-h2;
    color: $color-white;
    font-weight: $default;
    line-height: $type-xl;
    text-align: left;
    padding: 0 50px;
    text-shadow: 1px 1px 0 $color-gray-darkest;

    @media (min-width: $break-sm) {
      font-weight: $default;
      text-align: justify;
      text-shadow: none;
    }

    &-dark {
      @extend .legacy-sub;
      color: $color-gray-medium;
      font-weight: $default;
    }
  }

  &-h1 {
    font-size: $type-hero;
    font-weight: $default;
    color: $color-white;
    text-transform: uppercase;
    padding: 125px 50px 8px 50px;
    line-height: $type-xxl;

    @media (min-width: $break-sm) {
      padding: 155px 50px 8px 50px;
      line-height: $type-xxl;
    }

    &-dark {
      @extend .legacy-title;
      color: $color-gray-darker;
      padding: 125px 50px 8px 50px;

      @media (min-width: $break-sm) {
        padding: 155px 50px 8px 50px;
      }
    }

    &-warning {
      @extend .legacy-h1;
      background: $color-pink;
      color: $color-white;
    }

    &-covid {
      @extend .legacy-h1;
      color: $color-pink;
    }
  }

  &-h2 {
    font-size: $type-md;
    color: $color-white;
    line-height: $type-xl;
    text-align: left;
    padding: 0 50px;
    text-shadow: 1px 1px 0 $color-gray-darkest;
    font-weight: $default;

    &-covid {
      @extend .legacy-h2;
      color: $color-gray-darker;
      text-shadow: none;
    }

    @media (min-width: $break-sm) {
      text-align: justify;
      text-shadow: none;
    }

    &-dark {
      @extend .legacy-sub;
      color: $color-gray-medium;
    }
  }
}
