@use "shared" as *;

.img-shadow {
  box-shadow: 12px 12px 36px $color-gray-slight;
  border: 1px solid $color-gray-light;
}

.fade-in {
  opacity: 0;
  animation: fadeIn;
  animation-duration: 0.3s;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: inherit;

  &-step-1 {
    @extend .fade-in;
    animation-delay: 0.3s;
  }

  &-step-2 {
    @extend .fade-in;
    animation-delay: 0.5s;
  }

  &-step-3 {
    @extend .fade-in;
    animation-delay: 0.75s;
  }

  &-step-4 {
    @extend .fade-in;
    animation-delay: 1s;
  }

  &-1000 {
    @extend .fade-in;
    animation-delay: 1s;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeAndScale {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fade-out {
  opacity: 0;
  -webkit-animation: fadeOut ease-in 0.3s;
  -moz-animation: fadeOut ease-in 0.3s;
  animation: fadeOut ease-in 0.3s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes zoomIn {
  from {
    background-size: 135% auto;
  }

  to {
    background-size: 115% auto;
  }
}
.slide-1 {
  animation-name: slide;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  margin-right: -1000px;
  animation-delay: 0.3s;
}

.slide-2 {
  animation-name: slide2;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  margin-right: -1000px;
  animation-delay: 0.5s;
}

@keyframes slide {
  0% {
    margin-right: -1000px;
  }

  100% {
    margin-right: -50px;
  }
}

@keyframes slide2 {
  0% {
    margin-right: -1000px;
  }

  100% {
    margin-right: 0;
  }
}

.pulse {
  animation-name: pulse;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-play-state: inherit;
}

.pulse-turbo {
  animation-name: pulse;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-play-state: inherit;
}

.pulse-fast {
  animation-name: pulse;
  animation-duration: 0.1s;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: 3;
  animation-play-state: inherit;
}

@keyframes pulse {
  0% {
    opacity: 0.24;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.24;
  }
}

.pop {
  animation-name: pop;
  animation-duration: 0.4s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-direction: normal;
}

@keyframes pop {
  0% {
    outline: 2px solid $color-gray-slight;
    transform: scale(1);
  }

  50% {
    outline: 2px solid $color-mid-default;
    color: $color-mid;
    transform: scale(1.2);
  }

  100% {
    outline: 2px solid $color-gray-slight;
    transform: scale(1);
  }
}

.pop-in {
  animation-name: pop2;
  animation-duration: 0.4s;
  animation-timing-function: linear;
}

@keyframes pop2 {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}

.point {
  animation: point 0.3s linear;
  animation-iteration-count: 1;
  position: fixed;
  top: 0;
  right: 24px;
  padding: 0 4px;
  font-size: $type-sm;
  line-height: $type-sm;
  z-index: $z-ultimate !important;
  color: $color-white;
  text-align: center;
  background: $color-orange;
  pointer-events: none !important;
  content: "+";
}

@keyframes point {
  0% {
  }

  100% {
    right: 140px;
    top: 0;
  }
}

.load,
.load:after {
  border-radius: 50%;
  width: 21px;
  height: 21px;
}

.loader {
  margin: 60px 0 0 0;
  font-size: $type-default;
  font-weight: $default;
  color: $color-gray-darkest;
  animation: pulse 0.5s linear;
}

.load {
  margin: 0 auto;
  position: relative;
  border-top: 1px solid $color-brand-slight;
  border-right: 1px solid $color-brand-slight;
  border-bottom: 1px solid $color-brand-slight;
  border-left: 1px solid $color-brand;
  animation: loader 1s infinite linear;

  &-contain {
    font-size: $type-default;
    display: inline-flex;
    margin: 24px 0;

    span {
      padding: 0 6px;
    }
  }

  &-items {
    @extend .load;
    border-top: 1px solid $color-blue-slight;
    border-right: 1px solid $color-blue-slight;
    border-bottom: 1px solid $color-blue-slight;
    border-left: 1px solid $color-blue;
    margin: 24px 0;
  }
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.point-down {
  animation-name: pointDown;
  animation-duration: 3s;
  animation-iteration-count: 5;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-delay: 6s;
  opacity: 0;
  padding: 0 48px;

  i {
    font-size: $type-xl;
    color: $color-white;
  }
}

@keyframes pointDown {
  0% {
    opacity: 0;
    transform: translate(0, 0);
  }

  30% {
    opacity: 1;
    transform: translate(0, 10px);
  }

  60% {
    opacity: 0;
    transform: translate(0, 0);
  }

  100% {
    opacity: 0;
    transform: translate(0, 0);
  }
}

.drop-down {
  animation-name: dropDown;
  animation-duration: 0.2s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  visibility: "visible";
  transition: "all 0.3s";
}

@keyframes dropDown {
  from {
    transform: translateY(-60px);
  }

  to {
    transform: translateY(0);
  }
}

.drop-up {
  animation-name: dropUp;
  animation-duration: 0.1s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

@keyframes dropUp {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-60px);
    display: none;
    height: 0;
  }
}

.delay-200ms {
  animation: delay;
  animation-duration: 0.2s;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: inherit;
  animation-delay: 0.2s;
  opacity: 0;
}

.delay-300ms {
  animation: delay;
  animation-duration: 0.2s;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: inherit;
  animation-delay: 0.3s;
  opacity: 0;
}

.delay-400 {
  animation: delay;
  animation-duration: 0.2s;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: inherit;
  animation-delay: 0.4s;
  opacity: 0;
}

.delay-400 {
  animation: delay;
  animation-duration: 0.2s;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: inherit;
  animation-delay: 0.4s;
  opacity: 0;
}

.delay-500 {
  animation: delay;
  animation-duration: 0.2s;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: inherit;
  animation-delay: 0.5s;
  opacity: 0;
}

.delay-750 {
  animation: delay;
  animation-duration: 0.2s;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: inherit;
  animation-delay: 0.75s;
  opacity: 0;
}

.delay-1000 {
  animation: delay;
  animation-duration: 0.2s;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: inherit;
  animation-delay: 1s;
  opacity: 0;
}

.pulse-fast-delay {
  animation-name: pulseDelay;
  animation-duration: 0.1s;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: 3;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: inherit;
  animation-delay: 0.6s;
  opacity: 0;
}

@keyframes pulseDelay {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  75% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes delay {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

// for new cart items
.slide-fade {
  animation: slideFade;
  animation-iteration-count: 1;
  animation-duration: 0.4s;
  animation-timing-function: ease;
  transform: translateY(-48px);
}

// for new cart items
.border-fade {
  animation: borderFade;
  animation-iteration-count: 1;
  animation-duration: 0.2s;
  animation-timing-function: linear;
  // border: 1px solid transparent;
  transform: translateX(0);
  transform-origin: left center;
}

@keyframes borderFade {
  0% {
    transform: translateX(-48px);
    opacity: 0;
    // border: 1px solid transparent;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
    // border: 1px solid transparent;
  }
}

@keyframes slideFade {
  0% {
    transform: translateY(-48px);
  }

  100% {
    transform: translateY(0);
  }
}
