@use "shared" as *;

.expand {
  details {
    margin-bottom: 24px;
    display: block;
  }

  summary {
    &::marker {
      color: $color-blue-light;
    }

    .pseudolink {
      color: $color-blue !important;
      text-decoration: underline !important;
      font-weight: $bold !important;
      display: block;
    }

    cursor: pointer;
    text-decoration: none !important;
    font-size: $type-xs;
    margin: 0 0 12px 0;
    outline: none; /* Remove default outline */

    strong {
      font-weight: $bold;
    }
  }

  summary:hover {
    text-decoration: underline; /* Add underline on hover for better UX */
  }

  img {
    height: 100px;
    padding: 6px 12px 0 0;
    float: left; /* Align image to the left */
    margin-right: 10px; /* Space between image and text */
  }

  p {
    clear: both; /* Clear floats */
  }

  &-main {
    @extend .expand;
    font-size: $type-copy !important;
    margin: 24px 0;

    details {
      font-size: $type-copy !important;
    }

    summary {
      font-size: $type-copy !important;
    }

    span {
      font-weight: $regular;
      font-size: $type-copy !important;
    }

    p {
      font-size: $type-copy !important;
    }
  }
}
