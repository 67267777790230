@use "shared" as *;

.notify {
  position: absolute;
  top: 465px;
  right: 12px;

  &-icon {
    text-align: center;
  }

  &-divider {
    border-bottom: 3px solid $color-gray-light;
    margin: 24px 0;
  }

  h1 {
    font-size: $type-default;
    font-weight: $regular;
    color: $color-gray-darkest;
    padding: 0;
    margin: 0;
    line-height: inherit;
  }

  &-compact {
    display: inline-flex;
    color: $color-gray-darkest;
    font-size: $type-default;
    z-index: $z-nav;

    @media (min-width: $break-sm) {
      display: none;
    }
  }

  &-urgent {
    display: inline-flex;
    color: $color-gray-dark;
    font-size: $type-sm;
    font-weight: $regular;
    padding: 0 6px 3px 0;
    margin: 0 0 12px 0;
    text-decoration: none;
    align-items: center;
    color: $color-pink-default;

    i {
      padding: 0 6px 0 0;
      color: $color-pink-default;
    }
  }

  &-announcement {
    color: $color-mid-default;

    @extend .notify-urgent;

    i {
      padding: 0 6px 0 0;
      color: $color-mid-default;
    }
  }

  &-news {
    color: $color-blue-default;
    @extend .notify-urgent;

    i {
      padding: 0 6px 0 0;
      color: $color-blue-default;
    }
  }

  &-shoutout {
    color: $color-brand-default;

    @extend .notify-urgent;

    i {
      padding: 0 6px 0 0;
      color: $color-brand-default;
    }
  }
}
