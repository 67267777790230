@use "shared" as *;

.testimonials {
  padding: 24px;
  color: $color-gray-darkest;
  font-weight: $default;
  font-size: $type-copy;
  background: linear-gradient(180deg, $color-gray-slight 0%, $color-white 80%);
}

.story {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  z-index: $z-base;

  padding: 24px;

  @media (min-width: $break-md) {
    padding: 36px 96px 0 96px;
  }

  @media (min-width: $break-huge) {
    padding: 36px 96px 0 96px;
  }

  @media (min-width: $break-xl) {
    justify-content: space-evenly;
    flex-direction: row;
    justify-content: center;
    grid-column: 1 / -1;
    display: grid;
    grid-gap: 60px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  &-box {
    color: $color-gray-darkest;
    text-decoration: none;
    margin: 0 0 24px 0;

    &-text {
      font-size: $type-copy;

      i {
        font-size: $type-default;
        color: $color-blue;
        transition: $transition-scale;
        transform: scale(1);
      }

      h1 {
        font-size: $type-h1;
        font-family: $font-default;
        color: $color-gray-darkest;
        font-weight: $default;
        padding: 0;
        margin: 0;
      }

      h2 {
        font-size: $type-h2;
        font-family: $font-default;
        color: $color-gray-darkest;
        font-weight: $default;
        padding: 0;
        margin: 12px 0;
      }

      b {
        font-size: $type-tiny;
        color: $color-blue;
        background: $color-brand-slight;
        padding: 6px 12px;
        font-weight: $regular;
        margin: 0;
        display: inline-flex;
        text-transform: uppercase;
      }

      p {
        font-size: $type-default;
        line-height: $type-md;
        color: $color-gray-darkest;
        font-weight: $default;
        padding: 0;
        margin: 12px 0;
      }

      li {
        list-style: circle;
        color: $color-gray-darkest;
        font-weight: $default;
        margin: 0 0 3px 0;
        font-size: $type-default;
      }
    }
  }

  &-line {
    padding: 36px 96px;
    display: flex;
    flex-direction: column;

    padding: 24px;

    @media (min-width: $break-md) {
      grid-column: 1 / -1;
      display: grid;
      grid-gap: 60px;
      grid-template-columns: auto 36%;
      flex-direction: column;

      padding: 36px 96px 0 96px;
    }

    @media (min-width: $break-huge) {
      padding: 36px 96px 0 96px;
    }

    &-item {
      @extend .story-box;

      &-text {
        @extend .story-box-text;
        margin: 0 0 36px 0;
      }
    }
  }
}
