$font-default: "Avenir Next", "Avenir", "Nunito Sans", "Helvetica Neue",
  Helvetica, Arial, sans-serif, -apple-system;
$font-awesome: "Font Awesome 5 Free", "FontAwesome", "Material Icons";
$font-mono: "Roboto Mono", monospace;

$ultra: 200;
$light: 300;
$default: 400;
$regular: 475;
$bold: 600;
$extra-bold: 650;

$radius-round: 100px;
$radius-default: 3px;
$radius-module: 6px;
$radius-hard: 12px;
$radius-slight: 2px;

$type-h1: 23px;
$type-story: 24px;
$type-h1-m: 21px;
$type-h2: 19px;
$type-h2-m: 23px;
$type-h3: 17px;
$type-h3-m: 17px;
$type-h4: 13px;
$type-h4-m: 15px;
$type-massive: 100px;
$type-huge: 90px;
$type-big: 36px;
$type-metric: 35px;
$type-hero: 27px;
$type-subhero: 28px;
$type-xxl: 32px;
$type-dashboard: 21px;
$type-xl: 28px;
$type-lg: 21px;
$type-icon: 17px;
$type-md: 16px;
$type-copy: 15px;
$type-copy-height: 25px;
$type-sm: 14px;
$type-xs: 13px;
$type-default: 12px;
$type-line-item: 13px;
$type-xxs: 11px;
$type-tiny: 10px;
$type-teeny: 9px;
$type-note: 8px;
$type-mono: 7px;

$transition-opacity: opacity 0.2s linear;
$transition-outline: outline 0.2s linear;
$transition-background: background-color 0.2s ease-in;
$transition-color: color 0.2s linear;
$transition-scale: transform 0.3s ease-in-out;
$transition-filter: filter 0.4s ease-in;
$transition-boxshadow: box-shadow 0.2s ease-in;
$transition-shift: margin-left 0.2s linear;
$transition-font: font-size 0.2s linear;
$transition-border: border 0.2s linear;
$transition-indent: margin-left 0.2s ease-in;
$transition-padding: padding 0.2s ease-in;

$transition-all: all 0.2s ease-in;

// $z-ultimate: 24000;
// $z-penultimate: 12000;
// $z-menu: 6000;
// $z-slider: 2400;
// $z-nav: 1200;
// $z-footer: 960;
// $z-default: 480;
// $z-title: 360;
// $z-base: 0;

$z-ultimate: 20000;
$z-penultimate: 10000;
$z-drawer: 9500;
$z-backdrop: 9000;
$z-menu: 7000;
$z-nav: 1200;
$z-slider: 2000;
$z-footer: 1000;
$z-default: 400;
$z-title: 300;
$z-base: 0;

// OASYS BREAKPOINTS
// $break-xxs: 480px;
// $break-xs: 520px;
// $break-sm: 640px;
// $break-md: 768px;
// $break-lg: 1024px;
// $break-xl: 1280px;
// $break-xxl: 1536px;

// CORP SITE BREAKPOINTS
$break-xxs: 480px;
$break-xs: 720px;
$break-sm: 840px;
$break-md: 960px;
$break-mid: 1160px;
$break-lg: 1280px;
$break-default: 1350px;
$break-xl: 1440px;
$break-xxl: 1600px;
$break-xxxl: 1670px;
$break-huge: 1770px;
$break-xhuge: 1800px;
$break-xxhuge: 1920px;
