@use "shared" as *;

.filter {
  display: flex;
  padding: 12px 6px 24px 6px;

  &-notification {
    background: $color-mid-slight;
    color: $color-mid;
    padding: 12px;
    grid-column: 1 / -1;
    display: grid;
    grid-gap: 12px;
    grid-template-columns: 24px auto;
    margin: 0 0 12px 0;
    font-size: $type-default;

    @media (min-width: $break-md) {
      padding: 12px;
      font-size: $type-xs;
    }

    i {
      font-size: $type-icon;
      align-self: center;
      color: $color-mid-default;
    }

    #plus {
      font-size: $type-default;
      padding: 0 3px;
      align-self: center;
      color: $color-mid-default;
    }
  }

  &-item.left {
    h1 {
      padding: 24px 0;
      background: $color-white;
      font-weight: $regular;
      font-size: $type-h1;
    }
  }

  &-item.right {
    h1 {
      padding: 24px 0;
      background: $color-white;
      font-weight: $regular;
      font-size: $type-h1;
    }
  }

  &-group {
    margin: 0;
    padding: 24px 0 0 0;
    box-sizing: content-box;
    flex: 1 auto;
    top: 58px;
    left: 0;
    right: 0;
    position: -webkit-sticky;
    position: sticky;
    height: auto;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: $color-white;

    .MuiFormControl-root {
      min-width: 100%;
      box-sizing: content-box;
    }

    .MuiInput-underline:before,
    .MuiInput-underline:after {
      border-bottom: 1px solid transparent;
    }

    input {
      background: $color-white;
      border: 1px solid $color-gray;
      border-radius: $radius-default;
      color: $color-gray-darkest !important;
      // padding: 12px !important;
      margin: 0 !important;
      font-size: $type-default !important;
      width: 100%;

      &:after {
        border: 1px solid $color-gray;
        background: $color-white;
      }
    }
  }

  b {
    margin-left: 6px;
    font-weight: $regular;
  }

  &-list {
    padding-top: 40px;
  }

  &-rollups {
    display: inline-flex;
    align-self: flex-end;
    justify-content: space-evenly !important;
    align-items: center;
    padding: 0 48px;
    margin: 0;
  }
}
