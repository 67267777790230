@use "shared" as *;

.article {
  padding: 60px 24px;
  color: $color-gray-darkest;

  @media (min-width: $break-md) {
    padding: 0 24px;
  }

  @media (min-width: $break-lg) {
    padding: 0 36px;
  }

  @media (min-width: $break-xl) {
    padding: 0 96px;
  }

  @media (min-width: $break-huge) {
    padding: 0 96px;
  }

  &-slash {
    color: $color-gray-half !important;
    font-weight: $bold;
  }

  &-slide {
    margin: 0 72px;

    img {
      display: flex;
      width: 100%;
    }

    .nav {
      padding: 0;
      margin: 0;
    }
  }

  h2 {
    color: $color-gray-darkest;
    font-size: $type-h2;
    font-weight: $default;
    text-transform: none;
    text-align: left;
  }

  h3 {
    color: $color-gray-darkest;
    font-size: $type-h3;
    font-weight: $default;
    text-transform: none;
    text-align: left;
    margin: 6px 0 24px 0;

    a {
      font-size: $type-h3;
      color: $color-blue;
    }
  }

  h4 {
    color: $color-gray-darkest;
    font-size: $type-sm;
    font-weight: $default;
    text-transform: none;
    margin: 0;
    padding: 0;
  }

  &-video {
    padding: 0;
    margin: 0;
    align-items: baseline;

    iframe {
      align-items: baseline;
      width: 320px;
      height: 180px;
      padding: 0;
      margin: 12px 0;

      @media (min-width: $break-lg) {
        width: 400px;
        height: 225px;
      }
    }

    p {
      b {
        font-weight: $regular;
      }
    }

    &-container {
      padding: 24px 12px;
      margin-bottom: 12px;
      font-weight: $regular;
      font-size: $type-xs;
      width: 100%;
      background: $color-gray-note;

      b {
        font-weight: $bold;
      }
    }

    &-list {
      display: block;
      justify-content: flex-start;

      iframe {
        width: 320px;
        height: 180px;
        padding: 12px;
      }

      @media (min-width: $break-lg) {
        display: flex;
        justify-content: space-evenly;

        iframe {
          width: 267px;
          height: 150px;
          padding: 12px;
        }
      }
    }
  }

  &-grid {
    list-style: none;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    padding: 12px 6px;
    text-align: left;

    @media (min-width: $break-lg) {
      grid-column: 1 / -1;
      grid-gap: 36px;
      display: grid;
      grid-template-columns: 200px auto;
      align-items: flex-start;
      flex-direction: column;
    }

    @media (min-width: $break-lg) {
      grid-column: 1 / -1;
      grid-gap: 72px;
      display: grid;
      grid-template-columns: 400px auto;
      align-items: flex-start;
      flex-direction: column;
    }
  }

  &-text {
    // Main
    color: $color-gray-darker;
    justify-content: center;
    flex-direction: column;
    align-items: baseline;
    font-size: $type-copy;
    font-weight: $default;

    @media (min-width: $break-lg) {
      font-size: $type-copy;
      display: inline-flex;
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        list-style: none;
        border: none;
        padding: 6px 0;
        font-size: $type-copy;
        font-weight: $default;

        strong {
          font-weight: $regular;
          color: $color-gray-darkest;
        }
      }
    }

    h1 {
      font-size: $type-subhero;
      color: $color-gray-darkest;
      font-weight: $default;
      text-transform: none;
      line-height: $type-subhero + 6px;
      padding: 0;
      margin: 0;
    }

    h2 {
      padding: 6px 0;
      margin: 0 0 12px 0;
      font-size: $type-h2;
      color: $color-gray-darkest;
      font-weight: $default;
    }

    h3 {
      padding: 6px 0;
      margin: 0 0 12px 0;
      font-size: $type-h3;
      color: $color-gray-darkest;
      font-weight: $default;
    }

    h4 {
      padding: 6px 0;
      margin: 12px 0;
      font-size: $type-md;
      color: $color-gray-darkest;
      font-weight: $default;
    }

    h5 {
      font-size: $type-md;
      text-transform: none;
      color: $color-gray-darker;
      font-weight: $default;
      margin: 0;
      padding: 0;
    }

    h6 {
      font-weight: $default;
      font-size: $type-md;
      color: $color-gray-darker;
      font-style: oblique;
      text-align: left;
      margin: 24px;
      text-transform: $default;
      line-height: $type-copy-height;

      @media (min-width: $break-lg) {
        font-size: $type-copy;
      }
    }

    p {
      color: $color-gray-darkest;
      font-size: $type-copy;
      line-height: $type-copy-height;
      font-weight: $default;
      margin: 12px 0 6px 0;
      text-align: left;

      @media (min-width: $break-lg) {
      }

      span {
        color: $color-gray-darkest;
      }

      strong {
        font-weight: $bold;
      }

      b {
        font-weight: $regular;
        color: $color-gray-darkest;
      }

      @media (min-width: $break-lg) {
        font-size: $type-copy;
      }
    }

    // a {
    //   color: $color-blue-default;
    //   font-weight: $regular;
    //   cursor: pointer;
    //   text-decoration: none;
    //   font-size: inherit;

    //   img {
    //     vertical-align: middle;
    //     padding: 0 3px;
    //     height: 14px;
    //   }

    //   &:hover {
    //     color: $color-blue;
    //   }
    // }

    &-grid {
      background: transparent;
      margin: 48px 0 0 0;

      grid-column: 1 / -1;
      grid-gap: 12px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      flex-direction: column;

      @media (min-width: $break-lg) {
        background: $color-gray-module;
        grid-template-columns: auto auto;
      }

      &-left {
        align-self: center;
        padding: 0;
        order: 2;

        h2 {
          font-size: $type-lg;
          text-transform: none;
          color: $color-blue;
          font-weight: $default;
          line-height: $type-xl + 6px;
          margin: 0;
          padding: 0;
        }

        h5 {
          font-size: $type-md;
          text-transform: none;
          color: $color-gray-darker;
          font-weight: $default;
          margin: 0;
          padding: 0;
        }

        @media (min-width: $break-lg) {
          order: 1;
          padding: 0 48px 24px 96px;
        }
      }

      &-right {
        padding: 0;
        order: 1;
        justify-content: center;
        display: flex;

        img {
          max-height: 400px;
        }

        @media (min-width: $break-lg) {
          order: 2;
          padding: 24px 0;
          align-self: center;

          img {
            max-height: 500px;
          }
        }
      }
    }

    &-wide {
      margin: 0 auto;

      @extend .article-text;
    }

    &-story {
      @extend .article-text;
      display: flex;
      width: 100%;

      blockquote {
        margin: 12px 0;
        font-style: italic;

        p {
          font-size: $type-default !important;
          font-weight: $regular;

          strong {
            font-size: $type-md !important;
            display: block;
            font-weight: $bold;
          }
        }
      }

      @media (min-width: $break-md) {
        grid-column: 1 / -1;
        grid-gap: 24px;
        display: grid;
        grid-template-columns: 55% repeat(1, 1fr);
        align-items: flex-start;
      }

      @media (min-width: $break-lg) {
        grid-column: 1 / -1;
        grid-gap: 24px;
        display: grid;
        grid-template-columns: 65% repeat(1, 1fr);
        align-items: flex-start;
      }

      @media (min-width: $break-xl) {
        grid-column: 1 / -1;
        grid-gap: 24px;
        display: grid;
        grid-template-columns: 65% repeat(1, 1fr);
        align-items: flex-start;
      }

      &-solo {
        @extend .article-text-story;

        display: flex;
        width: auto;
        padding: 0;

        @media (min-width: $break-md) {
          padding: 0 96px;
        }
      }

      &-main {
        padding: 0;
        margin: 0;

        blockquote {
          background: $color-gray-slight;
          font-style: normal;
          border-radius: $radius-slight;
          padding: 24px;
          margin: 24px 0;

          img {
            float: none;
            padding: 0;
            height: 168px;

            @media (min-width: $break-md) {
              height: auto;
              max-width: 120px;
              float: left;
              padding: 0 12px 12px 0;
            }
          }

          p {
            font-size: $type-copy !important;
            font-weight: $default !important;
          }
          a {
            color: $color-blue;
            font-size: $type-sm;
            font-weight: $bold;
          }

          b {
            color: $color-gray-darker;
            font-size: $type-md;
            font-weight: $regular;
          }

          span {
            color: $color-gray-darker;
            font-size: $type-sm;
            font-weight: $default;
          }

          h1 {
            color: $color-gray-darkest;
            font-weight: $regular;
            font-size: $type-lg;
            margin: 0;
            padding: 12px 0 0 0;

            @media (min-width: $break-md) {
              padding: 0;
            }
          }

          h2 {
            font-size: $type-h2;
            font-weight: $regular;
            color: $color-gray-darkest;
            padding: 12px 0 6px 0;
            margin: 0;
            strong {
              font-weight: $bold;
            }
          }

          h3 {
            font-size: $type-h3;
            font-weight: $regular;
            color: $color-gray-darkest;
            padding: 0 0 6px 0;
            margin: 0;
            strong {
              font-weight: $bold;
            }
          }

          h4 {
            font-size: $type-copy;
            font-weight: $regular;
            color: $color-blue;
            padding: 24px 0 6px 0;
            margin: 0;
            strong {
              font-weight: $bold;
            }
          }

          h5 {
            font-size: $type-copy;
            font-weight: $regular;
            color: $color-blue;
            padding: 0;
            margin: 0;
            strong {
              font-weight: $bold;
            }
          }
        }

        iframe {
          width: 30% !important;
          margin: 0 12px 0 0;
        }

        img {
          max-width: 100%;
          max-height: auto;
        }

        @media (min-width: $break-md) {
          padding: 24px 0 0 0;

          img {
            max-width: 60%;
          }
        }
        @media (min-width: $break-xl) {
          padding: 24px 0 0 0;
        }

        @media (min-width: $break-huge) {
          padding: 24px 0 0 0;
        }

        ul {
          li {
            list-style: circle;
            margin: 6px 24px;
            padding: 6px 12px;
            font-size: $type-copy;
            font-weight: $default;
            strong {
              font-weight: $regular;
            }
          }
        }

        ol {
          margin: 0;
          padding: 0 24px;

          li {
            border: none;
            padding: 3px 0;
            font-size: $type-copy;
            font-weight: $default;
            text-transform: none;

            strong {
              font-weight: $regular;
            }
          }
        }

        h1 {
          color: $color-gray-darkest;
          font-weight: $regular;
          font-size: $type-lg;
          margin: 0;
          padding: 12px 0 0 0;

          @media (min-width: $break-md) {
            padding: 0;
          }
        }

        h2 {
          font-size: $type-h2;
          font-weight: $regular;
          color: $color-gray-darkest;
          padding: 12px 0 6px 0;
          margin: 0;
          strong {
            font-weight: $bold;
          }
        }

        h3 {
          font-size: $type-h3;
          font-weight: $regular;
          color: $color-gray-darkest;
          padding: 24px 0 6px 0;
          margin: 0;
          strong {
            font-weight: $bold;
          }
        }

        h4 {
          font-size: $type-copy;
          font-weight: $regular;
          color: $color-blue;
          padding: 24px 0 6px 0;
          margin: 0;
          strong {
            font-weight: $bold;
          }
        }

        h5 {
          font-size: $type-copy;
          font-weight: $regular;
          color: $color-blue;
          padding: 0;
          margin: 0;
          strong {
            font-weight: $bold;
          }
        }

        b {
          font-size: $type-tiny;
          font-weight: $bold;
          background: $color-gray-darkest;
          padding: 6px 12px;
          margin: 0 0 12px 0;
          display: inline-flex;
          color: $color-white;
          text-transform: uppercase;
        }

        em {
          font-size: inherit;
          font-weight: $regular;
          color: $color-blue;
          font-style: normal;
        }

        strong {
          font-weight: $bold;
          color: $color-gray-darker;
        }

        p {
          color: $color-gray-darkest;
          font-weight: $default;

          strong {
            font-weight: $bold;
            color: $color-gray-darker;
          }
        }
      }

      .pull-quote {
        left: 0;
        float: left;
        margin: 12px 24px 12px 0;
        position: relative;
        text-align: left;
        width: 240px;
        font-size: $type-lg;
        line-height: $type-xl;
        border-top: 6px solid $color-brand-default;
        padding: 12px;

        &-right {
          @extend .pull-quote;
          right: 0;
          left: auto;
          text-align: right;
          float: right;
          margin: 12px 0 12px 24px;
        }
      }

      &-sidebar {
        // story
        border-radius: $radius-default;
        // position: relative;
        width: 100%;

        @media (min-width: $break-md) {
          top: 100px;
          position: -webkit-sticky;
          position: sticky;
          margin: 48px 0 24px 0;
        }

        &-html {
          background: $color-white !important;

          @extend .article-text-story-sidebar-default;
          padding: 0 !important;

          iframe {
            width: 100%;
            height: 281px;
          }
        }

        &-default {
          background: $color-gray-slight;
          padding: 24px;
          display: flex;
          justify-content: center;
          flex-direction: column;

          // position: relative;

          .Typewriter {
            display: flex;
          }

          blockquote {
            font-size: $type-default;
            font-style: italic;
            font-weight: $bold;
            margin: 0 12px;
          }

          code {
            font-family: $font-mono;
            font-size: $type-default;
            background: $color-white-medium;
          }

          ul {
            margin: 0;
            padding: 0;

            li {
              list-style: none;
              border: none;
              padding: 3px 0;
              font-size: $type-sm;
              font-weight: $default;
              text-transform: none;
              display: flex;
              align-items: center;
              align-content: center;

              strong {
                font-weight: $regular;
                color: $color-gray-darkest;
              }

              img {
                align-self: center;
                height: 50px;
                margin: 0 12px 0 0;
                border: 1px solid $color-gray;
              }
            }
          }

          ol {
            margin: 0;
            padding: 0 24px;

            li {
              border: none;
              padding: 3px 0;
              font-size: $type-default;
              font-weight: $default;
              text-transform: none;

              strong {
                font-weight: $regular;
                color: $color-gray-darkest;
              }
            }
          }

          h1 {
            font-size: $type-h1;
            color: $color-gray-darkest;
            font-weight: $default;
            text-transform: uppercase;
            line-height: $type-h1 + 6px;
            padding: 0;
            margin: 0;

            strong {
              font-weight: $bold;
            }
          }

          h2 {
            padding: 6px 0;
            margin: 0;
            font-size: $type-h2;
            color: $color-gray-darkest;
            font-weight: $default;
            text-transform: none;
            strong {
              font-weight: $bold;
            }
          }

          h3 {
            padding: 6px 0;
            margin: 0;
            font-size: $type-h3;
            color: $color-blue;
            font-weight: $regular;
            text-transform: none;
            strong {
              font-weight: $bold;
            }
          }

          h4 {
            padding: 6px 0;
            margin: 0;
            font-size: $type-h4;
            color: $color-gray-darkest;
            font-weight: $regular;
            text-transform: none;
            strong {
              font-weight: $bold;
            }
          }

          h5 {
            padding: 3px 0;
            margin: 0;
            font-size: $type-default;
            color: $color-gray-darkest;
            font-weight: $regular;
            text-transform: none;
            strong {
              font-weight: $bold;
            }
          }

          h6 {
            padding: 3px 0;
            margin: 0;
            font-size: $type-xxs;
            color: $color-gray-darkest;
            font-weight: $regular;
            text-transform: none;
            strong {
              font-weight: $bold;
            }
          }

          p {
            color: $color-gray-darkest;
            font-size: $type-xs;
            font-weight: $default;
            text-align: left;
            text-transform: none;
            padding: 6px 0;
            margin: 0;

            img {
              max-width: 144px;
            }

            strong {
              font-weight: $regular;
            }
          }
          small {
            font-weight: $regular;
            font-size: $type-xxs;
            text-transform: uppercase;
          }

          img {
            max-width: 100%;
          }

          a {
            font-weight: $regular;
          }
        }

        &-alert {
          @extend .article-text-story-sidebar-default;
          padding: 24px;
          background: $color-pink-slight;
          font-weight: $regular;
          color: $color-pink;
          font-size: $type-xs;
          line-height: normal;
          display: block;

          strong {
            font-weight: $bold;
            text-transform: uppercase;
          }
        }

        &-earth {
          background: $color-gray-slight;
          position: relative;

          .Typewriter {
            display: flex;
          }

          h1 {
            font-size: $type-h1;
            color: $color-gray-darkest;
            font-weight: $default;
            font-family: $font-default;
            padding: 0;
            line-height: inherit;
            margin: 0;
          }

          small {
            font-weight: $regular;
            font-size: $type-xxs;
          }
        }
      }
    }
  }

  &-image {
    display: flex;
    color: $color-white;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 90px;

    img {
      height: 370px;
      display: none;

      @media (min-width: $break-lg) {
        display: flex;
      }
    }
  }

  &-block {
    background: $color-pink-medium;
    color: $color-white;
    font-size: $type-md;
    font-weight: $regular;
    padding: 3px 12px;
    width: auto;
    display: inline-flex;
    align-items: center;
  }

  &-cred {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid $color-gray-light;
    padding: 24px 6px;
    text-decoration: none;
    transition: $transition-background;

    font-weight: $default;
    font-size: $type-xs;
  }

  &-file {
    grid-column: 1 / -1;
    grid-gap: 12px;
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid $color-gray-light;
    padding: 12px 6px;
    text-decoration: none;
    transition: $transition-background;

    i {
      color: $color-brand-medium;
      font-size: $type-xl;
      text-align: center;
    }

    &:hover {
      background: $color-gray-note;
      transition: $transition-background;

      i {
        color: $color-brand;
      }
    }

    b {
      text-decoration: underline;
      color: $color-brand;
      font-size: $type-xs;
      font-weight: $regular;
    }

    span {
      color: $color-gray;
      font-size: $type-default;
    }
  }

  &-avatar {
    grid-column: 1 / -1;
    grid-gap: 24px;
    display: flex;
    grid-template-columns: 90px auto;
    align-items: center;
    justify-content: flex-start;
    padding: 12px 0 12px 0;

    img {
      width: 100px;
      height: auto;
      border-radius: $radius-round;
      filter: grayscale(0.3);
      border: 2px solid $color-brand-slight;
      margin: 12px;
    }

    &-empty {
      height: 90px;
      width: 90px;
      border-radius: $radius-round;
      background-color: $color-gray-solid;
      border: 2px solid $color-brand-slight;
    }

    a {
      color: $color-blue;
      font-size: $type-sm;
    }

    b {
      color: $color-gray-darker;
      font-size: $type-md;
      font-weight: $regular;
    }

    span {
      color: $color-gray-darker;
      font-size: $type-sm;
      font-weight: $default;
    }
  }

  &-section {
    &-label {
      color: $color-gray;
      font-size: $type-tiny;
      padding: 3px 0;
      border-bottom: 1px solid $color-gray-light;
    }

    &-profile {
      color: $color-gray-darker;
      font-size: $type-copy;

      h3 {
        color: $color-blue-default;
        font-size: $type-sm;
        padding: 3px 0 3px 0;
      }

      p {
        color: $color-gray-darker;
        font-size: $type-xs;
        line-height: $type-copy-height;
        padding: 3px 0;
        text-align: left;
        font-style: oblique;

        strong {
          font-weight: $regular;
        }
      }

      i {
        font-size: $type-sm;
        color: $color-blue-default;
        cursor: pointer;
      }
    }
  }
}

#clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

#logo-article {
  width: 100%;
  height: auto;

  @media (min-width: $break-lg) {
    width: auto;
    max-height: 50px;
  }
}

#webinar-list {
  @media (min-width: $break-sm) {
    display: inline-flex;
    width: 47%;
    margin: 0 12px 24px 12px;
  }

  @media (min-width: $break-xl) {
    display: inline-flex;
    width: 31%;
    margin: 0 6px 12px 6px;
    min-height: 300px;
  }
}
