@use "shared" as *;

.console {
  padding: 24px;
  max-width: 80px;

  &-item {
    grid-column: 1 / -1;
    grid-gap: 16px;
    display: grid;
    grid-template-columns: 60px auto;
    font-weight: $default;
    color: $color-gray-darkest;
    margin: 0;
  }

  &-columns-2 {
    grid-column: 1 / -1;
    grid-gap: 6px;
    display: grid;
    grid-template-columns: 50px 50px;
    font-weight: $default;
    color: $color-gray-darkest;
    align-items: flex-start;
    margin: 0;
    padding: 0;
  }

  &-columns {
    grid-column: 1 / -1;
    grid-gap: 16px;
    display: grid;
    grid-template-columns: 60px repeat(auto-fit, minmax(100px, 4fr));
    font-weight: $default;
    color: $color-gray-darkest;
    align-items: flex-start;
    margin: 0;
    padding: 24px;
  }

  &-nasa {
    grid-column: 1 / -1;
    grid-gap: 16px;
    display: grid;
    grid-template-columns: 60px auto 60px;
    font-weight: $default;
    color: $color-gray-darkest;
    align-items: flex-start;
    margin: 0;
    padding: 24px;
    // position: absolute;
    // top: 0;

    &-icon-loader {
      width: 60px;
      height: 3px;
      background: $color-gray;
    }
  }

  &-icon {
    display: flex;
    width: 60px;
    height: 60px;
    border-radius: $radius-slight;
    background: $color-brand-note;
    font-size: $type-sm;
    color: $color-gray-darkest;
    vertical-align: middle;
    justify-content: center;
    text-align: center;
    align-items: center;
    align-self: flex-start;

    // this allows for counter position inside icon box
    position: relative;

    span {
      font-weight: $default;
      color: $color-gray-darkest;
      font-family: $font-mono;
      line-height: inherit;
      padding: 0;
      font-size: $type-note;
      position: absolute;
      bottom: 2px;
      right: 2px;
      left: auto;
      animation: fadeIn 0.2s linear;
    }

    i {
      color: $color-gray-darkest;
      font-size: $type-lg;
    }

    div {
      align-self: flex-end;
    }

    &-health,
    &-status {
      @extend .console-icon;

      i {
        color: $color-brand;
        font-size: $type-h2;
      }

      .error {
        color: $color-red-default;
        font-size: $type-h2;
      }

      .stable {
        color: $color-brand;
        font-size: $type-h2;
      }
    }

    &-nasa {
      display: flex;
      width: 60px;
      height: 60px;
      border-radius: $radius-slight;
      background: $color-brand-note;
      font-size: $type-sm;
      color: $color-gray-darkest;
      vertical-align: middle;
      justify-content: center;
      text-align: center;
      align-items: center;
      align-self: center;

      &-inverted {
        @extend .console-icon-nasa;
        background: transparent;
        animation: pulse 1s linear;
        animation-iteration-count: 5;
      }

      // this allows for counter position inside icon box
      position: relative;

      i {
        color: $color-brand;
        font-size: $type-h2;
      }

      .error {
        color: $color-red-default;
        font-size: $type-h2;
      }

      .stable {
        color: $color-brand;
        font-size: $type-h2;
      }

      span {
        font-weight: $default;
        color: $color-gray-darkest;
        font-family: $font-mono;
        line-height: inherit;
        padding: 0;
        font-size: $type-note;
        position: absolute;
        bottom: 4px;
        right: 4px;
        left: auto;
        animation: fadeIn 0.2s linear;

        &:after {
          content: "%";
        }
      }

      b {
        font-weight: $default;
        color: $color-gray-darkest;
        font-family: $font-mono;
        line-height: inherit;
        padding: 0;
        justify-content: center;
        font-size: $type-md;
        animation: fadeIn 0.2s linear;

        &:after {
          content: "%";
        }
      }
    }
  }

  &-status {
    font-size: $type-default;
    color: $color-gray-darkest;
  }

  &-graph {
    width: 60px;
    padding: 0;
    display: inline-flex;
    justify-content: space-between;
    margin: 0;
    align-items: center;

    i {
      font-size: $type-note;
      color: $color-gray-darkest;
      line-height: $type-default;
      align-self: center;
      vertical-align: middle;
    }
  }

  &-spark {
    // position: absolute;
    // top: 48px;
    // right: 24px;
    // width: 100%;
    padding: 0 36px 0 0;
    align-self: center;
  }

  &-note {
    position: absolute;
    top: 12px;
    right: 12px;

    i {
      transition: $transition-scale;
      color: $color-gray-half;
      font-size: $type-default;
      cursor: pointer;

      &:hover {
        transform: scale(1.5);
        transform-origin: center;
        transition: $transition-scale;
        color: $color-gray-darkest;
      }
    }
  }

  &-time {
    text-align: right;
  }

  &-data {
    text-transform: uppercase;
    line-height: normal;

    label {
      padding: 0;
      margin: 0;
      color: $color-gray-dark;
      font-weight: $regular;
      font-size: $type-note;
      display: block;
    }

    b {
      padding: 0;
      margin: 0;
      color: $color-gray-dark;
      font-weight: $regular;
      font-size: $type-tiny;
      display: block;
    }

    small {
      text-transform: uppercase;
      font-weight: $regular;
      padding: 0;
      margin: 0;
      font-size: $type-xs;
      line-height: $type-default;
    }

    // b {
    //   text-transform: uppercase;
    //   font-weight: $regular;
    //   padding: 0;
    //   margin: 0;
    //   font-size: $type-tiny;
    //   line-height: 6px !important;
    // }

    h1 {
      color: $color-gray-darkest;
      margin: 0;
      padding: 6px 0 0 0;
      font-size: $type-h1;

      small {
        text-transform: uppercase;
        font-weight: $regular;
        padding: 0;
        margin: 0;
        font-size: $type-default;
        line-height: $type-default;
      }

      i {
        color: $color-gray-darkest;
        font-size: $type-sm;
        vertical-align: middle;
        align-self: center;
        padding: 0 0 0 6px;
      }

      sup {
        font-size: $type-default;
        padding: 0;
      }
    }
  }

  &-status {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.clock {
  font-weight: $default;
  color: $color-blue-default;
  font-size: $type-lg;
  padding: 0;
  margin: 0 0 12px 0;

  span {
    font-size: $type-md;
    color: $color-blue-medium;
    padding: 0 3px;
    text-transform: uppercase;
  }
}
