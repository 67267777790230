@use "shared" as *;

.shoppingcart {
  max-height: 228px;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  box-shadow: 0 0 24px inset $color-gray-slight;

  @media (min-width: $break-xl) {
    max-height: 288px;
  }

  &-items {
    font-size: $type-default;
    text-decoration: none;
    padding: 12px 24px;
    grid-column: 1 / -1;
    display: grid;
    gap: 24px;
    flex-direction: column;
    grid-template-columns: 144px auto 72px;
    // grid-template-columns: 96px auto 72px 12px;
    font-weight: $regular;
    border-bottom: 1px solid $color-gray-light;
    margin: 0;
    align-items: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:first-child {
      border-top: 1px solid $color-gray-light;
    }

    @media (min-width: $break-lg) {
      // grid-template-columns: 120px auto 72px 12px;
    }

    @media (min-width: $break-xl) {
      grid-template-columns: 144px auto 72px;
      // grid-template-columns: 120px auto 72px 12px;
    }

    @media (min-width: $break-xxl) {
      grid-template-columns: 144px auto 96px;
      // grid-template-columns: 132px auto 96px 12px;
    }

    &-moodle {
      padding: 12px 0;

      @extend .shoppingcart-items;

      grid-template-columns: 96px auto 120px;

      @media (min-width: $break-lg) {
      }

      @media (min-width: $break-xl) {
        grid-template-columns: 96px auto 120px;
      }

      @media (min-width: $break-xxl) {
        grid-template-columns: 96px auto 120px;
      }
    }

    label {
      display: block;
      color: $color-gray-dark;
      font-size: $type-tiny;
    }

    span {
      color: $color-gray-darkest;
      line-height: $type-default;

      small {
        font-size: $type-tiny;
        text-transform: none;
        margin: 0;
        display: flex;
      }

      em {
        font-style: normal;
        color: $color-gray-darkest;
        text-transform: uppercase;
        font-weight: $regular;
      }

      b {
        color: $color-brand;
        font-weight: $regular;
        text-transform: uppercase;
      }
    }

    #quantity {
      display: inline-flex;
      align-items: center;
      justify-content: center;

      i {
        vertical-align: middle;
        align-self: center;
        float: right;
        color: $color-blue;
        font-size: $type-default;
        padding: 12px;
        transition: $transition-scale;
        cursor: pointer;
        font-weight: $bold;

        &:hover {
          cursor: pointer;
          color: $color-blue;
          transform: scale(1.5);
          transition: $transition-scale;
        }
      }

      span {
        outline: 2px solid $color-gray-slight;
        background: $color-gray-slight;
        color: $color-gray-darkest;
        padding: 3px;
        min-width: 24px;
        font-weight: $regular;
        text-transform: uppercase;
        align-items: center;
        justify-content: center;
        text-align: center;
        border-radius: $radius-slight;
        font-size: $type-default;
      }
    }

    .remove {
      vertical-align: middle;
      align-self: center;
      float: right;
      color: $color-gray-half;
      font-size: $type-sm;
      padding: 6px;
      cursor: pointer;

      &:hover {
        transform: scale(1.2) !important;
        color: $color-pink;
      }
    }

    &:hover {
      i {
        color: $color-pink-default;
      }

      &:hover {
        #quantity i {
          transform: scale(1.2) !important;
          color: $color-blue;
        }
      }
    }
  }
}

.indicator {
  &-discount {
    font-size: $type-default;
    position: relative;
    background: $color-green-default;
    right: 36px;
    // left: 100px;
    top: -2px;
    color: $color-white;
    display: inline-flex;
    text-align: center;
    justify-content: center;
    padding: 1px 12px;

    &:after {
      left: 100%;
      top: 50%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: transparent;
      border-left-color: $color-green-default;
      border-width: 9px;
      margin-top: -9px;
    }

    &-big {
      @extend .indicator-discount;
      background: $color-green;

      &:after {
        border-left-color: $color-green;
      }
    }
  }
}

.sum {
  background: $color-white;
  padding: 0;
  margin: 0;

  b {
    font-weight: $regular;
    font-size: $type-xxs;
    display: block;
    line-height: $type-sm;
    color: $color-gray-dark;

    strong {
      font-weight: $bold;
    }
  }

  small {
    color: $color-green;
    font-size: $type-sm !important;
    font-weight: $regular;
    padding: 3px;

    span {
      color: $color-gray-darkest;
      font-size: $type-xxs;
    }
  }

  i {
    color: $color-green;
    transform: rotateY(180deg);
    font-size: $type-sm;
    vertical-align: baseline;
    margin: 0 6px 0 0;
    align-self: center;
  }

  h1 {
    font-size: $type-h1;
    color: $color-gray-darkest;
    font-weight: $default;
    justify-content: flex-end;
    text-align: right;
    padding: 12px 12px;
    margin: 0;

    span {
      font-weight: $default;
      color: $color-gray;
    }
  }

  h2 {
    font-size: $type-h2;
    color: $color-gray-darkest;
    font-weight: $default;
    justify-content: flex-end;
    text-align: right;
    padding: 6px 12px;
    margin: 0;
  }

  h3 {
    font-size: $type-h3;
    color: $color-green;
    font-weight: $default;
    text-align: right;
    justify-content: flex-end;
    margin: 0;
    padding: 6px 12px;
    background: $color-green-note;
    border-top: 1px dashed $color-green-half;
    border-bottom: 1px dashed $color-green-half;
  }
}

.storefront {
  align-items: flex-start;
  flex-direction: column;
  padding: 0 12px;
  margin: 24px 0 0 0;

  h4 {
    font-size: $type-h3;
    font-weight: $regular;
    margin: 0;
    padding: 0 0 6px 0;
  }

  @media (min-width: $break-md) {
    grid-column: 1/-1;
    gap: 12px;
    display: grid;
    padding: 0 24px;
    grid-template-columns: 65% auto;
    margin: 24px 0 0 0;
  }

  @media (min-width: $break-xl) {
    grid-column: 1/-1;
    display: grid;
    gap: 36px;
    padding: 0 96px;
    grid-template-columns: 65% auto;
    margin: 24px 0 0 0;
  }

  @media (min-width: $break-huge) {
    grid-column: 1/-1;
    display: grid;
    gap: 36px;
    padding: 0 96px;
    grid-template-columns: 65% auto;
    margin: 24px 0 0 0;
  }

  &-header {
    align-items: flex-start;
    flex-direction: column;
    padding: 84px 24px 0 24px;

    @media (min-width: $break-md) {
      padding: 24px 24px 0;
      margin: 0;
    }

    @media (min-width: $break-xl) {
      padding: 24px 96px 0 96px;
    }

    p {
      font-size: $type-default;
      font-weight: $default;

      a {
        text-decoration: underline;
        color: $color-brand;
        font-weight: $regular;
        font-size: $type-default;
        cursor: pointer;
      }
    }

    h1 {
      color: $color-gray-darkest;
      font-weight: $default;
      font-size: $type-lg;
      text-transform: uppercase;
      margin: 0;
      padding: 12px 0 0 0;

      @media (min-width: $break-md) {
        padding: 0;
      }
    }

    h2 {
      color: $color-gray-darkest;
      font-size: $type-h3;
      padding: 0;
      font-weight: $default;
      text-transform: uppercase;
      margin: 40px 0 0 0;
    }

    h3 {
      color: $color-gray-darkest;
      font-weight: $default;
      font-size: $type-sm;
      padding: 0;
      margin: 12px 0;
      display: block;

      @media (min-width: $break-lg) {
        font-size: $type-sm;
      }

      @media (min-width: $break-xxl) {
        font-size: $type-h3;
      }

      span {
        font-size: $type-sm;
        font-weight: $regular;
        color: $color-mid;

        @media (min-width: $break-lg) {
          font-size: $type-sm;
        }

        @media (min-width: $break-xl) {
          font-size: $type-sm;
        }

        @media (min-width: $break-xxl) {
          font-size: $type-h3;
        }
      }

      a {
        text-decoration: underline;
        color: $color-mid;
        font-weight: $regular;
        font-size: $type-default;
        cursor: pointer;
      }
    }
  }

  &-sidebar {
    flex-direction: column;
    font-size: $type-copy;
    margin: 0;
    position: fixed;
    left: 0;
    top: 60px;
    bottom: 0;
    right: 0;
    padding: 0;

    @media (min-width: $break-md) {
      flex-direction: column;
      font-size: $type-copy;
      top: 90px;
      box-sizing: content-box;
      flex: 1 auto;
      left: 0;
      right: 0;
      bottom: 60px;
      position: -webkit-sticky;
      position: sticky;
      height: auto;
      margin: 0;
      padding: 0;
    }

    .form {
      padding: 24px;
      border: 1px solid $color-gray-light;
      background: $color-white;
      box-shadow: 6px 6px 36px $color-gray-note;

      p {
        font-size: $type-sm;
        padding: 0 0 24px 0 !important;
        font-weight: $default;
        margin: 0;
      }

      .MuiFormHelperText-root {
        color: $color-white;
        background-color: $color-pink-default;
        padding: 0 3px;
        margin: 43px 0 0 12px;
        position: absolute;
        text-transform: uppercase;
        font-size: $type-mono;
        font-family: $font-default;
        font-weight: $bold;
        line-height: $type-default;
      }

      .MuiFormControl-root,
      .MuiInputBase-root {
        min-width: 100%;
        box-sizing: content-box;
        margin-bottom: 6px;
      }

      a {
        background: $color-blue-default;
        color: $color-white;
        font-size: $type-default;
        padding: 9px 12px;
        text-align: center;
        border-radius: $radius-default;
        cursor: pointer;
        display: flex;
        text-align: center;
        justify-content: center;
      }
    }
  }

  &-moodle {
    @extend .storefront-sidebar;
    position: unset;

    h4 {
      padding: 0;
      margin: 0;
      font-weight: $regular;
    }
  }

  &-order {
    &-scroll {
      margin: 0;
      padding: 0;
    }
  }

  #price {
    font-weight: $bold;
    font-size: $type-default;
  }

  &-contain {
    color: $color-gray-darkest;

    .status-empty {
      font-size: $type-default;
      margin-top: 24px;
      color: $color-red-default;
    }
  }

  &-quote {
    padding: 12px;
    color: $color-gray-darkest;
    font-size: $type-xxs;

    i {
      font-size: $type-xxs;
      margin: 0 1px;
      color: $color-mid;
    }

    span {
      font-size: $type-xxs;
      color: $color-mid;
    }
  }

  b {
    font-weight: $regular;
  }

  small {
    display: block;
    font-size: $type-note;
    color: $color-gray-darkest;
    text-transform: uppercase;
    padding: 3px 0;
  }

  &-grid {
    flex-direction: column;
    border: none;
    border-bottom: 1px solid $color-gray-half;
    align-items: baseline;
    font-size: $type-default;
    color: $color-gray-darkest;
    padding: 24px 12px;
    grid-column: 1 / -1;
    gap: 12px;
    display: flex;

    &-alt {
      grid-template-columns: auto auto;
    }

    &:hover {
      background: $color-gray-hint;
    }

    @media (min-width: $break-md) {
      grid-column: 1 / -1;
      gap: 24px;
      display: grid;
      grid-template-columns: 120px 100px minmax(auto, 1fr) auto;
      padding: 24px;

      &:hover {
        .link-product {
          background: $color-mid-default;
          color: $color-white;
        }

        .link-product-default {
          color: $color-white;
          background: $color-blue-default;
        }

        .link-product-text {
          color: $color-blue;
        }

        .link-product-details {
          background: $color-blue-default;
          color: $color-white;
        }

        b > span {
          font-size: $type-teeny;
          color: $color-blue;
          text-decoration: underline;
          font-weight: $regular;
          text-transform: uppercase;
          line-height: $type-sm;
          cursor: pointer;
          visibility: visible;
        }
      }
    }

    @media (min-width: $break-lg) {
      grid-column: 1 / -1;
      gap: 12px;
      display: grid;
      grid-template-columns: 120px 120px minmax(auto, 1fr) auto;
      padding: 24px;
    }

    @media (min-width: $break-xl) {
      grid-column: 1 / -1;
      display: grid;
      gap: 24px;
      grid-template-columns: 168px 120px minmax(auto, 1fr) auto;
      padding: 24px;
    }

    &-selected {
      @extend .storefront-grid;
      background: $color-gray-slight;
      border-bottom: 1px solid $color-gray-light;

      &:hover {
        background: $color-gray-slight;
      }
    }

    label {
      text-transform: uppercase;
      font-size: $type-note;
      color: $color-gray-darkest;
      display: block;
      margin: 0 0 3px 0;
    }

    small {
      text-transform: none;
      font-size: $type-tiny;
      color: $color-brand;
      font-weight: $regular;

      i {
        font-size: $type-tiny;
        padding: 0 1px 0 0;
      }
    }

    b {
      font-weight: $regular;

      i {
        color: $color-brand-default;
        transition: $transition-color;
        font-size: $type-teeny;
        text-align: center;
        padding: 0 3px 0 6px;
        cursor: pointer;
      }

      span {
        font-size: $type-teeny;
        color: $color-blue;
        text-decoration: underline;
        font-weight: $regular;
        text-transform: uppercase;
        line-height: $type-sm;
        cursor: pointer;
        visibility: hidden;
      }
    }
  }

  &-action {
    display: inline-flex;
    align-self: flex-start;
    flex-direction: row-reverse;
    gap: 12px;

    button {
      border: 0;
      min-width: auto;
      padding: 0;

      &:last-child {
        padding: 6px;
      }
    }

    @media (min-width: $break-md) {
      flex-direction: row;
      align-self: center;
    }
  }
}
