@use "shared" as *;

.catalog {
  &-contain {
    color: $color-gray-darkest;

    .status-empty {
      font-size: $type-default;
      margin-top: 24px;
      color: $color-red-default;
    }
  }

  &-quote {
    padding: 12px;
    color: $color-gray-darkest;
    font-size: $type-xxs;

    i {
      font-size: $type-xxs;
      margin: 0 1px;
      color: $color-mid;
    }

    span {
      font-size: $type-xxs;
      color: $color-mid;
    }
  }

  &-results {
    font-size: $type-default;
    color: $color-gray-darkest;

    &-contain {
      padding-top: 3px;
      padding-bottom: 0px;
      height: 48px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(18, 29, 45, 0.35);
    }
  }

  &-action {
    padding: 9px 12px;
    border-color: transparent;
    display: block;
    text-align: center;
  }

  @media (min-width: $break-md) {
    &:hover {
      .link-product {
        background: $color-mid-default;
        color: $color-white;
      }

      .link-product-default {
        color: $color-white;
        background: $color-blue;
      }

      .link-product-details {
        background: $color-blue-default;
        color: $color-white;
      }

      b > span {
        font-size: $type-teeny;
        color: $color-blue;
        text-decoration: underline;
        font-weight: $regular;
        text-transform: uppercase;
        line-height: $type-sm;
        cursor: pointer;
        visibility: visible;
      }
    }
  }

  b {
    font-weight: $regular;
  }

  small {
    display: block;
    font-size: $type-note;
    color: $color-gray-darkest;
    text-transform: uppercase;
    padding: 3px 0;
  }

  &-order {
    &-scroll {
      height: 108px;
      overflow-y: auto;
      margin: 6px 0 12px 0;
      background: $color-white-default;
      padding: 0;
    }
    &-noscroll {
      height: 60px;
      overflow-y: auto;
      margin: 6px 0 12px 0;
      background: $color-white-default;
      padding: 0;
    }

    i {
      color: $color-brand !important;
      font-size: 9px !important;
    }
  }

  &-analyte {
    font-size: $type-default;
    color: $color-gray-darkest;
    line-height: $type-md !important;

    &:after {
      content: ", ";
    }

    &:last-child {
      &:after {
        content: "";
      }
    }
  }

  &-fields-split {
    grid-column: 1 / -1;
    display: grid;
    grid-gap: 12px;
    grid-template-columns: repeat(2, auto);
  }

  &-grid {
    flex-direction: column;
    border: none;
    border-bottom: 1px solid $color-gray-half;
    align-items: baseline;
    font-size: $type-default;
    color: $color-gray-darkest;
    padding: 24px 0;
    grid-column: 1 / -1;
    grid-gap: 12px;
    display: grid;
    grid-template-columns: auto 50px;

    &:hover {
      background: $color-gray-hint;
    }

    @media (min-width: $break-md) {
      grid-column: 1 / -1;
      grid-gap: 24px;
      display: grid;
      grid-template-columns: 120px 100px minmax(auto, 1fr) auto;
      padding: 24px 0;
    }

    @media (min-width: $break-lg) {
      grid-column: 1 / -1;
      grid-gap: 24px;
      display: grid;
      grid-template-columns: 120px 120px minmax(auto, 1fr) auto;
      padding: 24px 0;
    }

    @media (min-width: $break-xl) {
      grid-column: 1 / -1;
      display: grid;
      grid-gap: 24px;
      grid-template-columns: 168px 120px minmax(auto, 1fr) auto;
      padding: 24px 0;
    }

    &-selected {
      @extend .catalog-grid;
      background: $color-gray-slight;
      border-bottom: 1px solid $color-gray-light;

      &:hover {
        background: $color-gray-slight;
      }
    }

    b {
      i {
        color: $color-brand-default;
        transition: $transition-color;
        font-size: $type-teeny;
        text-align: center;
        padding: 0 3px 0 6px;
        cursor: pointer;
      }

      span {
        font-size: $type-teeny;
        color: $color-blue;
        text-decoration: underline;
        font-weight: $regular;
        text-transform: uppercase;
        line-height: $type-sm;
        cursor: pointer;
        visibility: hidden;
      }
    }
  }
}

.analytes {
  b {
    font-size: $type-default;
    padding: 3px 0;
  }

  transition: $transition-all;

  &-block {
    background: $color-gray-slight;
    border-bottom: 1px solid $color-gray-half;
    border-top: 1px solid transparent;
    padding: 0 12px;

    @media (min-width: $break-md) {
      padding: 0 24px 12px 24px;
    }

    h3 {
      font-size: $type-default;
      font-weight: $default;
      color: $color-gray-darker;

      strong {
        font-weight: $regular;
        color: $color-gray-darkest;
      }
    }

    ul {
      padding: 12px;
      columns: 2;
      column-gap: 12px;
      background: $color-white;

      @media (min-width: $break-md) {
        columns: 3;
        column-gap: 24px;
      }

      li {
        border: none;
        background: transparent;
        font-size: $type-default;
        font-weight: $regular;
        color: $color-gray-darkest;
      }
    }
  }
}

.products {
  align-items: flex-start;
  flex-direction: column;
  padding: 0 12px;
  margin: 60px 0 0;

  @media (min-width: $break-md) {
    grid-column: 1/-1;
    grid-gap: 12px;
    display: grid;
    padding: 0 24px;
    grid-template-columns: 65% auto;
    margin: 0;
  }

  @media (min-width: $break-xl) {
    grid-column: 1/-1;
    grid-gap: 12px;
    display: grid;
    grid-gap: 36px;
    padding: 0 96px;
    grid-template-columns: 65% auto;
  }

  @media (min-width: $break-huge) {
    grid-column: 1/-1;
    grid-gap: 12px;
    display: grid;
    grid-gap: 36px;
    padding: 0 96px;
    grid-template-columns: 65% auto;
  }

  .w-full {
    width: 100%;
  }

  .uc-word {
    text-transform: uppercase;
  }

  &-split {
    @extend .products;

    h1 {
      font-size: $type-lg;
      font-weight: $light;
    }
    h2 {
      font-size: $type-h2;
      font-weight: $default;
    }
    h3 {
      font-size: $type-h3;
      font-weight: $default;
    }

    p {
      color: $color-gray-darkest;
      font-size: $type-sm;
      line-height: $type-copy-height;
      font-weight: $default;
    }

    ul {
      margin: 24px 0;
      padding: 0 24px;

      li {
        list-style: circle;
        margin: 0 0 24px 0;
        padding: 0;
        font-size: $type-sm;
        font-weight: $default;
        span {
          color: $color-blue;
        }
      }
    }

    .h1 {
      padding: 36px 0 0;

      @media (min-width: $break-md) {
        padding: 0;
      }
    }

    @media (min-width: $break-md) {
      grid-column: 1/-1;
      display: grid;
      grid-gap: 12px;
      grid-template-columns: 65% auto;
    }

    @media (min-width: $break-xl) {
      grid-column: 1/-1;
      display: grid;
      grid-gap: 36px;
      padding: 0 96px 0 96px;
      grid-template-columns: 65% auto;
    }

    @media (min-width: $break-huge) {
      grid-column: 1/-1;
      grid-gap: 12px;
      display: grid;
      grid-gap: 36px;
      padding: 0 96px 0 96px;
      grid-template-columns: 65% auto;
    }
  }

  &-alert {
    background: linear-gradient(to right, #7fced5 0%, #4096c0 48%);
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 16px;
    color: $color-white;
    font-size: $type-default;
    font-weight: $regular;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &-compact {
      @extend .products-alert;
      background: transparent;
      position: fixed;
      left: auto;
      right: 0;
      bottom: 0;
      color: $color-white;
      font-size: $type-default;
      font-weight: $regular;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      button {
        font-size: $type-default;
        outline: 2px solid $color-blue-light;
        background: $color-blue-default;
        padding: 3px 12px;
        color: $color-white;
        border: none;
        border-radius: $radius-slight;
        font-family: $font-default;
        cursor: pointer;
      }
    }

    @media (min-width: $break-md) {
      padding: 12px;
    }

    @media (min-width: $break-lg) {
      padding: 12px 36px 12px 36px;
    }

    @media (min-width: $break-xl) {
      padding: 12px 96px 12px 96px;
    }

    @media (min-width: $break-huge) {
      padding: 12px 96px 12px 96px;
    }

    i {
      cursor: pointer;
      font-size: $type-md;
      margin: 0 0 0 12px;
    }

    a {
      font-size: $type-default;
      color: $color-white;
      padding: 3px 6px;
      font-weight: $regular;
      text-decoration: underline;
      // border-radius: $radius-slight;
      // background: transparent;
      // padding: 6px 12px;
      // margin: 0 6px;
      // border: 1px solid $color-white;
      border-radius: $radius-slight;

      &:hover {
        color: $color-white;
      }
    }
  }

  &-filter {
    padding: 12px 0 0 0;

    input {
      height: auto;
      background: $color-white;
      border: 1px solid $color-gray;
      border-radius: $radius-default;
      color: $color-gray-darkest !important;
      padding: 9px 12px !important;
      font-size: $type-default !important;
      width: 100%;
      margin: 0 0 12px 0 !important;

      &:after {
        border: 1px solid $color-gray;
        background: $color-white;
      }
    }

    fieldset {
      border-color: transparent !important;
    }

    label {
      margin-top: -5px;
      margin-left: -12px;
    }

    @media (min-width: $break-xxs) {
      display: flex;
      justify-content: space-between;

      .search-input {
        min-width: 49% !important;
      }

      .dropdown-contain {
        width: 49%;
      }
    }

    .dropdown {
      &-contain {
        position: relative;
        z-index: $z-ultimate !important;

        @media (max-width: $break-xxs) {
          margin-bottom: 36px;
          margin-top: 8px;
        }
      }

      &-search {
        display: flex;
        width: auto;
      }

      &-menu {
        box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.12);
        background: $color-white;
        position: absolute;
        width: 100%;
        height: auto;
        // max-height: 300px;
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
  }

  &-header {
    padding: 24px 0 12px 0;
    margin: 0;

    p {
      font-size: $type-default;
      font-weight: $default;

      a {
        text-decoration: underline;
        color: $color-brand;
        font-weight: $regular;
        font-size: $type-default;
        cursor: pointer;
      }
    }

    h1 {
      color: $color-gray-darkest;
      font-weight: $default;
      font-size: $type-lg;
      text-transform: uppercase;
      margin: 0;
      padding: 12px 0 0 0;

      @media (min-width: $break-md) {
        padding: 0;
      }
    }

    h2 {
      color: $color-gray-darkest;
      font-size: $type-h3;
      padding: 0;
      font-weight: $default;
      text-transform: uppercase;
      margin: 40px 0 0 0;
    }

    h3 {
      color: $color-gray-darkest;
      font-weight: $default;
      font-size: $type-sm;
      padding: 0;
      margin: 12px 0;
      display: block;

      @media (min-width: $break-lg) {
        font-size: $type-sm;
      }

      @media (min-width: $break-xl) {
        font-size: $type-sm;
      }

      @media (min-width: $break-xxl) {
        font-size: $type-h3;
      }

      span {
        font-size: $type-sm;
        font-weight: $regular;
        color: $color-mid;

        @media (min-width: $break-lg) {
          font-size: $type-sm;
        }

        @media (min-width: $break-xl) {
          font-size: $type-sm;
        }

        @media (min-width: $break-xxl) {
          font-size: $type-h3;
        }
      }

      a {
        text-decoration: underline;
        color: $color-mid;
        font-weight: $regular;
        font-size: $type-default;
        cursor: pointer;
      }
    }
  }

  &-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: 1px solid $color-gray-slight;
    background: $color-gray-solid;
    z-index: $z-footer !important;
    color: $color-white;
    font-weight: $default;
    text-transform: uppercase;
    text-align: center;
    padding: 12px;
    display: flex;
    gap: 12px;

    span {
      width: 24px;
      height: 24px;
      background: $color-indicator;
      color: $color-indicator-text;
      -moz-border-radius: 70px;
      -webkit-border-radius: 70px;
      border-radius: 70px;
      font-size: $type-xs;
      font-weight: $regular;
      justify-content: center;
      align-items: center;
      display: inline-flex;
      position: absolute;
      right: -12px;
      top: -12px;
      vertical-align: middle;
    }
  }

  &-submit-form {
    .MuiInput-underline:before {
      display: none;
    }

    label {
      font-size: $type-teeny !important;
    }
  }

  &-sidebar {
    flex-direction: column;
    font-size: $type-copy;
    margin: 0 0 60px;
    position: fixed;
    left: 0;
    top: 60px;
    bottom: 0;
    right: 0;
    padding: 0;
    background-color: $color-gray-solid;

    &-inpage {
      .form {
        // width: 420px !important;
        padding: 0;

        button {
          width: 100%;
        }

        .MuiFormHelperText-root {
          color: $color-white;
          background-color: $color-pink-default;
          padding: 0 3px;
          margin: 43px 0 0 12px;
          position: absolute;
          text-transform: uppercase;
          font-size: $type-mono;
          font-family: $font-default;
          font-weight: $bold;
          line-height: $type-default;
        }

        .MuiFormControl-root,
        .MuiInputBase-root {
          min-width: 100%;
          box-sizing: content-box;
          margin-bottom: 6px;
        }

        &-confirmation {
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          // padding: 24px;
          // min-height: 400px;
          // height: auto;
          @extend .form;

          b {
            font-weight: $bold;
          }

          p {
            color: $color-gray-darkest;
          }
        }

        a {
          background: $color-blue-default;
          color: $color-white;
          font-size: $type-default;
          padding: 9px 12px;
          text-align: center;
          border-radius: $radius-default;
          cursor: pointer;
          display: flex;
          text-align: center;
          justify-content: center;
        }

        h1 {
          font-size: $type-sm;
          font-weight: $regular;
          padding: 0;
          margin: 0 0 12px 0;
        }

        label {
          color: $color-gray-darkest;
          font-size: $type-xxs;
          font-weight: $regular;
        }

        input,
        textarea,
        .MuiSelect-select,
        select {
          background: $color-white;
          border: 1px solid $color-gray;
          border-radius: $radius-default;
          color: $color-gray-darkest !important;
          padding: 9px 12px !important;
          margin: 0 !important;
          font-size: $type-default !important;
          width: 100%;
          -webkit-appearance: none !important;

          &:after {
            border: 1px solid $color-gray;
            background: $color-white;
          }
        }

        select {
          background: $color-white;
          border: 1px solid $color-gray;
          border-radius: $radius-default;
          color: $color-gray-darkest !important;
          padding: 9px 12px !important;
          margin: 0 !important;
          font-size: $type-default !important;
          width: 100%;
          -webkit-appearance: none !important;
        }

        textarea {
          height: 30px !important;
        }
      }
    }

    h3 {
      color: $color-gray-darkest;
      font-size: $type-h3;
      margin: 0;
      padding: 12px 24px 0 24px;
      font-weight: $default;
      text-transform: uppercase;
    }

    &-disabled {
      display: none;
    }

    &-enabled {
      display: flex;
      z-index: 10;
      overflow: auto;
    }

    @media (min-width: $break-md) {
      flex-direction: column;
      font-size: $type-copy;
      top: 84px;
      box-sizing: content-box;
      flex: 1 auto;
      left: 0;
      right: 0;
      position: -webkit-sticky;
      position: sticky;
      height: auto;
      // -webkit-user-select: none;
      // -moz-user-select: none;
      // -ms-user-select: none;
      // user-select: none;
      margin: 0;
      padding: 0;
      background-color: $color-gray-slight;
    }

    .form {
      padding: 12px 24px 0 24px;

      @media (min-width: $break-md) {
        padding: 24px;
      }

      .MuiFormHelperText-root {
        color: $color-white;
        background-color: $color-pink-default;
        padding: 0 3px;
        margin: 43px 0 0 12px;
        position: absolute;
        text-transform: uppercase;
        font-size: $type-mono;
        font-family: $font-default;
        font-weight: $bold;
        line-height: $type-default;
      }

      .MuiFormControl-root,
      .MuiInputBase-root {
        min-width: 100%;
        box-sizing: content-box;
        margin-bottom: 6px;
      }

      &-confirmation {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        // padding: 24px;
        // min-height: 400px;
        // height: auto;
        @extend .form;

        b {
          font-weight: $bold;
        }

        p {
          color: $color-gray-darkest;
        }

        &-andr {
          @extend .form-confirmation;
          border: 1px solid $color-green;
        }
      }

      a {
        background: $color-blue-default;
        color: $color-white;
        font-size: $type-default;
        padding: 9px 12px;
        text-align: center;
        border-radius: $radius-default;
        cursor: pointer;
        display: flex;
        text-align: center;
        justify-content: center;
      }

      h1 {
        font-size: $type-sm;
        font-weight: $regular;
        padding: 0;
        margin: 0 0 12px 0;
      }

      label {
        color: $color-gray-darkest;
        font-size: $type-xxs;
        font-weight: $regular;
      }

      input,
      textarea,
      .MuiSelect-select,
      select {
        background: $color-white;
        border: 1px solid $color-gray;
        border-radius: $radius-default;
        color: $color-gray-darkest !important;
        padding: 9px 12px !important;
        margin: 0 !important;
        font-size: $type-default !important;
        width: 100%;
        -webkit-appearance: none !important;

        &:after {
          border: 1px solid $color-gray;
          background: $color-white;
        }
      }

      select {
        background: $color-white;
        border: 1px solid $color-gray;
        border-radius: $radius-default;
        color: $color-gray-darkest !important;
        padding: 9px 12px !important;
        margin: 0 !important;
        font-size: $type-default !important;
        width: 100%;
        -webkit-appearance: none !important;
      }

      textarea {
        height: 30px !important;
      }
    }
  }
}

.year-filter {
  width: 120px;

  label[data-shrink="false"] {
    padding: 6px 12px !important;
    font-weight: $default !important;
  }

  input {
    border: 1px solid $color-gray;
    border-radius: $radius-default;
    padding: 8px 12px !important;
  }

  .MuiInput-root {
    padding-right: 0 !important;

    &::before,
    &::after {
      border-bottom: 0 !important;
    }
  }

  &-ddm[class*="-elevation1"] {
    box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.12) !important;
  }

  &-list[class*="-listbox"] {
    padding: 0;

    li {
      background-color: $color-white !important;
      border-bottom: 1px solid $color-gray-light;
      padding: 8px 16px;
      font-size: $type-default;
    }
  }
}
