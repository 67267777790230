@use "shared" as *;

.earth {
  &-globe {
    z-index: 0;
  }

  &-grid {
    height: 100%;
    background: $color-white-slight;
    display: flex;
    justify-content: center;

    canvas {
      border: none;
      outline: none;
    }

    &-console {
      position: absolute;
      pointer-events: none;
      left: 0;
      top: 0;
      width: 80px;
      z-index: 1;
      align-self: flex-start;
      padding: 24px;
    }

    &-controls {
      align-self: flex-start;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      text-align: right;
      width: 150px;
      z-index: 1;
      padding: 24px;

      a {
        font-size: $type-xs;
      }
    }
  }
}
