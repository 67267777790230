@use "shared" as *;

html,
body {
  font-family: $font-default;
  font-size: $type-xxs;
  font-weight: $regular;
  background: $color-background;
  color: $color-gray-darkest;
  padding: 0 !important;
  margin: 0;
}

.stamp {
  display: inline-flex;
  color: $color-white;
  padding: 3px 12px 3px 6px;
  font-weight: $bold;
  font-size: $type-xxs;
  align-items: center;
  justify-content: center;
  margin: 6px;
  border-radius: $radius-default;
  cursor: pointer;

  &-poct {
    @extend .stamp;
    background: linear-gradient(
      to right,
      $color-mid-light 30%,
      $color-mid-medium 30%
    );
    &:hover {
      background: linear-gradient(
        to right,
        $color-mid-light 30%,
        $color-mid 30%
      );
      i {
        color: $color-mid-default;
      }
    }
    i {
      margin: 0 16px 0 0;
      color: $color-mid-medium;
      font-size: $type-default;
    }
  }
  &-lab {
    @extend .stamp;
    background: linear-gradient(
      to right,
      $color-brand-light 36%,
      $color-brand-medium 36%
    );
    &:hover {
      background: linear-gradient(
        to right,
        $color-brand-light 36%,
        $color-brand 36%
      );
      i {
        color: $color-brand-default;
      }
    }
    i {
      margin: 0 16px 0 0;
      color: $color-brand-medium;
      font-size: $type-default;
    }
  }
}

.outlier-andr-aside {
  margin-top: 0;

  @media (min-width: $break-md) {
    margin-top: 82px;
  }
}

.message {
  &-success {
    span {
      float: right;
      background: $color-green-default;
      color: $color-white;
      padding: 2px 12px;
      border-radius: $radius-module;
      z-index: $z-ultimate !important;
      font-size: $type-default;
      font-weight: $bold;
      display: flex;
      align-items: center;
      i {
        color: $color-white;
        margin: 0 6px 0 0;
        font-size: $type-default;
      }
    }

    button {
      font-size: $type-default;
      border: none;
      color: $color-blue-default;
      cursor: pointer;
      font-weight: $regular;
      font-family: $font-default;
      text-decoration: underline;
      background: transparent;
      padding: 0;

      &:hover {
        color: $color-blue;
      }
    }
  }
}

.csv {
  list-style: none;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: $color-blue-slight;
  width: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: $radius-default;
  background: $color-blue-light;

  &:hover {
    background: $color-blue-default;
  }
}

::-webkit-input-placeholder {
  color: $color-gray-darkest;
  font-weight: $default;
  opacity: 1;
}

:-ms-input-placeholder {
  color: $color-gray-darkest;
  font-weight: $default;
  opacity: 1;
}

::placeholder {
  color: $color-gray-darkest;
  font-weight: $default;
  opacity: 1;
}

.notification {
  background: $color-mid-default;
  color: $color-white;
  padding: 0 24px;
  height: 23px;
  line-height: 23px;
  width: auto;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: $z-ultimate;
  text-decoration: none;
  font-size: $type-default;
  font-weight: $bold;

  i {
    align-self: center;
    margin-right: 6px;
  }

  &:hover {
    background: $color-mid;
    color: $color-white;
  }

  @media (min-width: $break-sm) {
    position: absolute;
    top: 0;
    left: 0;
    right: auto;
  }
}

.unavailable {
  color: $color-gray-darkest;
  text-align: center;
  font-size: $type-xs;
  display: block;

  a {
    color: $color-blue;
    text-decoration: underline;
  }
}

// iframe {
//   min-height: 280px !important;
// }

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.show-flex {
  display: inline-flex !important;
}

.sidebar {
  color: $color-gray-darker;

  strong {
    font-weight: $regular;
    color: $color-gray-darkest;
    font-size: $type-sm;
  }
}

table {
  width: 100%;
  border: none;
  font-size: $type-xxs;
  border-spacing: 0;
  margin: 24px 0;

  @media (min-width: $break-sm) {
    font-size: $type-xs;
  }

  // border: 1px solid $color-gray-medium !important;
  thead {
    tr {
      background: $color-brand-note;
      border: none;

      th {
        font-weight: $bold !important;
        border: none;
        text-align: left;
      }
    }

    tr > th {
      border-bottom: 1px solid $color-gray-half !important;
    }
  }
  tbody {
    tr > td {
      border-bottom: 1px solid $color-gray-half !important;
    }
  }

  th {
    padding: 5px;
  }

  tr > td {
    padding: 6px;

    strong {
      font-weight: $regular;
    }
  }
}

.holiday {
  background: $color-blue;
  color: $color-white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 12px 48px;
  font-size: $type-md;
  position: fixed;
  height: 60px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: $z-ultimate;
}

.maintenance {
  background: $color-pink;
  color: $color-white;
  text-transform: uppercase;
  display: inline-flex;
  justify-content: center;
  padding: 4px 6px;
  font-size: $type-xs;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: $z-ultimate;
}

input,
select {
  outline: none;
  font-size: $type-sm;
  font-family: $font-default !important;
  font-weight: $regular;
}

.accent-border {
  border-top: 2px solid $color-brand-half;
  margin: 0;

  @media (min-width: $break-sm) {
    margin: 12px 24px;
  }
}

.desktop {
  display: none !important;

  @media (min-width: $break-md) {
    display: block !important;
  }
}

.mobile {
  display: block !important;

  @media (min-width: $break-md) {
    display: none !important;
  }
}

sup {
  line-height: 10px;
}

.contain {
  margin: 0 auto;
  background: $color-white;
  max-width: 100%;
  position: relative;
  min-height: 1200px;
  box-shadow: 0 0 84px $color-gray-light;
  border-left: 1px solid $color-gray-half;
  border-right: 1px solid $color-gray-half;

  @media (min-width: $break-lg) {
    max-width: 100%;
  }

  @media (min-width: $break-xl) {
    max-width: $break-xxl;
  }

  &-filter {
    padding: 0 96px 12px 96px;
    background: $color-white;

    @media (min-width: $break-sm) {
      margin: 0 auto;
    }
  }

  &-404 {
    // margin: 0 auto;
    // max-width: 100%;
    padding: 12% 24px;

    h1 {
      font-weight: $default;
      font-size: $type-lg;
      text-align: center;
      text-transform: none;
    }
  }
}

.cookie {
  border: 2px solid $color-gray-darker;
  padding: 6px 12px;
  color: $color-gray-darker;
  font-size: $type-xxs;
  font-weight: $regular;
  position: fixed;
  left: 24px;
  bottom: 24px;
  background: $color-white;
  z-index: $z-ultimate;

  text-transform: uppercase;

  a {
    text-transform: uppercase;
  }
}

.emergency {
  z-index: $z-ultimate !important;
  background: $color-blue;
  color: $color-white;
  padding: 12px 24px;
  margin: 0 auto;
  height: 700px;

  @media (min-width: $break-lg) {
    padding: 24px 100px;
  }

  p {
    font-size: $type-xxs;
    line-height: $type-xs;

    @media (min-width: $break-sm) {
      font-size: $type-sm;
      line-height: $type-md;
    }
  }

  h2 {
    color: $color-white;
    font-size: $type-h2;
    padding: 0;
    margin: 0;

    @media (min-width: $break-sm) {
      padding: 12px 0;
      font-size: $type-lg;
    }
  }
}

.translations {
  &-container {
    padding: 36px 96px;

    h1 {
      font-weight: $default;
      padding: 0;
      margin: 12px 0;
      font-size: $type-h2;
    }

    h2 {
      font-weight: $default;
      padding: 0;
      margin: 0;
      font-size: $type-h2;
    }

    .MuiAccordionSummary-content {
      background: $color-gray-slight;
      padding: 12px;
      margin: 6px 0;

      grid-column: 1 / -1;
      display: grid;
      grid-gap: 6px;
      flex-direction: column;
      grid-template-columns: auto repeat(6, 36px);
      align-items: center;
    }

    .Mui-expanded {
      background: $color-white !important;
    }
  }

  &-status {
    text-align: center;
    font-weight: $regular;
    font-size: $type-xxs;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 6px;
    padding: 3px 6px;

    &-on {
      @extend .translations-status;
      color: $color-mid-default;
      background: $color-mid-slight;
    }

    &-off {
      color: $color-white;
      background: $color-pink-medium;
      @extend .translations-status;
    }
  }

  &-header {
    label {
      display: block;
      font-size: $type-teeny;
      color: $color-gray;
    }

    strong {
      font-weight: $regular;
      font-size: $type-sm;
      color: $color-gray-darkest;

      span {
        color: $color-gray-darkest;
      }
    }

    i {
      font-size: $type-sm;
      padding: 0 6px 0 0;
    }
  }

  label {
    display: block;
    font-size: $type-tiny;
  }

  strong {
    font-weight: $regular;
    font-size: $type-sm;
    color: $color-gray-darkest;

    span {
      color: $color-brand;
    }
  }

  i {
    font-size: $type-default;
    padding: 0 24px 0 0;
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      label {
        font-weight: $default;
      }

      font-size: $type-default;
      font-weight: $regular;

      &:first-child {
        border-top: none;
      }

      &:last-child {
        border-bottom: none;
      }

      padding: 6px 0;
      list-style: none !important;
      margin: 0;
      border-top: 1px solid $color-gray-light;

      i {
        color: $color-pink-default;
        font-size: $type-default;
        padding: 0;
      }
    }
  }
}

.lang {
  z-index: $z-ultimate;
  font-size: $type-xxs;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0 auto;
  max-width: 100%;
  background: $color-white;
  padding: 0;

  &-alert {
    padding: 12px;
    background: $color-pink-slight;
    font-weight: $regular;
    color: $color-pink;
    font-size: $type-default;
    line-height: normal;
    display: block;
    text-transform: none;
    margin: 0 0 24px 0;

    strong {
      font-weight: $bold;
      text-transform: uppercase;
    }
  }

  @media (min-width: $break-md) {
    top: 0;
    right: 0;
  }

  ul {
    padding: 4px 12px;
    margin: 0;
    display: inline-flex;

    li {
      justify-content: flex-end;
      color: $color-gray-darkest;
      background: transparent;
      text-align: center;
      border: none !important;
      margin-left: 4px;
      list-style: none;

      .selected {
        color: $color-white;
        background: $color-blue-default;

        &:hover {
          color: $color-white;
          background: $color-blue;
        }
      }

      span {
        display: inherit;
        cursor: pointer;
        padding: 0 6px;
        color: $color-gray-darkest;

        font-weight: $regular;
        font-size: $type-default !important;

        &:hover {
          color: $color-white;
          background: $color-blue;
        }
      }
    }
  }
}

.content {
  background: $color-gray-solid;
  margin: 0 24px;
}

.content-short {
  background: $color-white;
  margin: 0 72px;

  b {
    font-weight: $regular;
  }
}

.status {
  &-empty {
    font-size: $type-default;
    padding: 24px 0;
    color: $color-pink;
  }

  &-current {
    color: $color-white;
    font-size: $type-xxs;

    b {
      font-size: $type-sm;
      font-weight: $regular;
      color: $color-white;
      background: $color-gray-darkest;
      padding: 0 6px;
      width: 60px;
      border-radius: $radius-slight;
    }
  }

  &-featured {
    border-radius: 0;
    background: $color-blue-light;
    color: $color-blue;
    font-size: $type-teeny;
    padding: 1px 6px;
    line-height: $type-lg;

    &-on {
      @extend .status-featured;
      background: $color-blue;
      color: $color-white;
    }
  }
}

.media {
  display: flex;
  justify-content: center;
  align-content: flex-start;
  align-items: center;
  align-self: flex-start;
  flex-direction: column;

  img {
    width: 140px;
    margin-bottom: 20px;
  }
}

.member-dialog {
  padding: 30px 40px;
  min-height: 65px;
  font-weight: $regular;
  z-index: $z-ultimate;

  label {
    display: block;
    font-size: $type-xs;
  }

  select {
    display: block;
    font-size: $type-xs;
  }
}

.flex {
  display: flex !important;

  &-20 {
    max-width: 100%;
    max-height: 100%;

    @media (min-width: $break-sm) {
      max-width: 20%;
      max-height: 100%;
      min-width: 20%;
    }
  }

  &-25 {
    max-width: 100%;
    max-height: 100%;

    @media (min-width: $break-sm) {
      max-width: 24%;
      max-height: 100%;
      min-width: 24%;
    }
  }

  &-30 {
    max-width: 100%;
    max-height: 100%;

    @media (min-width: $break-sm) {
      max-width: 30%;
      max-height: 100%;
      min-width: 30%;
    }
  }

  &-50 {
    max-width: 100%;
    max-height: 100%;

    @media (min-width: $break-sm) {
      max-width: 50%;
      max-height: 100%;
    }
  }

  &-60 {
    max-width: 100%;
    max-height: 100%;

    @media (min-width: $break-sm) {
      max-width: 60%;
      max-height: 100%;
      min-width: 60%;
    }
  }

  &-70 {
    max-width: 100%;
    max-height: 100%;

    @media (min-width: $break-sm) {
      max-height: 100%;
      width: 70%;
    }
  }

  &-75 {
    max-width: 100%;
    max-height: 100%;

    @media (min-width: $break-sm) {
      max-width: 75%;
      max-height: 100%;
      min-width: 75%;
    }
  }

  &-80 {
    max-width: 100%;
    max-height: 100%;
    width: 100%;

    @media (min-width: $break-sm) {
      max-width: 80%;
      max-height: 100%;
      min-width: 80%;
    }
  }

  &-auto {
    max-width: 100%;
    max-height: 100%;

    @media (min-width: $break-sm) {
      max-width: auto;
      max-height: 100%;
      min-width: auto;
    }
  }

  &-80 {
    max-width: 100%;
    max-height: 100%;

    @media (min-width: $break-sm) {
      max-width: 80%;
      max-height: 100%;
      min-width: 80%;
    }
  }

  &-auto {
    max-width: auto;
    min-width: auto;
    max-height: auto;
  }

  &-100 {
    @media (min-width: $break-xs) {
      max-width: 100%;
      max-height: 100%;
      min-width: 100%;
    }
  }

  &-right {
    display: flex;
    margin-left: auto;
    align-self: center;
    align-items: flex-end;
    align-content: flex-end;
    justify-content: flex-end;
    text-align: right;
  }
}

.layout {
  &-row {
    flex-direction: row !important;

    &-sm {
      display: flex !important;
      flex-direction: column !important;

      @media (min-width: $break-sm) {
        display: flex !important;
        flex-direction: row !important;
      }
    }
  }

  &-column {
    flex-direction: column !important;
    flex: auto;
  }
}

.align {
  &-right {
    justify-content: flex-end;
    text-align: right;
    align-self: flex-end;
  }

  &-center {
    justify-content: center;
    text-align: center;
  }
}

.layout-row {
  display: flex;
  font-size: $type-default;
  color: $color-gray-light;
}

.nudge {
  &-xs {
    margin-bottom: 6px;
  }

  &-sm {
    margin-bottom: 12px;
  }

  &-md {
    margin-bottom: 24px;
  }

  &-lg {
    margin-bottom: 40px;
  }

  &-xl {
    margin-bottom: 65px;
  }

  &-xxl {
    margin-bottom: 120px;
  }
}

.slide-text {
  font-size: $type-default;
  color: $color-white;
  margin: 10px 50px 10px 50px;
  font-weight: $regular;
  line-height: $type-lg;
  text-align: left;

  @media (min-width: $break-sm) {
    line-height: $type-xl;
    text-align: justify;
    display: block;
  }

  &-covid {
    @extend .slide-text;
    font-size: $type-sm;
    font-weight: $default;

    color: $color-gray-dark;

    img {
      vertical-align: middle;
      height: 25px;
    }
  }
}

.video-desc {
  b {
    font-weight: $regular !important;
    font-size: $type-md !important;
    color: $color-gray-darkest;
    display: block;
    text-transform: none;
    padding: 0 0 6px 0;
  }
}

.check {
  &-green {
    color: $color-green;
    padding: 0 12px 0 3px;
    font-size: $type-sm;
  }

  &-transparent {
    color: transparent;
    padding: 0 12px 0 3px;
    font-size: $type-sm;
  }
}

.video-programs {
  display: flex;
  margin-top: 72px;
  flex-direction: column;
  padding: 0;

  h2 {
    text-transform: none !important;
  }

  @media (min-width: $break-sm) {
    flex-direction: row;
    padding: 96px;
  }

  &-left {
    flex: 0 auto;
    width: 100%;
    justify-content: flex-start;

    iframe {
      width: 100%;
      height: 100%;
      // position: absolute;
      // top: 50%;
      // left: 50%;
      // transform: translateX(-50%) translateY(-50%);
      // opacity: 0.1;
      // z-index: $z-base !important;
    }
  }

  &-right {
    padding: 24px;
    flex: 1;

    h1 {
      font-size: $type-lg;
      padding: 0 !important;
      margin: 0 !important;
    }

    h2 {
      font-size: $type-md;
      padding: 0;
      margin: 0 !important;
    }

    p {
      strong {
        font-size: $type-sm;
        font-weight: $regular;
      }

      color: $color-gray-darker;
      font-size: $type-sm;
    }

    small {
      color: $color-gray-darker;
    }
  }
}

.env {
  font-size: $type-md;
  padding: 96px;

  div {
    margin: 12px 0;
  }

  small {
    display: block;
    color: $color-gray-darker;
    font-size: $type-xxs;
    padding: 6px 0;
    text-transform: uppercase;
  }

  strong {
    color: $color-gray-darkest;
    font-weight: $regular;
  }

  a {
    color: $color-blue;
    font-weight: $bold;
  }
}

// override metrics material ui expansion panel

.MuiList-root .MuiMenu-list {
  z-index: $z-ultimate !important;
}

.MuiMenu-root .MuiMenu-paper {
  z-index: $z-ultimate !important;
}

.MuiPaper-elevation8 {
  top: 3px !important;
}

.MuiAccordionDetails-root {
  flex-direction: column !important;
}

.MuiSvgIcon-root {
  width: 0.6em !important;
}

.MuiAccordionPanel-root:before {
  background-color: transparent !important;
}

.MuiTypography-body1 {
  font-size: $type-xs !important;
  font-weight: $regular !important;
  font-family: $font-default !important;
  color: $color-gray-darkest !important;
}

.MuiAccordionSummary-root.Mui-expanded {
  min-height: 0 !important;
}

.MuiMenu-list {
  border: 1px solid $color-gray;
}

.MuiAccordionSummary-root-6 {
  padding: 0 !important;
}

.MuiAccordionSummary-root {
  padding: 0 !important;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.MuiAccordion-root:before {
  top: -1px;
  left: 0;
  right: 0;
  height: 1px;
  content: "";
  opacity: 1;
  position: absolute;
  background-color: white !important;
}

.pad {
  padding: 0 24px;
}

.pad-24 {
  padding: 24px;
}

.pad-24-0 {
  padding: 24px 0;
}

.pad-48 {
  padding: 48px;
}

li {
  font-size: $type-xs;
  font-weight: $regular;
}

p {
  font-size: $type-xs;
  font-weight: $regular;

  span {
    color: $color-blue;
    font-weight: $regular;
  }
}

.metrics {
  text-transform: uppercase;
  margin-bottom: 14px;
  text-align: center;

  &-line1 {
    font-size: $type-tiny;
    line-height: $type-xs;
    color: $color-gray-dark;
  }

  &-line2 {
    font-size: $type-tiny;
    line-height: $type-xs;
    color: $color-gray-dark;
  }

  &-number {
    font-size: $type-xl;
    color: $color-brand !important;
    font-weight: $default;
    margin: 6px 0;
  }
}

.avatar {
  border-radius: $radius-round;
  height: 100px;
  width: 100px;
  background-size: cover;
  background-color: $color-gray-solid;
  outline: 4px solid $color-gray-slight;
}

.slide-hero {
  background: $color-blue;
  background: linear-gradient(to right, $color-blue 25%, $color-blue-half 72%);
  height: 400px;
  width: 100%;
  display: flex;

  &-covid {
    @extend .slide-hero;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    &-title {
      font-size: $type-hero;
      font-family: $font-default;
      font-weight: $ultra;
      color: $color-gray-darkest;
      padding: 40px 50px 0 50px;
      line-height: $type-xxl;
      z-index: $z-ultimate;

      @media (min-width: $break-sm) {
        line-height: inherit;
      }
    }

    &-sub {
      padding: 12px 20px 0 50px;
    }
  }

  &-white {
    @extend .slide-hero;
    background: $color-gray-solid;
  }

  &-lang {
    align-items: flex-end;
    justify-content: flex-end;
    display: flex;
    color: $color-white-half;
    font-family: $font-default;
    padding: 12px 24px;
    align-self: flex-start;
    align-items: flex-start;
    align-content: flex-start;

    li {
      color: $color-white;
      background: transparent;
      text-align: center;
      margin-left: 5px;
      border: none;

      .selected {
        color: $color-white;
        background: #4aa988;

        &:hover {
          background: #4aa988;
        }
      }

      a {
        padding: 0 8px;
        font-weight: $regular;

        &:hover {
          color: $color-white;
          background: $color-white-slight;
        }
      }

      &:first-child {
        margin-right: 12px;
      }
    }
  }

  &-main {
    align-self: center;
  }

  &-title {
    font-size: $type-hero;
    font-family: $font-default;
    font-weight: $default;
    color: $color-white;
    text-transform: uppercase;
    padding: 40px 50px 0 50px;
    line-height: $type-xxl;
    z-index: $z-ultimate;

    @media (min-width: $break-sm) {
      line-height: inherit;
    }
  }

  &-sub {
    font-size: $type-sm;
    font-family: $font-default;
    color: $color-blue-light;
    font-weight: $regular;
    max-width: $break-xxs;
    text-transform: uppercase;
    padding: 0 80px;
  }
}

.slide-hero-2 {
  @extend .slide-hero;
  background: $color-gray-solid !important;
}

// override carousel / material ui

.MuiAccordionPanelSummary-root {
  padding: 0 24px 0 0 !important;
}

.MuiAccordionPanel-root.Mui-expanded {
  margin: 0 !important;
}

.MuiAccordionPanelDetails-root {
  padding: 0 24px !important;
  display: block !important;
}

.MuiPaper-elevation8 {
  box-shadow: 0 0 0 $color-gray-slight !important;
  // border: 1px solid $color-gray !important;
  border-radius: 0 !important;
  min-width: 275px !important;
}

.list {
  li {
    font-size: $type-default !important;
  }
}

.MuiDrawer-paper {
  width: 320px;
  background: $color-white !important;
  // border-left: 2px solid $color-gray-light;
  box-shadow: none;
  padding: 0;
  color: $color-gray-darkest;
  text-transform: uppercase;
  z-index: $z-drawer !important;

  @media (min-width: $break-sm) {
    width: 600px !important;
  }
}

.tile-header {
  padding: 24px 24px 14px 24px;
  font-size: $type-h1;
  text-transform: uppercase;
  color: $color-gray-darkest;
  font-weight: $regular;
  text-align: center;
}

.flex-grid {
  display: block;
  align-items: stretch;
  background: $color-white;

  @media (min-width: $break-sm) {
    display: flex;
    margin: 0 19px;
  }
}

.column-5 {
  flex-direction: column;

  @media (min-width: $break-sm) {
    width: 20%;
  }

  a {
    color: $color-gray-darkest;
    cursor: pointer;
    text-align: center;
    width: auto;
    display: block;
    text-decoration: none !important;
  }

  &-heading {
    text-align: center;
    background: $color-blue-medium;
    color: $color-white;
    font-size: $type-sm;
    font-weight: $regular;
    max-width: 200px;
    display: flex;
    align-self: flex-end;
    align-content: flex-end;
    padding: 4px 6px;
  }
}

.tile {
  background: $color-gray-solid;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: $radius-default;
  border: 1px solid $color-gray-slight;
  width: 100%;
  height: 70px;
  cursor: pointer;

  &:hover {
    border: 1px solid $color-gray-light;
  }

  @media (min-width: $break-sm) {
    width: 20%;
  }

  a {
    color: $color-blue;
    text-align: center;
    width: auto;
    display: block;
    font-size: $type-sm;
    padding: 10px;
    text-decoration: none !important;
  }

  &-heading {
    text-align: center;
    background: $color-blue-medium;
    color: $color-white;
    font-size: $type-sm;
    font-weight: $regular;
    max-width: 200px;
    display: flex;
    align-self: flex-end;
    align-content: flex-end;
    padding: 4px 6px;
  }
}

.footnote {
  color: $color-gray-dark;
  font-size: $type-xxs;

  i {
    font-size: 6px;
    vertical-align: text-top;
  }
}

// TILES

.strip-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding: 0;
  align-self: flex-start;
  margin-bottom: 20px;

  i {
    color: $color-gray-slight;
    padding: 24px;
  }

  &:hover {
    cursor: pointer;
    border: none !important;

    span {
      background: $color-blue;
      color: $color-white !important;
    }

    img {
      border: none !important;
    }

    i {
      color: $color-blue;
      animation-name: slideInOut;
      animation-duration: 1s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
    }
  }
}

.strip-column {
  display: flex;
  flex-direction: column;
  color: $color-gray-darkest;
  align-self: center;
  justify-content: flex-start;
  flex: 0;

  img {
    height: 80px;
    filter: grayscale(50%);
    border: none !important;
  }
}

.strip-column-2 {
  // display: flex;
  // flex-direction: column;
  color: $color-gray-darkest;
  align-self: center;
  justify-content: flex-start;
  padding: 10px;

  font-size: $type-sm;

  span {
    font-size: $type-sm;
    border-radius: $radius-slight;
    text-transform: uppercase;
    background: $color-blue-slight;
    color: $color-blue;
    padding: 2px 10px;
  }
}

.center {
  display: flex;
  align-items: center;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.error-view-button {
  background-color: $color-blue-default;
  color: $color-white;
  border-radius: 3px;
  padding: 8px 20px;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: $font-default;
  font-size: $type-default;
}

.error-view {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  button {
    @extend .error-view-button;
  }
}

.error-container {
  @extend .error-view;
  height: 100vh;

  button {
    @extend .error-view-button;
    margin: 0;
  }
}

.ddm-menu-list {
  padding: 0 !important;

  li {
    background-color: $color-white !important;
    border-bottom: 1px solid $color-gray-light;
    padding: 8px 16px;
    font-size: $type-default;
  }
}
