@use "shared" as *;

.alert {
  color: $color-white;
  padding: 6px 12px;
  border-radius: $radius-slight;
  font-size: $type-default;
  font-weight: $regular;
  top: 0;
  left: 0;
  position: fixed;
  align-items: center;
  z-index: $z-ultimate !important;

  i {
    color: $color-white;
    padding: 0 6px 0 0;
    align-self: center;
    vertical-align: middle;
    font-size: $type-xxs;
    display: inline-flex;
  }

  &-error {
    @extend .alert;
    background: $color-red-default;
  }
  &-success {
    @extend .alert;
    background: $color-green;
  }
  &-info {
    @extend .alert;
    background: $color-blue-default;
  }
  &-warning {
    @extend .alert;
    background: $color-orange-default;
  }
}
