$color-white: #fff;
$color-white-light: rgba(255, 255, 255, 0.3);
$color-white-half: rgba(255, 255, 255, 0.5);
$color-white-medium: rgba(255, 255, 255, 0.75);
$color-white-default: rgba(255, 255, 255, 0.95);
$color-white-slight: rgba(255, 255, 255, 0.15);
$color-white-note: rgba(255, 255, 255, 0.06);
$color-white-hint: rgba(255, 255, 255, 0.03);

$color-red: rgba(173, 0, 0, 1); // B70501
$color-red-default: rgba(173, 0, 0, 0.85); // C22A27
$color-red-medium: rgba(173, 0, 0, 0.75); // C94441
$color-red-half: rgba(173, 0, 0, 0.5); // DB8280
$color-red-light: rgba(173, 0, 0, 0.3); // E9B4B2
$color-red-slight: rgba(173, 0, 0, 0.15); // F4DAD9
$color-red-note: rgba(173, 0, 0, 0.06); // FBF0F0

$color-pink: rgba(255, 63, 63, 1); // FF7B7B
$color-pink-default: rgba(255, 63, 63, 0.9);
$color-pink-medium: rgba(255, 63, 63, 0.75);
$color-pink-half: rgba(255, 63, 63, 0.5);
$color-pink-light: rgba(255, 63, 63, 0.3);
$color-pink-slight: rgba(255, 63, 63, 0.15);
$color-pink-note: rgba(255, 63, 63, 0.06);

$color-orange: rgba(255, 162, 64, 1); // FFAD00
$color-orange-default: rgba(255, 162, 64, 0.9);
$color-orange-medium: rgba(255, 162, 64, 0.75);
$color-orange-half: rgba(255, 162, 64, 0.5);
$color-orange-light: rgba(255, 162, 64, 0.3);
$color-orange-slight: rgba(255, 162, 64, 0.15);
$color-orange-note: rgba(255, 162, 64, 0.06);

$color-yellow: rgba(255, 228, 64, 1); // FFD600
$color-yellow-default: rgba(255, 228, 64, 0.85); // ffdc26
$color-yellow-medium: rgba(255, 228, 64, 0.75); //FFE040
$color-yellow-half: rgba(255, 228, 64, 0.5); //FFEA7F
$color-yellow-light: rgba(255, 228, 64, 0.3); //255, 228, 64,.3
$color-yellow-slight: rgba(255, 228, 64, 0.15); //FFF9D9
$color-yellow-note: rgba(255, 228, 64, 0.06); //fffdf0

$color-purple: rgba(109, 109, 185, 1);
$color-purple-default: rgba(109, 109, 185, 0.9);
$color-purple-medium: rgba(109, 109, 185, 0.75);
$color-purple-half: rgba(109, 109, 185, 0.5);
$color-purple-light: rgba(109, 109, 185, 0.3);
$color-purple-slight: rgba(109, 109, 185, 0.15);
$color-purple-note: rgba(109, 109, 185, 0.06);

$color-green: rgba(9, 191, 171, 1); // 00AB8D
$color-green-default: rgba(9, 191, 171, 0.9); // 26b89e
$color-green-medium: rgba(9, 191, 171, 0.75); // 40C0AA
$color-green-half: rgba(9, 191, 171, 0.5); // 7FD5C6
$color-green-light: rgba(9, 191, 171, 0.3); // B2E6DD
$color-green-slight: rgba(9, 191, 171, 0.15); // D9F2EE
$color-green-note: rgba(9, 191, 171, 0.06); // f0faf8

$color-mid: rgba(0, 158, 171, 1); // 009eab
$color-mid-default: rgba(0, 158, 171, 0.85); // 26acb8
$color-mid-medium: rgba(0, 158, 171, 0.75); // 40b6c0
$color-mid-half: rgba(0, 158, 171, 0.5); // 7fced5
$color-mid-light: rgba(0, 158, 171, 0.3); // b2e2e6
$color-mid-slight: rgba(0, 158, 171, 0.1); // d9f1f2
$color-mid-note: rgba(0, 158, 171, 0.06); // f0f9fa
$color-mid-hint: rgba(0, 158, 171, 0.03); // f0f9fa

$color-brand: rgba(0, 116, 173, 1); // 0073AB
$color-brand-default: rgba(0, 116, 173, 0.9); // 2688b8
$color-brand-medium: rgba(0, 116, 173, 0.75); // 4096C0
$color-brand-half: rgba(0, 116, 173, 0.5); // 7FB9D5
$color-brand-light: rgba(0, 116, 173, 0.3); // B2D5E6
$color-brand-slight: rgba(0, 116, 173, 0.15); // D9EAF2
$color-brand-note: rgba(0, 116, 173, 0.06); // f0f9fa
$color-brand-hint: rgba(0, 116, 173, 0.03); // f0f9fa
$color-brand-solid: #7fb9d5; // f0f9fa

$color-blue: rgba(0, 83, 124, 1); // 1D4F7F
$color-blue-default: rgba(0, 83, 124, 0.9); // 3f6992
$color-blue-medium: rgba(0, 83, 124, 0.75); // 567B9F
$color-blue-half: rgba(0, 83, 124, 0.5); // 8EA7BF
$color-blue-light: rgba(0, 83, 124, 0.3); // BBCAD8
$color-blue-slight: rgba(0, 83, 124, 0.15); // DDE5EC
$color-blue-note: rgba(0, 83, 124, 0.06); // f2f5f7
$color-blue-hint: rgba(0, 83, 124, 0.03); // f2f5f7
$color-blue-hover: rgba(0, 83, 124, 0.1);

$color-gray-solid: #edf1f3;
$color-gray: #9eadbb; // #788898
$color-gray-module: rgba(158, 173, 189, 0.08);
$color-gray-light: rgba(158, 173, 189, 0.3);
$color-gray-medium: #6d7f90;
$color-gray-half: rgba(158, 173, 189, 0.5);
$color-gray-slight: rgba(158, 173, 189, 0.15);
$color-gray-note: rgba(158, 173, 189, 0.06);
$color-gray-hint: rgba(158, 173, 189, 0.03);
$color-gray-dark: #4c5864;
$color-gray-darker: #2b3239;
$color-gray-darkest: #121d2d;
$color-black: #000;

$color-background: #eff2f5;

$color-indicator: $color-pink;
$color-indicator-text: $color-white;

$color-red-canada: rgba(235, 45, 55, 1);
$color-red-italia: rgba(205, 33, 42, 0.84);
$color-green-italia: rgba(0, 140, 69, 0.84);
