@use "shared" as *;

.link-container {
  position: relative;
  display: inline-block;
}

.divider {
  border-bottom: 1px solid $color-gray-light;
  margin: 12px 0;
}

/* Style for the badge */
.badge {
  position: absolute;
  top: -9px;
  right: 28px;
  background-color: $color-pink-default;
  border-radius: 50%;
  width: 19px;
  height: 19px;
  text-align: center;
  line-height: 19px;

  i {
    color: $color-white !important;
    font-size: $type-default !important;
  }
}

/* Style for the link */
.link-badge {
  font-size: $type-default;
  font-weight: $regular;
  border-radius: $radius-default;
  padding: 4px 12px;
  align-self: center;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  margin: 0 36px 0 0;

  color: $color-blue;
  border-radius: $radius-default;
  background: $color-brand-slight;
  transition: $transition-background;

  &:hover {
    color: $color-blue;
    background: $color-brand-slight;
  }
}

.nav {
  max-width: 100%;
  display: flex;
  background: $color-white;
  margin: 0;
  z-index: $z-nav !important;
  position: relative;
  top: 12px;
  padding: 24px;

  @media (min-width: $break-md) {
    padding: 24px;
  }

  @media (min-width: $break-lg) {
    padding: 24px 36px 24px 36px;
  }

  @media (min-width: $break-xl) {
    padding: 24px 96px 24px 96px;
  }

  @media (min-width: $break-huge) {
    padding: 24px 96px 24px 96px;
  }

  &-landing {
    @extend .nav;
    max-width: 100%;
    display: flex;
    background: transparent;
    z-index: 0;
    position: absolute;
    top: 36px;
    left: 0;
    right: 0;
  }

  &-left {
    display: inline-flex;
    color: $color-gray-darkest;
    flex: 1;
    align-items: center;

    img {
      height: 55px;
    }

    @media (min-width: $break-md) {
      img {
        height: 55px;
      }
    }
  }

  &-right {
    display: flex;
    color: $color-white;
    justify-content: flex-end;
    align-items: center;
    padding: 0;

    #new {
      padding: 3px 6px;
      border-radius: $radius-slight;
      background: $color-green;
      color: $color-white;
      font-size: $type-teeny;
      font-weight: $bold;
      margin: 0 6px 2px 0;
      vertical-align: super;
      position: absolute;
      top: -12px;
      z-index: $z-ultimate;
    }
    a {
      cursor: pointer !important;
    }

    i {
      color: $color-blue;
      font-size: $type-md;
      padding: 3px;
    }
  }

  &-compact {
    @extend .nav;
    height: 60px;
    background: $color-white;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: $z-nav;
    box-shadow: 0 12px 60px $color-gray-slight;
    padding: 0 24px;

    @media (min-width: $break-md) {
      padding: 0 24px;
    }

    @media (min-width: $break-lg) {
      padding: 0 36px;
    }

    @media (min-width: $break-xl) {
      padding: 0 96px;
    }

    @media (min-width: $break-huge) {
      padding: 0 96px;
    }

    &-left {
      @extend .nav-left;

      img {
        height: 30px;
      }
    }

    &-right {
      @extend .nav-right;
    }
  }

  &-search {
    display: flex;
    align-items: center;
    align-self: center;
    width: 300px;
    height: 40px;
    margin-right: 32px;
    padding: 0 4px 0 16px;
    border: 1px solid $color-gray-light;

    [class*="InputBase"] {
      flex: 1;
    }

    input {
      font-size: $type-sm !important;
    }
  }
}
