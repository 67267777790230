@use "shared" as *;

.lang-video {
  font-size: $type-tiny;
  align-self: flex-end;

  ul {
    display: inline-flex;
    list-style: none;
    color: $color-gray-dark;
    padding: 0;
    margin: 6px 0;

    li {
      justify-content: flex-end;
      color: $color-gray-dark !important;
      background: transparent;
      text-align: center;
      border: none !important;
      margin-left: 6px;

      a,
      span {
        cursor: pointer;
        padding: 1px 3px;
        text-transform: uppercase;
        font-size: $type-tiny;

        &:hover {
          color: $color-white;
          background: $color-brand-half;
        }
      }

      .selected {
        color: $color-white;
        background: $color-brand-medium;
        border-radius: 0;
        min-width: 40px !important;
        display: block;
        padding: 1px 3px;

        &:hover {
          color: $color-white;
          background: $color-brand;
        }
      }

      .disabled {
        color: $color-gray-medium;
        background: $color-gray-slight;
        border-radius: 0;
        min-width: 40px !important;
        display: block;
        padding: 1px 3px;
        cursor: not-allowed;

        &:hover {
          color: $color-gray-medium;
          background: $color-gray-slight;
        }
      }

      .unselected {
        color: $color-brand;
        background: $color-brand-slight;
        border-radius: 0;
        min-width: 50px !important;
        display: block;
        padding: 1px 3px;

        &:hover {
          color: $color-brand;
          background: $color-brand-light;
        }
      }
    }
  }
}
